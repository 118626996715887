.splash {
  position: relative;
  display: grid;
  grid-template-rows: repeat(3, 1fr);
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FDF8F4;
  overflow: hidden;

}

.bigBubleTop {
  position: absolute;
  width: 216px;
  height: 216px;
  background-color: #C1C4F1;
  border-radius: 100%;
  animation: topBigBubble 2s normal;
  top: 125px;
  left: -90px;
}

.mediumBubleTop {
  position: absolute;
  width: 80px;
  height: 80px;
  background-color: #F9B240;
  border-radius: 100%;
  animation: topMediumBubble 2s normal;
  top: 85px;
  left: 115px;
}

.smallBubleTop {
  position: absolute;
  width: 25px;
  height: 25px;
  background-color: #CFE2F3;
  border-radius: 100%;
  animation: topSmallBubble 2s normal;
  top: 50px;
  left: 190px;
}

.bigBottomBubble {
  position: absolute;
  width: 200px;
  height: 200px;
  background-color: #7D9652;
  border-radius: 100%;
  animation: bottomBubble 2s normal;
  bottom: -40px;
  right: -35px;
}

.centre {
  animation: logo 2s ease-in-out normal;
}

@keyframes logo {
  0% {
    transform: scale(0);

  }

  50% {
    transform: scale(1.3);
  }

  100% {
    transform: scale(1);
  }
}


@keyframes bottomBubble {
  0% {
    bottom: 20px;
    right: 50px;
  }

  50% {
    bottom: 20px;
    right: 50px;
  }

  100% {
    bottom: -40px;
    right: -35px;
  }
}

@keyframes topBigBubble {
  0% {
    top: 150px;
    left: 20px;
  }

  50% {
    top: 150px;
    left: 20px;
  }

  100% {
    top: 125px;
    left: -90px;
  }
}


@keyframes topMediumBubble {
  0% {
    top: 110px;
    left: 300px;
  }

  50% {
    top: 110px;
    left: 300px;
  }

  100% {
    top: 85px;
    left: 115x;
  }
}


@keyframes topSmallBubble {
  0% {
    top: 50px;
    left: 350px;
  }

  50% {
    top: 50px;
    left: 350px;
  }

  100% {
    top: 50px;
    left: 190px;
  }
}