.loading {
    position: absolute;
    z-index: 100;
    top: 0px;
    right: 0px;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.393);
    backdrop-filter: blur(3px);
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
}

.content>h2 {

    color: white;
    margin-top: 20px;
}