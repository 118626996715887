*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color: #343434;
}
.add{
    background-color: #b9b9b9;
    border-radius: 4px;
}
.minus{
    background-color: #6f7e90;
    border-radius: 4px;
}
.main_container{
    display: flex;
}

.main_container > div:nth-child(2){
    padding: 0 0 0 6px;

}
.parent_detail{
    padding: 0 0 0 20px;
    text-align: left;
    font-size: 8px;
    width: 150px;
    word-wrap: break-word;
}

.parent_detail > p:nth-child(1){
    word-wrap: break-word;
}

.parent_detail > p:nth-child(3){
    font-size: 12px !important;
}

.name_label{
    font-size: 12px;
}

@media screen and (min-width: 1024px) and (max-width : 1100px) {

    .parent_detail{
        padding: 0 0 0 20px;
        text-align: left;
        font-size: 8px;
        word-wrap: break-word;
    }
    

}

@media screen and (min-width: 1024px) and (max-width : 1100px) {

    .parent_detail{
        padding: 0 0 0 20px;
        text-align: left;
        font-size: 7px;
        width: 100px;
        word-wrap: break-word;
    }
    .parent_detail > p:nth-child(3){
        font-size: 11px !important;
    }

}