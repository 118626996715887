.mainContainer{
    display: flex;
    width: 100%;
    flex-direction: column;
    margin: 0 20px;
}

.selectRecieverDiv{
    display: flex;
}

.inputDiv{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.buttonDiv{
    margin: 30px 20px 0px;
    display: flex;
    justify-content: center;
}

.sendButton{
    background-color: #1740a1;
    color: white;
    border: none;
    padding: 6px 15px;
    border-radius: 5px;
    font-size: 14px;
}

.CancelButton{
    background-color: #ffffff;
    color: #1740a1;
    border: 1px solid #1740a1;
    padding: 6px 15px;
    border-radius: 5px;
    font-size: 14px;
    margin-right: 20px;
}