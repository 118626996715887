.topHeading {
    padding: 10px 0 20px 0;
}

.topHeading h1 {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    color: #000000
}

.topHeading p {
    padding: 4px 0;
    font-size: 10px;
    line-height: 14px;
    color: #66707E;
}

.faq {
    border: 1px solid #E8E7E7;
    box-shadow: 0px 1px 50px 0px #00000014;
    background-color: #FFFFFF;
    border-radius: 15px;
}

.faq>div:first-of-type {
    border-radius: 15px 15px 0 0;
}

.faq>div:last-of-type {
    border-radius: 0 0 15px 15px;
}

.faq a {
    color: #3B3CEA
}

.question {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    color: #001028;
    margin: 8px 0;
}

.answer {
    color: #66707E;
}