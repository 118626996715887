@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
* {
  font-family: "Inter", sans-serif;
}

.sideBarContainer {
  position: sticky;
  top: 0px;
  height: 100vh;
  min-width: 236px;
  background-color: #5770e6;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  overflow-y: scroll;
  font-size: 14px;
  padding-bottom: 16px;
}

.sideBarContainer::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

.logoDiv {
  display: flex;
  width: 100%;
  margin-bottom: 30px;
  margin-left: 5px;
  margin-top: 10px;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 5px;
}
.list_container {
  display: flex;
  flex-direction: column;
  gap: 200px;
}
.sideBarItemDiv {
  display: flex;
  align-items: center;
  margin: 15px;
  width: 100%;
}

.navItemText {
  color: white !important;
}

.navItemSelected {
  padding: 0;
}

.navItemNotSelected {
  opacity: 0.6;
  padding: 0;
}

.navButtonDiv {
  position: absolute;
  top: 180px;
  left: 255px;
  background-color: white;
  padding: 5px;
  border-radius: 50px;
  cursor: pointer;
}

.navButton {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8d9af;
  border-radius: 50px;
  width: 40px;
  height: 40px;
}

.link {
  width: 100%;
}

.dashboard_footer_container {
  padding: 0 24px;
}

.dashboard_footer_container_inner {
  border: 1px dashed #b5c0f3;
  padding: 12px;
  text-align: center;
  border-radius: 8px;
}
.dashboard_footer_container_inner > div {
  margin: 8px 0 0 0;
}
.dashboard_footer_container_inner > div > p:first-child {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  color: #b5c0f3;
}
.footer_link {
  margin: 2px 0 0 0;
  text-decoration: none;
  font-weight: 400;
  font-size: 8px;
  line-height: 10px;
  text-align: center;
  color: #ffffff;
}
.helpsupport_container {
  margin: 32px 0 0 0;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.helpsupport_link {
  font-weight: 400;
  font-size: 12px;
  color: #b5c0f3;
}
.app_version {
  margin: 35px 0 0 0;
  text-align: center;
  font-weight: 400;
  font-size: 10px;
  color: #ffffff;
}
