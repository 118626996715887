.mainCon {
    position: absolute;
    /* height: 26px; */
    z-index: 4;
    border-radius: 50px;
    width: fit-content;
}


.activeGreen {
    z-index: 5;
    border: .5px solid green;
    animation: pulse 1s infinite;
}


.activeRed {
    z-index: 5;
    border: .5px solid red;
    animation: pulseRed 1s infinite;
}


.lungspointCon {
    position: absolute;
    z-index: 4;
}


.heartpointsCon {
    position: absolute;
    z-index: 4;
}



@-webkit-keyframes pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(0, 184, 23, 0.3);
    }

    70% {
        -webkit-box-shadow: 0 0 0 20px rgba(204, 169, 44, 0);
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}

@keyframes pulse {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba(0, 184, 23, 0.3);
        box-shadow: 0 0 0 0 rgba(0, 184, 23, 0.3);
    }

    70% {
        -moz-box-shadow: 0 0 0 20px rgba(204, 169, 44, 0);
        box-shadow: 0 0 0 20px rgba(204, 169, 44, 0);
    }

    100% {
        -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
        box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}



@-webkit-keyframes pulseRed {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(248, 13, 56, 0.3);
    }

    70% {
        -webkit-box-shadow: 0 0 0 20px rgba(204, 169, 44, 0);
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}

@keyframes pulseRed {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba(248, 13, 56, 0.3);
        box-shadow: 0 0 0 0 rgba(248, 13, 56, 0.3);
    }

    70% {
        -moz-box-shadow: 0 0 0 20px rgba(204, 169, 44, 0);
        box-shadow: 0 0 0 20px rgba(204, 169, 44, 0);
    }

    100% {
        -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
        box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}