* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Montserrat;
  color: #343434;
}

/* .studentRow_container{
    display: flex;
    border-radius: 8px;
    margin: 12px 0 0 0;
    padding: 10px 0 10px 16px;
    border: 1px solid #e7e7e7;
    background-color: #fff;
    
  
  } */

.studentRow_container {
  display: grid;
  border-radius: 8px;
  padding: 10px 0 10px 16px;
  border: 1px solid #e7e7e7;
  background-color: #fff;
  gap: 24px;
  grid-template-columns: 0.7fr 0.3fr 0.3fr 0.7fr 0.4fr 0.6fr 0.3fr;
}

.studentRow_container > div {
  /* border: 1px solid red; */
}

/* Name and Avatar Styles */
.studentRow_container > div:first-child {
  display: flex;
  font-weight: bold;
  word-wrap: break-word;
}

.studentRow_container > div:first-child > div:nth-child(2) {
  padding: 5px 0 0 16px;
}

.studentRow_container > div:first-child > div:nth-child(2) > p {
  font-size: 12px;
}

/* Age Styles */

.studentRow_container > div:nth-child(2) {
  padding: 5px 0;
  text-align: left;
  font-size: 12px;
}

/* Gender Styles */
.studentRow_container > div:nth-child(3) {
  padding: 5px 0;
  text-align: left;
  font-size: 12px;
}

.studentRow_container > div:nth-child(4) {
  padding: 5px 0;
  text-align: left;
  font-size: 12px;
}

/* Screening Styles And Teacher Style */
.studentRow_container > div:nth-child(5) {
  padding: 5px 0;
  text-align: left;
  font-size: 12px;
}

/* Conditional Report Button */
.studentRow_container > div:nth-child(6) {
  padding: 5px 0;
  display: flex;
  gap: 4px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #343434;
}

.report_btn {
  padding: 5px 0;
  width: 72px;
  border: none;
  background: none;
  background-color: #3b3cea;
  font-size: 8px;
  font-weight: 600;
  border-radius: 5vh;
  color: #fff;
  display: flex;
  justify-content: center;
}
.screened_btn {
  padding: 5px 0;
  width: 72px;
  border: 1px solid #e7e7e7;
  background: #149457;
  font-size: 8px;
  color: #000;
  border-radius: 5vh;
  display: flex;
  justify-content: center;
}

.inprogress_btn {
  padding: 5px 0;
  width: 72px;
  border: 1px solid #e7e7e7;
  background: #eea61f;
  font-size: 8px;
  color: #000;
  border-radius: 5vh;
  display: flex;
  justify-content: center;
}

/* Validated Styling */

.studentRow_container > div:nth-child(7) {
  padding: 5px 0;
  text-align: left;
  font-size: 12px;
}

.studentRow_container > div:nth-child(8) {
  padding: 5px;
  font-size: 12px;
}

.iconHover {
  display: flex;
  margin: -5px 0 0 0;
  justify-content: center;
  align-items: center;
  position: relative;

  &:hover {
    background: rgba(#f6f6f6, .3);

    &>.validationOnHover {
      opacity: 1;
      width: 230px;
      height: 100px;
    }
  }
}

.validationOnHover {
  white-space: nowrap;
  overflow: hidden;
  opacity: 0;
  width: 0px;
  height: 0px;
  position: absolute;
  z-index: 10;
  top: 15px;
  right: 5px;
  box-shadow: 0px 12px 34px rgba(0, 0, 0, 0.12317);
  background-color: white;
  padding: 16px;
  border-radius: 16px;
  text-align: start;
  transition: all .4s ease;
}

.validationOnHover>:nth-child(1) {
  font-size: 14px;
  font-weight: 600;
  color: #66707E;
  line-height: 20px;
}

.validationOnHover>:nth-child(2) {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #B2B7BE;
  margin-top: 4px;
}

.validationOnHover>:nth-child(3) {
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #B2B7BE;
  margin-top: 16px;
}

.validationOnHover>:nth-child(4) {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #001028;
  margin-top: 6px;
}



.infoHover {
  background-color: transparent;
  width: fit-content;

  &:hover {
    background: rgba(#f6f6f6, 0.3);

    & > .popOnHover {
      opacity: 1;
      width: 104px;
      height: 113px;
    }
  }
}

.popOnHover {
  box-shadow: 0px 0px 10px lightgrey;
  border-radius: 10px;
  /* white-space: nowrap; */
  overflow: hidden;
  opacity: 0;
  width: 0px;
  height: 113px;
  position: absolute;
  right: 57px;
  top: 10px;
  z-index: 2;
  bottom: -100px;
  transition: all 0.4s ease;
}

/* Responsive Media Query */

@media screen and (min-width: 1024px) and (max-width: 1100px) {
  .studentRow_container {
    display: grid;
    border-radius: 8px;
    padding: 10px 0 10px 16px;
    border: 1px solid #e7e7e7;
    background-color: #fff;
    gap: 22px;
    grid-template-columns: 0.7fr 0.3fr 0.3fr 0.7fr 0.6fr 0.3fr 0.3fr;
  }

  .studentRow_container > div {
    /* border: 1px solid red; */
    font-size: 11px;
  }

  /* Name and Avatar Styles */
  .studentRow_container > div:first-child {
    display: flex;
    font-weight: bold;
  }

  .studentRow_container > div:first-child > div:nth-child(2) {
    padding: 4px 0 0 15px;
  }

  .studentRow_container > div:first-child > div:nth-child(2) > p {
    font-size: 11px;
  }

  /* Age Styles */

  .studentRow_container > div:nth-child(2) {
    padding: 5px 0;
    text-align: left;
  }

  /* Gender Styles */
  .studentRow_container > div:nth-child(3) {
    padding: 5px 0;
    text-align: left;
  }

  .studentRow_container > div:nth-child(4) {
    padding: 5px 0;
    text-align: left;
  }

  /* Screening Styles And Teacher Style */
  .studentRow_container > div:nth-child(5) {
    padding: 5px 0;
    text-align: left;
  }

  /* Conditional Report Button */
  .studentRow_container > div:nth-child(6) {
    padding: 5px 0;
    display: flex;
    gap: 4px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #343434;
  }

  .report_btn {
    padding: 5px 0;
    width: 72px;
    border: none;
    background: none;
    background-color: #3b3cea;
    font-size: 8px;
    border-radius: 5vh;
    color: #fff;
    cursor: pointer;
  }
  .screened_btn {
    padding: 5px 0;
    width: 72px;
    border: 1px solid #e7e7e7;
    background: none;
    font-size: 8px;
    color: #000;
    border-radius: 5vh;
    cursor: pointer;
  }

  /* Validated Styling */

  .studentRow_container > div:nth-child(7) {
    padding: 5px 0;
    text-align: left;
    font-size: 12px;
  }

  .studentRow_container > div:nth-child(8) {
    padding: 5px;
    font-size: 12px;
  }
}
