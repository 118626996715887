.profileContainer {
    background-color: #fff;
    padding: 20px;
}

.avatarContainer {
    text-align: center;
}

.avatarContainer h1 {
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
    margin-top: 10px;
}

.detailsContainer {
    display: flex;
    gap: 10px;
}

.detailsContainer h6 {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #9D9D9D
}