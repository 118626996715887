.cancel_btn{
    display: flex;
    flex-direction: row-reverse;
    cursor: pointer;
}
.schedule_event_header{
    margin: 8px 0 0 0;
    text-align: center;
}
.schedule_event_header > p:first-child{
    font-size: 20px;
    color: #001028;
}
.schedule_event_header > p:nth-child(2){
    margin: 16px 0 0 0;
    font-size: 14px;
    color: #66707E;
}
.schedule_event_component{
    margin: 48px 0 0 0;
    display: flex;
    gap: 48px;
    /* border: 1px solid red; */
}
.schedule_event_component > div:nth-child(1){
    width: 344px;
}
.schedule_event_component > div:nth-child(2){

}
.schedule_total_students_text > p:first-child{
    font-weight: 400;
    color: #66707E;
    font-size: 14px;
}
.calendar_container{
    padding: 10px;
    border: 1px solid #E7E7E7;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}
.schedule_total_students_text > p:nth-child(2){
    margin: 8px 0 0 0;
    color: #001028;
    font-size: 18px;
    font-weight: 500;
}

.event_start_info_container{
    margin: 40px 0 0 0;
    display: flex;
    gap: 32px;
}

.event_start_info_container > div > p:first-child{
    font-weight: 400;
    color: #66707E;
    font-size: 14px;
}
.event_start_info_container > div > p:nth-child(2){
    margin: 8px 0 0 0;
    color: #001028;
    font-size: 18px;
    font-weight: 500;

}

.average_student_text{
    margin: 40px 0 0 0;
}

.average_student_text > p:first-child{
    font-weight: 400;
    color: #66707E;
    font-size: 14px;
}

.average_student_text > p:nth-child(2){
    margin: 8px 0 0 0;
    color: #001028;
    font-size: 18px;
    font-weight: 500;
}
.schedule_container{
    margin: 32px 0 0 0;
    width: 432px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #a3a3a3;
}
.decison_buttons_container{
    margin: 48px 0 0 0;
    display: flex;
    gap: 32px;
    justify-content: center;
}
.decison_buttons_container > button{
    border: none;
    background: none;
    width: 176px;
    padding: 16px;
    text-align: center;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
}
.decison_buttons_container > button:first-child{
    border: 1px solid #1740A1;
    color: #1740A1;
    background-color: #ffffff;
}
.decison_buttons_container > button:nth-child(2){
    background-color: #1740A1;
    color: #ffffff;
}
.css-1e6y48t-MuiButtonBase-root-MuiButton-root{
    padding: 0 !important;
}
.MuiButton-contained{
    text-transform: lowercase !important;
}