@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  color: #343434;
}

/* .studentRow_container{
    display: flex;
    border-radius: 8px;
    margin: 12px 0 0 0;
    padding: 10px 0 10px 16px;
    border: 1px solid #e7e7e7;
    background-color: #fff;
    
  
  } */

.studentRow_container {
  display: grid;
  border-radius: 8px;
  padding: 10px 0 10px 16px;
  border: 1px solid #e7e7e7;
  background-color: #fff;
  gap: 20px;
  /* grid-template-columns: 0.6fr 0.24fr 0.3fr 0.4fr 0.3fr 0.4fr 0.28fr 0.2fr; */
  grid-template-columns: 1fr 0.4fr 0.5fr 0.6fr 0.3fr 0.3fr 0.3fr 0.4fr 0.25fr;
  transition: all .2s;

}

.studentRow_container:hover {
  box-shadow: -1px 3px 10px rgb(209, 209, 209);
  transform: scale(1.01);
}

.studentRow_container>div {
  /* border: 1px solid red; */
  font-size: 12px;
}



/* Name and Avatar Styles */
.studentRow_container>div:first-child {
  display: flex;
  align-items: center;
  font-weight: bold;
  word-wrap: break-word;
}


.studentRow_container>div:first-child>div:nth-child(2) {
  padding: 0px 0 0 16px;
}

.studentRow_container>div:first-child>div:nth-child(2)>p {
  font-size: 12px;

}


/* Age Styles */

.studentRow_container>div:nth-child(2) {
  padding: 5px 0;
  text-align: left;
  font-size: 12px;
}

/* Gender Styles */
.studentRow_container>div:nth-child(3) {
  padding: 5px 0;
  text-align: left;
  font-size: 12px;
}

.studentRow_container>div:nth-child(4) {
  padding: 5px 0;
  text-align: left;
  font-size: 12px;
  /* border: 1px solid red; */
  word-wrap: break-word;
}

/* Screening Styles And Teacher Style */
.studentRow_container>div:nth-child(5) {
  padding: 5px 0;
  text-align: left;
  font-size: 12px;

}

/* Conditional Report Button */
.studentRow_container>div:nth-child(6) {
  padding: 5px 0;
  text-align: left;
}


.active_btn {
  padding: 5px 0;
  width: 72px;
  border: none;
  background: none;
  background-color: #62a615;
  font-size: 8px;
  border-radius: 5vh;
  text-align: center !important;
  color: #fff;
}

.inactive_btn {

  padding: 5px 0;
  width: 72px;
  border: 1px solid #EBECEE;
  background: #EBECEE;
  font-size: 8px;
  color: #B2B7BE !important;
  font-weight: 600;
  border-radius: 5vh;
  cursor: pointer;
  text-align: center !important;
  text-align: center;

}

/* Validated Styling */

.studentRow_container>div:nth-child(7) {
  padding: 5px 0;
  font-size: 12px;
}

.studentRow_container>div:nth-child(8) {
  padding: 5px;
  font-size: 12px;

}


.infoHover {
  background-color: transparent;
  width: fit-content;

  &:hover {
    background: rgba(#f6f6f6, .3);

    &>.popOnHover {
      opacity: 1;
      width: 104px;
      height: 113px;
    }
  }
}

.popOnHover {
  box-shadow: 0px 0px 10px lightgrey;
  border-radius: 10px;
  /* white-space: nowrap; */
  overflow: hidden;
  opacity: 0;
  width: 0px;
  height: 113px;
  position: absolute;
  right: 25px;
  top: 10px;
  z-index: 2;
  bottom: -100px;
  transition: all .4s ease;
}




/* Responsive Media Query */
@media only screen and (min-width: 1200px) and (max-width: 1298px) {

  .studentRow_container {
    display: grid;
    border-radius: 8px;
    padding: 10px 0 10px 16px;
    border: 1px solid #e7e7e7;
    background-color: #fff;
    gap: 20px;
    grid-template-columns: 1fr 0.4fr 0.5fr 0.6fr 0.4fr 0.4fr 0.4fr 0.5fr 0.3fr;
  }

  .studentRow_container>div {
    /* border: 1px solid red; */
    font-size: 12px;
  }


}


@media only screen and (min-width: 1100px) and (max-width: 1199px) {

  .studentRow_container {
    display: grid;
    border-radius: 8px;
    padding: 10px 0 10px 16px;
    border: 1px solid #e7e7e7;
    background-color: #fff;
    gap: 20px;
    grid-template-columns: 0.75fr 0.4fr 0.5fr 0.6fr 0.3fr 0.3fr 0.3fr 0.5fr 0.3fr;
  }

  .studentRow_container>div {
    /* border: 1px solid red; */
    font-size: 11px;
  }


}


@media only screen and (min-width: 1024px) and (max-width: 1099px) {

  .studentRow_container {
    display: grid;
    border-radius: 8px;
    padding: 10px 0 10px 16px;
    border: 1px solid #e7e7e7;
    background-color: #fff;
    gap: 20px;
    grid-template-columns: 0.7fr 0.4fr 0.5fr 0.6fr 0.2fr 0.2fr 0.2fr 0.2fr 0.25fr;
  }

  .studentRow_container>div {
    /* border: 1px solid red; */
    font-size: 10px;
    word-wrap: break-word;
  }



}