.modalContainer {
    position: relative;
    width: 65%;
    background-color: white;
    border-radius: 20px;

}

.captureButton {
    position: absolute;
    right: 50px;
    top: 45%;
    border-radius: 100%;
}

.cameraView {
    padding: 2px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);

}

.captureButton:hover {
    background-color: #ffffff88;
}



.flipButton {

    padding: 2px 4px;
    position: absolute;
    right: -50px;
    top: 30px;
    border-radius: 100%;
    transition: transform 1s ease-in-out ; 
}

.flipButton:hover{
    transform: rotate(-180deg);
}

.flipButton:hover {
    background-color: #ffffff25;

}