.filterBox {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
}

.filterOptions,
.filterBtn {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.tabBtn_container {
    display: flex;
    align-items: center;
    gap: 5px;
}

.statusBtn {
    color: #fff;
    padding: 10px;
    border-radius: 100px;
    outline: none;
    border: none;
    /* cursor: pointer; */
    font-size: 10px;
    font-weight: 600;
    line-height: 12px;
    letter-spacing: 0.20000000298023224px;
}

.viewHistoryBtn {
    color: #222CC9;
    text-decoration: underline;
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    cursor: pointer;
}

/* .pagination_container  */
.pagination_container {
    display: flex;
    justify-content: space-between;
    margin: 20px 0 0 0;
    padding: 0 0 0 5px;
}

.pagination_container .page_item {
    font-size: 12px;
}

.pagination_container .pagination {
    display: flex;
    align-items: center;
}

.pagination_container .pagination>div:nth-child(3) {
    padding: 7px 10px;
    font-size: 14px;
    font-weight: bold;
}

.pagination_container .pagination>div:nth-child(3)>span:first-child {
    color: #e7e7e7;
    padding: 0 12px 0 0;
}

.opsBtn {
    border: none;
    background: #1740A1;
    font-weight: 500;
    font-size: 15px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    min-width: 160px;
    min-height: 44px;
    padding: 2px 10px;
    cursor: pointer;
}

.OpsBtnInactive {
    border: none;
    background: #B2B7BE;
    font-weight: 500;
    font-size: 15px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    min-width: 160px;
    min-height: 44px;
    padding: 2px 10px;
}