*{
    font-family: "Inter", sans-serif;
}
.tabButton{
    display: flex;
    gap : 16px;
    }
    .tabButton > button{
    display: flex;
    /* width: 160px; */
    height: 44px;
    gap: 8px;
    border: none;
    background-color:#1740A1;
    color: #fff;
    font-weight: 600;
    border-radius: 8px;
    padding: 10px 10px 10px 10px;
    cursor: pointer;
    }
    .tabButton > button:first-child{
    order : 2
    }
    .tabButton > button > span{
        font-weight: 600;
        font-size: 16px;
        padding: 1px 0 0 0;
        line-height: 20px;        
        color: #F5F6F7;
    }

    .eventButtonContainer{
       display: flex;
       gap: 16px; 
    }
    .eventButtonContainer > div:first-child{
        font-size: 12px;
    }
    .eventButtonContainer > div:nth-child(2){
        display: flex;
        flex-direction: column;
        gap: 6px;
    }