a{
    text-decoration: none;
    cursor: pointer;
  }
  .school_info {
    width:100%
  }
  .school_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .header {
    display: flex;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: normal;
    color: #000;
  }
  .back{
    padding: 0.3vh 0 0 0;
  }
  .header_text{
    padding: 0.3vh 0 0 0;
  }
  .edit_button {
    border-radius: 8px;
    background-color: #000;
    padding: 1vh 1.5vw;
    border: none;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    color: #fff;
  }
  .school_container {
    position: relative;
    margin-top: 3vh;
    padding: 3vh 2vw;
    border-radius: 16px;
    box-shadow: 0 1px 50px 0 rgba(0, 0, 0, 0.08);
    border: solid 1px #e8e7e7;
    background-color: #fff;
    text-align: center;
    /* padding-bottom: 8vh; */
  }
  .brandlogo_container{
    height: 120px;
    width: 120px;
    border-radius: 50%;
    text-align: center;
    margin: 0 auto;
  }
  .brandlogo{
  
  }
  .brandlogo_container_clinic{
    border: solid 2px #e8e7e7;
    text-align: center;
    padding: 2vh 0 0 0;
  }
  .brandlogo_clinic{
    width: 48px;
    height: 72px;
  }
  
  .status_bar{
    position: absolute;
    width: 72px;
    height: 24px;
    text-align: center;
    border-radius: 5vh;
    padding: 6px 8px;
    font-size: 0.5em;
    right: 2vw;
  }
  .status_bar_active{
    background-color: #62a615;
    color: #fff;
  }
  .status_bar_inactive{
    background-color: #e8e7e7;
    color: #959595;
    
  }
  .status_bar_saved{
    background-color: #fd6f09;
    color: #fff;
  }
  
  .school_name {
    margin: 20px 0 0 0;
    font-size: 20px;
    font-weight: 600;
    line-height: 1;
    text-align: center;
    color: #000;
    padding: 2vh 0;
  }
  .school_details {
    padding: 5vh 0;
    padding-bottom: 1.5vh;
  }
  .school_details > div:nth-child(2){
    margin: 24px 0 0 0;
  }
  .basic_details {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    text-align: left;
  }
  .basic_label {
    font-size: 0.8em;
    line-height: 1.33;
    color: #9d9d9d;
    text-align: left;
  }
  .basic_value {
    font-size: 0.9em;
    font-weight: 500;
    line-height: 1.14;
    color: #000;
    padding: 1vh 0;
  }
  .contact_detail {
    padding: 5vh 0;
    padding-top: 2vh;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .contacts {
    display: flex;
    align-items: center;
    padding: 2vh 0;
    padding-right: 1vw;
    /* border: 1px solid red; */
  }
  .contacts_icon {
    height: 32px;
    width: 32px;
  }
  
  .contacts_value_right{
    padding-left: 1vw;
    font-weight: 500;
    line-height: 1.43;
    text-align: justify;
    color: #000;
  }
  
  .contacts_value{
    padding-left: 1vw;
    font-weight: 500;
    line-height: 1.43;
    text-align: justify;
    color: #000;
  }
  .contacts_value > div:first-child{
    font-size: 0.75em;
    color: #9d9d9d;
  }
  .contacts_value > div:nth-child(2){
    font-size: 0.87em;
  }
  .contacts_value_right > div:first-child{
    font-size: 0.75em;
    color: #9d9d9d;
  }
  .contacts_value_right > div:nth-child(2){
    font-size: 0.87em;
    color: #3b3cea;
  }
  .contact_right_pannel {
    display: flex;
    /* border: 1px solid red; */
  }
  .contact_right_pannel > div:nth-child(3){
    padding: 0 0 0 4vw;
  }
  .contact_items_right {
    /* display: flex; */
    /* border: 1px solid red; */
    padding-left: 2vw;
  }

  .activate_services_container{
    margin: 70px 0 0 0;
    width: 160px;
    display: flex;
    gap: 16px;
    flex-direction: column;
  }
  .activate_services_container > button{
    border: none;
    background: none;
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    text-align: left;
  }
  .activate_btn{
    color: #3b3cea;
  }
  .delete_btn{
    color: #db1c1c;
  }
  .header_information{
    margin: 48px 0 0 0;
    display: flex;
    justify-content: space-between;
    width: 47vw;
  }
  .header_information > div{
    font-weight: bold;
    font-size: 14px;
  }

  .school_title{
    font-size: 14px;
    font-weight: bold;
  }