
.Mui-focused{
    /* border: 1px solid red; */
    /* color: #6b6c6f !important; */
}
.input-border {
    border-color: #b2b7be !important;
    border-radius: 8px !important;
}

.inputLabel {
    color: #9d9d9d !important;
    font-family: "Inter", sans-serif !important;
    font-size: 14px !important;
}
.labelAsterisk{
    color: #9d9d9d !important;
}

.helper-text {
    text-transform: initial;
    font-size: 1rem !important;
}
