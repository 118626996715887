@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

* {
    font-family: "Inter", sans-serif;
}

body {
    background: #FAF8F7;
}

.infoCard_container {
    display: flex;
    gap: 24px;
}

.analytics_text {
    margin: 32px 0 0 0;
    font-size: 16px;
    color: #001028;
    font-weight: 600;
}

.analytics_cards_container {
    margin: 16px 0 0 0;
    display: grid;
    grid-template-columns: repeat(4, 18.542vw);
    gap: 24px;
}

.analytics_cards_main {
    position: relative;
}

.analytics_cards_container_inner {
    border-radius: 16px;
    background: #FFFFFF;
    border: 1px solid #E8E7E7;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.08);
    padding: 24px;
}

/* .analytics_cards_container > .analytics_cards_container_inner_mouseover:nth-child(4){
    width: 50vw;
} */

.analytics_cards_container_inner_header {
    font-size: 16px;
    font-weight: 600;
    color: #66707E;
}

.analytics_cards_container_logo_number_container {
    margin: 24px 0 0 0;
    display: flex;
    gap: 12px;

}

.analytics_cards_container_logo_number_container_text {
    font-size: 28px;
    font-weight: 400;
}

.analytics_cards_container_logo_number_container_countText {
    padding: 10px 0 0 0;
}

.analytics_cards_container_inner_subtext {
    margin: 16px 0 0 0;
    font-size: 12px;
}

.analytics_cards_inner_green {
    color: #96C717;
}

.analytics_cards_inner_blue {
    color: #3B3CEA;
}

.analytics_cards_inner_red {
    color: #CA6B6E;
}

.analytics_cards_inner_violet {
    color: #A66DD4;
}

.analytics_cards_container_inner_mouseover {
    position: absolute;
    width: 23.333vw;
    z-index: 99;
    border-radius: 16px;
    background: #FFFFFF;
    border: 1px solid #E8E7E7;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.08);
    padding: 24px;
}

.analytics_cards_container_inner_mouseover_last_box {
    position: absolute;
    width: 23.333vw;
    right: 0;
    z-index: 99;
    border-radius: 16px;
    background: #FFFFFF;
    border: 1px solid #E8E7E7;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.08);
    padding: 24px;
}



.analytics_clinic_card {
    /* top: 0; */
    /* right: 0; */
}

.analytics_card_mouseover_subtitle {
    margin: 8px 0 0 0;
    font-size: 14px;
    color: #B2B7BE;
}

.analytics_card_mouseover_data_container {
    margin: 24px 0 0 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto;
    row-gap: 24px;
}

.analytics_mouseover_data_title {
    font-size: 12px;
    color: #B2B7BE;
}

.analytics_mouseover_data_title_count {
    margin: 8px 0 0 0;
    font-size: 16px;
    font-weight: bold;
}

.events_link {
    font-weight: 600;
}

.devices_data_container {
    margin: 24px 0 0 0;
    display: flex;
    gap: 24px;
}

.devices_container {
    width: 38.75vw;
    padding: 24px;
    background: #FFFFFF;
    border: 1px solid #E8E7E7;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
}

.devices_title {
    font-size: 16px;
    color: #66707E;
    font-weight: 600;
}

.devices_logo_number {
    margin: 24px 0 0 0;
    display: flex;
    gap: 12px;
}

.device_pupil_count {
    font-size: 28px;
    font-weight: 400;
    color: #9B715D;
}

.device_table_container {
    margin: 24px 0 0 0;
}

table {
    border-spacing: 20px;
}

table th {
    font-size: 12px;
    font-weight: 600;
    color: #001028;
}

table th:first-child {
    width: 10.278vw;
    text-align: left;
}

table th:nth-child(n+2) {
    width: 8vw;
    text-align: center;
}

table td:first-child {
    text-align: left;
    font-size: 14px;
    font-weight: 400;
}

table td:nth-child(n+2) {
    text-align: center;
    font-weight: 500;
}

table td:nth-child(2) {
    color: #3B3CEA;
}

.manage_devices_link {
    font-size: 12px;
    color: #9B715D;
}

.data_container {
    display: grid;
    grid-template-columns: repeat(2, 18.542vw);
    gap: 24px;
}

.data_container>div:first-child {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
}

.data_container>div:nth-child(2) {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
}

.data_container>div:nth-child(3) {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
}

.data_container>div:nth-child(4) {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
}

.data_container_boxes {
    border-radius: 16px;
    background: #FFFFFF;
    border: 1px solid #E8E7E7;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.08);
    padding: 24px;

}

.analytics_device_data_card_red {
    color: #CA6B6E;
}

.analytics_device_data_card_yellow {
    color: #EEA61F;
}

.graphTile {
    width: 38.75vw;
    padding: 24px;
    background: #FFFFFF;
    border: 1px solid #E8E7E7;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    height: fit-content;
}

.filterContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}