*{
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
  box-sizing: border-box;
}
.main_div{
  width: 100%;
}
.student_layout {
 display: flex;
 justify-content: space-between; 
}

.student_layout > div:first-child > p{
  font-size: 16px;
  font-weight: 600;
}

.student_layout > div:first-child > img{
  height: 12px;
  width: 12px;
}
.student_layout > div:nth-child(2){
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 16px;
}
.show_text{
  padding: 8px 0 0 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #66707E;
}


.sharetypeform_btn{
  background : none;
  border: none;
  width: 160px;
  height: 44px;
  border-radius: 8px;
  padding: 10px 0 10px 8px;
  font-weight: 600;
  display: flex;
  gap: 8px !important;
  font-size: 16px;
  cursor: pointer;
  background-color: #1740A1;
}
.sharetypeform_btn > img{
  height: 24px;
  width: 24px;
}
.sharetypeform_btn > span{
  margin: 0 !important;
  font-size: 16px !important;
  color: #fff;
}

.upload_list{
  position : absolute;
  top: 45px;
  left: 0;
  background: #FFFFFF;
  padding: 16px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.24);
  border-radius: 8px;
}
.upload_list > div
{
  display: flex;
  gap: 11px;
  font-size: 12px;
}
.upload_list > div:nth-child(2){
  margin: 20px 0 0 0;
}

.student_layout > div:nth-child(2) > button > span{
  font-size: 12px;
  color: #fff;
  margin: 0 0 0 0.5em;
}

.student_filters{
  margin: 28px 0 0 0;
  /* border: 1px solid red; */
  display: flex;
}

.student_filters > div{
  margin: 0 1em 0 0;
  border-radius: 8px;
}


/* Table Layout */
  
/* Table Header */
.student_table_header{
display: grid;
margin: 32px 0 0 0;
padding: 0 0 0 16px;
grid-template-columns: 0.5fr 0.2fr 0.2fr 0.5fr 0.5fr 0.3fr 0.3fr 0.2fr;
gap: 20px;
}

.student_table_header > div{
/* border: 1px solid green; */
font-size: 12px;
font-weight: 600; 
color: #343434;
text-align: left;
}

/* Student Body */
.student_column_container{
display: flex;
flex-direction: column;
/* border : 1px solid black; */
margin: 16px 0 0 0;
}

.student_column_container > div:nth-child(n+2){
margin: 12px 0 0 0;
}


.pagination_container{
  display: flex;
  justify-content: space-between;
  margin: 20px 0 0 0;
  padding: 0 0 0 5px;
}
.page_item{
  font-size: 12px;
}
.pagination{
  display: flex;
  align-items: center;
}
.pagination > div:first-child{
  position: relative;
}
.pagination > div:first-child > img:nth-child(2){
  position: absolute;
  top : 25%;
  left: 32%;
}



.pagination > div:nth-child(2){
  position: relative;
  font-size: 14px;
  font-weight: bold;
}

.pagination > div:nth-child(2) > span{
  position: absolute;
  top: 25%;
  left: 32%;
}


.pagination > div:nth-child(3){
    padding: 7px 10px;
    font-size: 14px;
    font-weight: bold;
}

.pagination > div:nth-child(3) > span:first-child{
  color: #e7e7e7;
  padding: 0 12px 0 0;
}

.pagination > div:nth-child(4){
  position: relative;
}

.pagination > div:nth-child(4) > img:nth-child(2){
  position: absolute;
  top : 25%;
  left : 32%;
}


@media screen and (min-width: 1024px) and (max-width : 1100px) {


  .student_table_header{
    display: grid;
    margin: 32px 0 0 0;
    padding: 0 0 0 16px;
    grid-template-columns: 0.5fr 0.2fr 0.2fr 0.5fr 0.55fr 0.3fr 0.3fr 0.2fr;
    gap: 20px;
    }

.student_table_header > div{
  /* border: 1px solid green; */
  font-size: 11px;
  font-weight: 600;
  color: #343434;
  text-align: left;
 }

}
