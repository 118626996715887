.sbpCon>:nth-child(1) {
    margin-top: 32px;
    font-size: 16px;
}

.sbpCon>:nth-child(2) {
    margin-top: 12px;
    font-size: 14px;
    color: #66707E
}

.titleTextBhv{
    font-weight: 600;
    margin: 10px 0 32px 0;
}
.testsLists {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 32px;
}

.ClickHereBlueBlack {
    font-size: 14px;
    display: flex;
    gap: 5px;
}

.ClickHereBlueBlack>span {
    font-weight: 600;
    color: #222cc9;
}

.testsLists>div {
    display: flex;
    gap: 10px;
    align-items: center;

}

.locateClinics {
    margin: 24px 0px;
    display: flex;
    align-items: center;
    gap: 16px;
}

.locateClinics>div {
    display: flex;
    flex-direction: column;
    gap: 12px;
}