@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
*{
    font-family: "Inter", sans-serif;
}
.blog_cards_container{
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);
    width: 12.361vw;
    height: 168px;
    padding: 12px 0 0 0;
    border-radius: 8px;
}
.blog_cards_container > div:first-child{
    padding: 0 12px;
    font-size: 12px;
    font-weight: 400;
    color: #001028;
    flex: 3;
}
.blog_cards_container > a > div{
    color: #405CFF;
    font-size: 12px;
    padding: 8px 12px;
    background: #EBECEE;
    border-radius: 0px 0px 8px 8px;
}

.video_card_container{
    background: #BEBCBC !important;
}
.video_card_container > div:first-child{
    display: flex;
    align-items: center;
    justify-content: center;
}
.video_card_container > div:first-child > div{
    color: #001028;
    font-size: 12px;
    background: #EBECEE;
    border-radius: 0px 0px 8px 8px;
    background-color: #EBECEE;
}
.video_topic{
    color: #001028;
    font-size: 12px;
    text-align: left;
    padding: 8px 12px;
    background: #EBECEE;
    border-radius: 0px 0px 8px 8px;
}
iframe{
    text-align: center;
}