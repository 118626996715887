@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
  color: #343434;
}

.studentRow_container {
  display: grid;
  align-items: center;
  border-radius: 8px;
  padding: 10px 0 10px 16px;
  border: 1px solid #e7e7e7;
  background-color: #fff;
  gap: 20px;
  /* grid-template-columns: 0.6fr 0.24fr 0.3fr 0.4fr 0.3fr 0.4fr 0.28fr 0.2fr; */
  grid-template-columns: 1fr 0.3fr 0.3fr 0.3fr 1fr 0.3fr 0.3fr 0.3fr;
}

.studentRow_container>div {
  /* border: 1px solid red; */
  font-size: 12px;
}

/* Name and Avatar Styles */
.studentRow_container>div:first-child {
  display: flex;
  align-items: center;
  font-weight: bold;
  word-wrap: break-word;
}


.studentRow_container>div:first-child>div:nth-child(2) {
  padding: 0px 0 0 16px;
}

.studentRow_container>div:first-child>div:nth-child(2)>p {
  font-size: 12px;
  font-weight: 600;
}


/* Age Styles */

.studentRow_container>div:nth-child(2) {
  padding: 5px 0;
  text-align: left;
  font-size: 12px;
}

/* Gender Styles */
.studentRow_container>div:nth-child(3) {
  padding: 5px 0;
  text-align: left;
  font-size: 12px;
}

.studentRow_container>div:nth-child(4) {
  padding: 5px 0;
  text-align: left;
  font-size: 12px;
  /* border: 1px solid red; */
  word-wrap: break-word;
}

.studentRow_container>div:nth-child(4)>p {}

/* Screening Styles And Teacher Style */
.studentRow_container>div:nth-child(5) {
  padding: 5px 0;
  text-align: left;
  font-size: 12px;

}

/* Conditional Report Button */
.studentRow_container>div:nth-child(6) {
  /* padding: 5px 0; */
  text-align: left;
}

.report_btn {
  padding: 5px 0;
  width: 72px;
  border: none;
  background: none;
  background-color: #fd6f09;
  font-size: 8px;
  border-radius: 5vh;
  color: #fff;
  /* cursor: pointer; */
  display: flex;
  justify-content: center;
}

.report_btn_active {
  padding: 5px 0;
  width: 72px;
  border: none;
  background: none;
  background-color: #149457 !important;
  font-size: 8px;
  border-radius: 5vh;
  font-weight: 600;
  color: #fff;
  /* cursor: pointer; */
  display: flex;
  justify-content: center;
}

.screened_btn {

  padding: 5px 0;
  width: 72px;
  border: 1px solid #EBECEE;
  background: #EBECEE;
  font-size: 8px;
  color: #B2B7BE !important;
  font-weight: 600;
  border-radius: 5vh;
  cursor: pointer;
  text-align: center;

}

.upcoming_btn {
  padding: 5px 0;
  width: 72px;
  border: 1px solid #eea61f;
  background: #eea61f;
  font-size: 8px;
  color: #fff !important;
  font-weight: 600;
  border-radius: 5vh;
  cursor: pointer;
  text-align: center;

}

.completed_btn {
  padding: 5px 0;
  width: 72px;
  border: 1px solid #EBECEE;
  background: #EBECEE;
  font-size: 8px;
  color: #B2B7BE !important;
  font-weight: 600;
  border-radius: 5vh;
  cursor: pointer;
  text-align: center;

}

.generated_btn {
  padding: 5px 0;
  width: 72px;
  border: 1px solid #E7E7E7;
  background: #FFF;
  font-size: 8px;
  color: #343434 !important;
  font-weight: 600;
  border-radius: 5vh;
  cursor: pointer;
  text-align: center;
}

/* Validated Styling */

.studentRow_container>div:nth-child(7) {
  padding: 5px 0;
  text-align: left;
  font-size: 12px;
}

.studentRow_container>div:nth-child(8) {
  padding: 5px;
  font-size: 12px;

}




/* Responsive Media Query */