.submit_button {
  background-color: #222cc9;
  border-radius: 8px;
  border: none;
  height: 6vh;
  width: -webkit-fill-available;
  color: #fff;
  text-align: center;
  line-height: 1.25;
  font-family: Montserrat;
  font-size: 0.9em;
  font-weight: 600;
  margin: 2vh 0;
}
.type_radio {
  display: flex;
  flex-direction: row;
}
.input_name {
  display: flex;
  justify-content: space-between;
}
.error_text {
  font-size: 10px;
  line-height: 1.2;
  color: #d20909;
}
.country_code {
  width: 7vw;
  height: 6.5vh;
  border-radius: 8px;
  border: solid 1px #c6c6c6;
  background-color: #f9f9ff;
  margin-top: 2vh;
  font-family: Montserrat;
  font-size: 14px;
  line-height: 1.29;
  color: #6b6c6f;
  padding: 0.8vw;
  
}
