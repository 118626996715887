@import url("https://fonts.googleapis.com/css2?family=Montserrat");
.success_page {
  font-family: "Montserrat", sans-serif;
  text-align: center;
  width: 22vw;
}
.success_page_head {
  font-size: 30px;
  line-height: 1;
}
.success_page_subhead {
  font-size: 14px;
  line-height: 1.29;
  color: #66707E;
  padding: 2vh 0;
}
.success_image {
  width: 100px;
  margin: 3vh 0;
  margin-top: 12vh;
}
.submit_button {
  background-color: #222cc9;
  border-radius: 8px;
  border: none;
  height: 6vh;
  width: -webkit-fill-available;
  color: #fff;
  text-align: center;
  line-height: 1.25;
  font-family: Montserrat;
  font-size: 0.9em;
  font-weight: 600;
  margin: 2vh 0;
  cursor: pointer;
}
