.studentDetails {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    padding: 16px;
    border-radius: 16px;
    margin-top: 3vh;
    box-shadow: 0 1px 50px 0 rgba(0, 0, 0, 0.08);
}

.avtarSection {
    display: flex;
    align-items: center;
    gap: 12px;
}

.studentDetails>:nth-child(2) {
    display: flex;
    justify-content: space-around;
    gap: 50px;
    margin: 0 40px;
    height: fit-content;
}

.studentDetails>:nth-child(2)>div>:first-child {
    color: #6B6C6F;
    font-size: 12px;
    margin-bottom: 8px;
}

.studentDetails>:nth-child(2)>div>:first-child+p,
.validatedByText {
    line-height: 18px;
    font-size: 14px;
    gap: 2px;
}

.studentDetails>:nth-child(2)>:last-child>:nth-child(2) {
    display: flex;
}



.student_details_information_name {
    /* border: 1px solid red; */
    font-size: 18px;
    font-weight: bold;
    color: #343434;
}

.container {
    margin-top: 25px;
    border-radius: 16px;
    box-shadow: 0 1px 50px 0 rgba(0, 0, 0, 0.08);
    border: solid 1px #e8e7e7;
    border-top: 0px;
    background-color: #fff;
    /* padding: 3vh 2vw; */
    height: calc(100% - 6vh)
}

.parameterContainer {
    padding: 24px;
    width: 100%;
}

.parameterHeading {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
}

.subParaHeading {
    font-weight: 500;
    font-size: 10px;
    line-height: 14px;
}

.poll_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #E8E7E7;
    padding-bottom: 24px;
}

.dentalHigoCon {
    width: 100%;
    display: flex;
    border-bottom: 1px solid #E8E7E7;
    padding-bottom: 24px;
    gap: 100px;
    align-items: center;

}

.heart_poll_container {
    margin: 32px 0 0 0;
    display: flex;
    flex-direction: column;
}

.heart_poll_container>:nth-child(1) {
    font-weight: 500;
    font-size: 16px;
    line-height: 14px;
}

.heart_poll_container>:nth-child(2) {
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    color: #66707E;
    margin-top: 12px;
    margin-bottom: 8px;
}



.heart_poll_container>:nth-child(3) {
    display: flex;
    gap: 24px;
}

.heart_poll_container_button_inactive {
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    width: 100px;
    height: 56px;
    text-align: center;
    border: none;
    border-radius: 4px;
    border: solid 1px #ebebeb;
    background-color: #f5f5ff;
    color: #000;
}

.heart_poll_container_button_active {
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    width: 100px;
    height: 56px;
    text-align: center;
    border: none;
    border-radius: 4px;
    border: solid 1px #ebebeb;
    background-color: #1740A1 !important;
    color: #fff !important;
}