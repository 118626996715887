@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
* {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
  box-sizing: border-box;
}

.student_layout {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.student_layout > div:first-child > p {
  font-size: 16px;
  font-weight: 600;
}

.student_layout > div:first-child > img {
  height: 12px;
  width: 12px;
}

.student_layout > div:nth-child(2) {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}

.student_layout > div:nth-child(2) > button {
  display: flex;
  border: none;
  background-color: #1740a1;
  color: #fff;
  font-weight: 600;
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;
  justify-content: space-between;
}

.student_layout > div:nth-child(2) > button > span {
  font-size: 12px;
  color: #fff;
  margin: 0 0 0 0.5em;
  padding: 3px;
}

.student_layout > div:nth-child(2) > button > span {
  font-size: 12px;
  color: #fff;
  margin: 0 0 0 0.5em;
}

.cards_container {
  margin: 24px 0 0 0;
  display: flex;
  gap: 24px;
}

.cards_container > div {
  width: 267px;
  height: 132px;
  padding: 24px;
  background: #ffffff;
  border: 1px solid #e8e7e7;
  box-shadow: 0px 1px 50px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
}

.cards_container > div > p:first-child {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #66707e;
}

.cards_container > div > div {
  margin: 24px 0 0 0;
  display: flex;
  gap: 12px;
}

.cards_container > div > div > p {
  font-weight: 400;
  font-size: 20px;
  line-height: 40px;
  color: #66707e;
}
.cards_container > div > div > img{
  height: 40px;
  width: 40px;
}
.cards_container > div > div > p > span{
  font-weight: 400;
  font-size: 28px;
  line-height: 40px;
  color: #CA6B6E;
}

.student_filters {
  margin: 28px 0 0 0;
  /* border: 1px solid red; */
  display: flex;
}
.show_text {
  padding: 8px 0 0 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #66707e;
}

.student_filters > div {
  margin: 0 1em 0 0;
  border-radius: 8px;
}

/* Table Layout */

/* Table Header */
.student_table_header {
  display: grid;
  margin: 32px 0 0 0;
  padding: 0 0 0 16px;
  grid-template-columns: 0.7fr 0.3fr 0.3fr 0.7fr 0.4fr 0.6fr 0.3fr;
  gap: 20px;
}

.student_table_header > div {
  /* border: 1px solid green; */
  font-size: 12px;
  font-weight: 600;
  color: #343434;
  text-align: left;
}

/* Student Body */
.student_column_container {
  display: flex;
  flex-direction: column;
  /* border : 1px solid black; */
  margin: 16px 0 0 0;
}

.student_column_container > div:nth-child(n + 2) {
  margin: 12px 0 0 0;
}

/* Pagination */

.pagination_container {
  display: flex;
  justify-content: space-between;
  margin: 20px 0 0 0;
  padding: 0 0 0 5px;
}

.page_item {
  font-size: 12px;
}

.pagination {
  display: flex;
  align-items: center;
}

.pagination > div:first-child {
  position: relative;
}

.pagination > div:first-child > img:nth-child(2) {
  position: absolute;
  top: 25%;
  left: 32%;
}

.pagination > div:nth-child(2) {
  position: relative;
  font-size: 14px;
  font-weight: bold;
}

.pagination > div:nth-child(2) > span {
  position: absolute;
  top: 25%;
  left: 32%;
}

.pagination > div:nth-child(3) {
  padding: 7px 10px;
  font-size: 14px;
  font-weight: bold;
}

.pagination > div:nth-child(3) > span:first-child {
  color: #e7e7e7;
  padding: 0 12px 0 0;
}

.pagination > div:nth-child(4) {
  position: relative;
}

.pagination > div:nth-child(4) > img:nth-child(2) {
  position: absolute;
  top: 25%;
  left: 32%;
}

@media screen and (min-width: 1024px) and (max-width: 1100px) {
  .student_table_header > div {
    /* border: 1px solid green; */
    font-size: 11px;
    font-weight: 600;
    color: #343434;
    text-align: left;
  }
}
