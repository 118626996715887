.showPages_container{
    display: flex;
    gap: 0.5em;
    justify-content: space-between;
    }
    .showPages_container > div:first-child{
    padding: 8px 0.556vw 0 0;
    font-size: 12px;
    }
    
    .showPages_container > div:nth-child(2){
    width: 5vw;
    display: flex;
    justify-content: space-between;
    border: 1px solid #e8e7e7;
    border-radius: 8px;
    padding: 8px 0 0 0;
    justify-content: space-evenly;
    font-size: 12px;
    }
    .showPages_container > div:nth-child(2) > img{
    padding: 5px 0 0 0;
    width: 12px;
    height: 12px;
    }
    
    .showPages_container > div:nth-child(2) > div:nth-child(1){
    color: #343434;
    }
    