.dentalJawMain {
    position: relative;
    width: fit-content;
}

.p1{
    position: absolute;
    color: grey;
    top : 45%;
    left: 25%
}
.p2{
    position: absolute;
    color: grey;
    top : 45%;
    right: 25%
}
.p3{
    position: absolute;
    color: grey;
    bottom : 40%;
    right: 25%
}
.p4{
    position: absolute;
    color: grey;
    bottom : 40%;
    left: 25%
}

.dentalOptions {
    position: absolute;
    z-index: 1;
    top: 30%;
    right: -55%;
    background-color: #ffffff;
    border-radius: 16px;
    box-shadow: 0px 1px 50px rgba(0, 0, 0, 0.1);
    padding: 10px 5px 10px 10px;
}

.dentalJawMain>svg>g {
    cursor: pointer;
    
}

.dentalJawMain>svg>g:hover{
    outline: dotted;
}
