#healthReport {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 10px 0px
}

#healthReport>div {
    box-shadow: 0px 0px 20px rgb(179, 179, 179);
}


* {
    margin: 0;
    padding: 0;
    font-family: "Inter", sans-serif;
    box-sizing: border-box;
}




.report_header {
    margin: 16px 0 0 0;
    font-family: "Argent CF" !important;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    width: 547px;
    text-align: center;
    color: #001028;
}

.student_information_container {
    margin: 18px auto;
    display: flex;
    width: 547px;
    justify-content: space-between;
    padding: 8px 16px;
    background: #ffffff;
    border: 1px solid #e8e7e7;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
}

.student_information_container>div:first-child {
    display: flex;
    gap: 8px;
}

.student_information_container>div:first-child>div:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    width: 36px;
    border-radius: 50%;
    color: #ffffff;
    font-weight: 600;
    font-size: 24px;
}

.student_information_container>div:first-child>div:nth-child(2) {
    padding: 12px 0 0 0;
    color: #001028;
    font-size: 10px;
}

.student_information_container>div:nth-child(n + 2) {
    padding: 4px 0 0 0;
}

.student_information_container>div:nth-child(n + 2)>p:first-child {
    font-weight: 400;
    font-size: 8px;
    color: #66707e;
}

.student_information_container>div:nth-child(n + 2)>p:nth-child(2) {
    margin: 2px 0 0 0;
    font-weight: 600;
    font-size: 8px;
    color: #001028;
}

.grid_container {
    margin: 10px auto;
    display: grid;
    justify-content: center;
    gap: 12px;
    grid-template-columns: 240px 295px;
    grid-row: auto;
}

.grid_container>div {
    background: #ffffff;
    border: 1px solid #e8e7e7;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    padding: 12px;
}

.physical_screening_container {
    grid-area: 1/1/3/2;
}

.behavioral_container {
    grid-area: 1/2/2/3;
}

.learning_container {
    grid-area: 2/2/3/3;
}

.physical_header_text {
    font-size: 12px;
    font-weight: 600;
    color: #343434;
}

.physical_screening_details_container {
    margin: 16px 0 0 0;
    display: flex;
    gap: 24px;
}

.physical_screening_details_container>div>p:first-child {
    font-size: 8px;
    font-weight: 400;
    color: #66707e;
}

.physical_screening_details_container>div>p:nth-child(2) {
    margin: 2px 0 0 0;
    font-size: 8px;
    font-weight: 600;
    color: #001028;
}

.physical_body_image_container {
    margin: 25px auto;
    position: relative;

}

.healthhead {
    position: relative;
}

.healthface {
    position: absolute;
    top: 28%;
    left: 3%;
}

.image_circles {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18.2px;
    height: 18.2px;
    background: #0bb02436;
    mix-blend-mode: normal;
    opacity: 1;
    filter: blur;
    border-radius: 50%;
    z-index: 0;
}

.image_circe_red {
    background: #f80d3830 !important;
}

.image_circles>img {
    width: 9.1px;
    height: 9.1px;
    z-index: 9;
}

.image_circles>svg {
    width: 9.1px;
    height: 9.1px;
    z-index: 1;
}

.physical_body_image_container {
    text-align: center;
    width: fit-content;
}

.physical_body_information_container_bottom {
    margin: 22px 0 0 0;
    padding: 0 0 0 12px;
    display: flex;
    gap: 24px;
}

.physical_body_information_container_bottom_para_normal {
    margin: 8px 0 0 0;
    font-weight: 700;
    color: #008c1e;
    font-size: 10px;
}

.physical_body_information_container_bottom>div {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.physical_body_information_container_bottom_para {
    margin: 5px 0 0 0;
    font-weight: 700;
    color: #008c1e;
    font-size: 10px;
}

.physical_body_information_container_bottom_sup {
    color: #008c1e;
}

.physical_body_information_container_bottom>div>p:nth-child(3) {
    margin: 4px 0 0 0;
    font-weight: 400;
    color: #001028;
    font-size: 8px;
}

.main {
    position: relative;
    background-color: rgb(238, 238, 238);
    width: 595px;
    height: 842px;
    /* margin: 0 auto; */
    background-image: url("../../assets/images/Background.svg");
}

.parent_container_inner {
    display: flex;
    justify-content: space-between;
    width: 547px;
    padding: 18px 24px 0 24px;
}

.parent_container_inner>div {
    display: flex;
    gap: 8px;
}

.parent_container_inner>div>img {
    width: 50px;
    /* height: 30px; */
    object-fit: contain;
}

.parent_container_inner>div>p {
    padding: 8px 0 0 0;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #001028;
}

.screening_test_header_container {
    margin: 18px auto 0 auto;
    display: flex;
    justify-content: space-between;
    width: 547px;
    padding: 10px 45px 10px 8px;
    background: #ffffff;
    border: 1px solid #e8e7e7;
    box-shadow: 0px 1px 50px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
}

.screening_test_header_container>p {
    font-weight: 400;
    font-size: 8px;
    line-height: 12px;
    color: #001028;
}

.screening_container {
    margin: 18px auto;
    margin-bottom: 0px;
    width: 547px;
    text-align: center;
}

.screening_container_header {
    display: flex;
    gap: 8px;
}

.screening_container_header>div:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    background: #222cc9;
    border-radius: 8px;
}

.screening_container_header>div:nth-child(2) {
    padding: 5px 0 0 0;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #001028;
}

.screening_test_report_container {
    margin: 12px 0 0 0;
}

.screening_test_report_container>div:nth-child(n + 2) {
    margin: 8px 0 0 0;
}

.report_data {
    padding-top: 10px;
    font-size: 10px;
    font-weight: 600;
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 8px
}

.report_text {
    font-size: 8px;
    font-weight: 400;
}

.screening_row {
    margin: 8px 0 0 0;
    display: flex;
    gap: 30px;
    font-weight: 400;
    font-size: 8px;
}

.screening_row>div:first-child {
    width: 110px;
    display: flex;
    gap: 6px;
}

.screening_row>div:nth-child(2) {
    width: 56px;
}

.screening_row>div:nth-child(3) {
    width: 124px;
}

.screening_row>div:nth-child(4) {
    width: 40px;
}

.screening_row>div:nth-child(5) {
    width: 127px;
}

.screening_row>div:first-child>div:first-child {
    margin: 3px 0 0 0;
    width: 4px;
    height: 4px;
    background: #96c717;
    border-radius: 50%;
}

.screening_row>div:first-child>div:nth-child(2) {}

.screening_row>div:nth-child(3)>div:nth-child(n + 2) {
    margin: 6px 0 0 0;
}

.screening_method {
    margin: 10px 0 0 0;
    text-align: left !important;
}

.screening_method>p {
    font-style: italic;
    font-weight: 500;
    font-size: 6px;
    line-height: 7px;
    color: #66707e;
}

.screening_method>p:nth-child(n + 2) {
    margin: 4px 0 0 0;
}

.qrcode_container {
    /* margin: 8px 0 0 0;
    display: flex;*/
    gap: 16px;
    position: absolute;
    bottom: 15px;
    left: 3.8%;
    margin: 16px auto;
    display: flex;
    /* justify-content: space-between; */
    width: 547px;
}

.qrcode_container>div:nth-child(2) {
    font-size: 10px;
    line-height: 12px;
    color: #001028;
}

.qrcode_container>div:nth-child(2)>div:first-child {
    padding: 10px 0 0 0;
    text-align: left;
    font-weight: 600;
}

.qrcode_container>div:nth-child(2)>div:nth-child(2) {
    font-weight: 400;
}

.footer_container {
    position: absolute;
    bottom: 0;
    left: 3.8%;
    margin: 16px auto;
    display: flex;
    justify-content: space-between;
    width: 547px;
}

.footer_container>p:first-child {
    font-weight: 600;
    font-size: 8px;
    line-height: 12px;
    display: flex;
    align-items: center;
    color: #001028;
}

.footer_container>p:nth-child(2) {
    font-weight: 400;
    font-size: 8px;
    line-height: 12px;
    display: flex;
    align-items: center;
    color: #001028;
}

.horizontal_line {
    width: 547px;
    border-top: 1px solid #a4a4a4;
    margin: 32px auto 0 auto;
    text-align: center;
}

.note_container {
    margin: 12px auto 0 auto;
    width: 547px;
}

.note_container>p {
    font-weight: 400;
    font-size: 8px;
    color: #001028;
}

.note_container>p:nth-child(2) {
    margin: 4px 0 2px 0;
}

.information_container {
    margin: 16px auto 0 auto;
    width: 547px;
    padding: 8px;
    background: #ffffff;
    border: 1px solid #e8e7e7;
    border-radius: 4px;
}

.information_container>p:first-child {
    color: #001028;
    font-size: 8px;
    text-align: center;
    text-decoration: underline;
}

.information_container>p:nth-child(2) {
    margin: 4px 0 0 0;
    color: #001028;
    line-height: 10px;
    font-size: 6px;
}

.end_report_container {
    margin: 24px auto 0 auto;
    width: 547px;
    text-align: center;
}

.end_report_container>p {
    color: #001028;
    font-size: 8px;
}

.end_report_container>p:nth-child(2) {
    margin: 4px 0 0 0;
}

.end_report_container>p:nth-child(2)>a {
    color: #3b3cea;
    text-decoration: underline;

}

.issue_box {
    display: flex;
    flex-direction: column;
    gap: 4px;
    background-color: rgb(238, 238, 238);
    border: 1px dashed #fd6f09;
    border-radius: 10px;
    padding: 8px;
}

.issue_box h5 {
    font-weight: 600;
    color: #fd6f09;
}

/* Light green box */
.healthy_box {
    display: flex;
    align-items: center;
    gap: 4px;
    background-color: #d8f2dc;
    /* border: 1px dashed #96c717; */
    color: #008c1e;
    border-radius: 10px;
    padding: 4px 8px;
    font-size: 10px;
    width: 80px;
}

.text-bold {
    font-weight: 600;
}

.slides_player_container {
    display: flex;
    flex-direction: column;
    max-width: 450px;
    align-items: center;
}
  
.slides_player_inner_container {
    display: flex;
    justify-content: space-around;
}
  