.modalBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 0 10px;
    max-width: 384px;
    margin: 0 auto;
}

.modalBox h1 {
    font-family: Argent CF;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    text-align: center;
    color: #1740A1;
}

.modalBox h6 {
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    text-align: center;
    padding-top: 30px;
}

.modalBox p {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    color: #66707E;
}

.modalBox .dateBox {
    display: flex;
    justify-content: center;
    gap: 1rem;
    width: 100%;
}

.modalBox .btnBox {
    display: flex;
    align-items: center;
    width: 100%;
}

.modalBox .btnBox Button {
    width: 100%;
}

.modalBox .closeBtn {
    position: absolute;
    top: 3;
    right: 3%;
    background: #66707E;
    fill: #fff;
    border-radius: 999px;
    cursor: pointer;
}