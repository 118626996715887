* {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
  box-sizing: border-box;
}


.container {
  padding: 32px 24px 0 24px;
}
.customise_header {
  display: flex;
  gap: 16px;
}
.customise_header > p {
  font-family: ArgentLight !important;
  font-weight: 300;
  font-size: 28px;
}
.customise_selected_package {
  margin: 36px 0 0 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #66707e;
}
.package_drawer_btn{
  display: inline-block;
  /* margin: 25px 0 0 0; */
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #3b3cea;
  cursor: pointer;
}
.package_name {
  margin: 8px 0 0 0;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #001028;
}
.physical_checkboxes_container {
  margin: 24px 0 0 0;
}

.checkbox_header {
  font-weight: 400;
  font-size: 16px;
  color: #66707e;
}

.checkbox_sub_header {
  margin: 8px 0 0 0;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #001028;
}
.checkbox_sub_header_extra_margin {
  margin: 16px 0 0 0;
}
.inputLabel {
  font-size: 12px;
}

.behavioral_screening_container {
  margin: 12px 0 0 0;
  padding: 24px 0;
  border-top: 1px solid #ebecee;
  border-bottom: 1px solid #ebecee;
}
.learning_screening_container {
  margin: 24px 0 0 0;
}

.control_btn_container {
  margin: 24px 0 0 0;
  display: flex;
  flex-direction: row-reverse;
  gap: 32px;
  background: #fafafa;
  padding: 20px 16px 20px 0;
  border-radius: 0px 0px 0px 24px;
}

.formControlLabel{
  font-family: "Inter", sans-serif !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  color: #66707e !important;
}

.control_btn {
  background: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 176px;
  height: 56px;
  font-weight: 600;
  border-radius: 8px;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
}
.cancel_btn {
  background: #ffffff;
  border: 1px solid #1740a1;
  color: #1740a1;
  order: 2;
}
.update_btn {
  background: #1740a1;
  color: #ffffff;
  border-radius: 8px;
}
.custom-checkbox-root .MuiSvgIcon-root{
  width: 16px !important;
  height: 16px !important;
}