@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

.container {
  display: flex;
  height: 80vh;
  align-items: center;
  justify-content: center;
}

.container>div>div:first-child {
  font-family: "ArgentCFLight";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  color: #1740a1;
}

.container>div>div:nth-child(2) {
  margin: 32px 0 0 0;
}

.container>div>div:nth-child(2)>button {
  margin: 0 auto;
  background: none;
  border: none;
  width: 342px;
  height: 80px;
  padding: 12px 16px;
  background: #1740a1;
  border-radius: 12px;
  display: flex;
  column-gap: 32px;
  cursor: pointer;
}

.container>div>div:nth-child(2)>button>div:first-child {
  height: 56px;
  width: 56px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.container>div>div:nth-child(2)>button>div:nth-child(2) {
  font-weight: 500;
  font-size: 32px;
  padding: 12px 0 0 0;
  line-height: 32px;
  color: #F5F6F7;
}

.qr_square {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto auto;
  width: 55%;
  height: 70%;
  z-index: 4;
  border: 25px solid rgba(0, 0, 0, 0.45);
  border-radius: 10%;
}

.qr_beam {
  position: absolute;
  width: 45%;
  height: 1%;
  background-color: rgba(255, 87, 87, 0.427);
  left: 0;
  right: 0;
  top: 25%;
  z-index: 5;
  margin: auto auto;
  animation: scanning 1.5s infinite;
}

.closeIcon {
  padding: 2px;
  border-radius: 100%;
  position: absolute;
  z-index: 2;
  top: 5px;
  right: -55px;
  width: fit-content;
}

.closeIcon:hover {
  background-color: #ffffff25;

}

.flipIcon {
  padding: 2px;
  border-radius: 100%;
  position: absolute;
  z-index: 2;
  top: 55px;
  right: -55px;
  width: fit-content;
}

.flipIcon:hover {
  background-color: #ffffff25;

}

@keyframes scanning {
  50% {
    transform: translateY(260px);
  }

}