@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

.container {
  display: flex;
  height: 80vh;
  align-items: center;
  justify-content: center;
}

.container > div > div:first-child {
  font-family: "ArgentCFLight";
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 40px;
  color: #1740a1;
}

.container > div > div:nth-child(2) {
  margin: 32px 0 0 0;
}

.container > div > div:nth-child(2) > button {
  margin: 0 auto;
  background: none;
  border: none;
  width: 342px;
  height: 80px;
  padding: 12px 16px;
  background: #1740a1;
  border-radius: 12px;
  display: flex;
  column-gap: 32px;
  cursor: pointer;
}

.container > div > div:nth-child(2) > button > div:first-child{
  height: 56px;
  width: 56px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.container > div > div:nth-child(2) > button > div:nth-child(2){
font-weight: 500;
font-size: 32px;
padding: 12px 0 0 0;
line-height: 32px;
color: #F5F6F7;
}

.cards_status_container {
    /* margin-top: 38px; */
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 24px;
}

.real_time_header_text {
    margin: 32px 0 0 0;
    color: #001028;
}

.real_time_activity_container {
    margin: 16px 0 0 0;
    padding: 24px;
    background: #FFFFFF;
    border: 1px solid #E8E7E7;
    box-shadow: 0px 1px 50px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
}

.real_time_activity_header_wrapper {
    display: flex;
    justify-content: space-between;
}

.real_time_activity_header_wrapper>div:first-child {
    font-size: 20px;
    font-family: ArgentCFLight;
    font-weight: 500;
}

.real_time_activity_header_wrapper>div:nth-child(2) {
    width: 72px;
    padding: 8px;
    font-size: 10px;
    font-weight: 600;
    color: #FFFFFF;
    text-align: center;
    background: #62A615;
    border-radius: 100px;
}

.real_time_activity_info_container {
    margin: 40px 0 0 0;
    display: grid;
    /* width: 30vw; */
    grid-template-columns: repeat(2, 220px);
    row-gap: 40px;
}



.real_time_activity_info_card {
    display: flex;
    gap: 12px;
}

.real_time_activity_info_card>div:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    background: #F5F6F7;
    border-radius: 8px;
}

.real_time_activity_info_card>div:nth-child(2)>p:first-child {
    font-size: 12px;
    font-weight: 400;
    color: #66707E;
}

.real_time_activity_info_card>div:nth-child(2)>p:nth-child(2) {
    margin: 8px 0 0 0;
    font-size: 12px;
    font-weight: 600;
    color: #001028;
}

.real_time_studentlabe_text {
    color: #3b3cea;
}

.download_csv_wrapper {
    margin: 24px 0 0 0;
    display: flex;
    gap: 8px;
}

.download_csv_wrapper>p {
    color: #001028;
    font-size: 12px;
    font-weight: 600;
}

.download_csv_wrapper>p>button {
    border: none;
    background: none;
    color: #3b3cea;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
}

.today_screening_text {
    margin: 48px 0 0 0;
    font-size: 12px;
    font-weight: 400;
    color: #66707E;
}

.screening_number {
    margin: 8px 0 0 0;
}

.screening_number>span:first-child {
    color: #96c717;
    font-weight: 600;
    font-size: 32px;
}

.screening_number>span:nth-child(2) {
    color: #001028;
    font-weight: 400;
    font-size: 20px;
}

.download_report {
    margin: 12px 0 0 0;
    font-size: 12px;
    font-weight: 600;
    color: #001028;
}

.download_report>button {
    border: none;
    background: none;
    color: #3b3cea;
    font-size: 12px;
    font-weight: 600;
}

.scan_student_btn {
    margin: 40px 0 0 0;
    border: none;
    background: none;
    display: flex;
    gap: 10px;
    background: #1740A1;
    mix-blend-mode: normal;
    border-radius: 12px;
    height: 48px;
    padding: 12px;
    cursor: pointer;
}

.scan_student_btn>div:first-child {
    width: 24px;
    height: 24px;
    background-color: #FFFFFF;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.scan_student_btn>div:nth-child(2) {
    font-weight: 600;
    font-size: 16px;
    color: #FFFFFF;
    padding: 2px 0 0 0;
}


.qr_square {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto auto;
    width: 55%;
    height: 70%;
    z-index: 4;
    border: 25px solid rgba(0, 0, 0, 0.45);
    border-radius: 10%;
}

.qr_beam {
    position: absolute;
    width: 45%;
    height: 1%;
    background-color: rgba(255, 87, 87, 0.427);
    left: 0;
    right: 0;
    top: 25%;
    z-index: 5;
    margin: auto auto;
    animation: scanning 1.5s infinite;
}

.closeIcon {
    padding: 2px;
    border-radius: 100%;
    position: absolute;
    z-index: 2;
    top: 5px;
    right: -55px;
    width: fit-content;
}

.closeIcon:hover {
    background-color: #ffffff25;

}

.flipIcon {
    padding: 2px;
    border-radius: 100%;
    position: absolute;
    z-index: 2;
    top: 55px;
    right: -55px;
    width: fit-content;
}

.flipIcon:hover {
    background-color: #ffffff25;

}

@keyframes scanning {
    50% {
        transform: translateY(260px);
    }

}