@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

.container {
  position: relative;
  margin: 0 auto;
  width: 595px;
  height: 842px;
  padding: 24px 24px 8px 24px;
  background-color: rgb(238, 238, 238);
  background-image: url("../../../assets/images/Background.svg");
}

.header_container {
  display: flex;
  gap: 8px;
}

.header_container>img {
  height: 32px;
  width: 22px;
}

.header_container>p {
  font-family: "Inter";
  padding: 6px 0 0 0;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #001028;
}

.question_container {
  margin: 12px 0 0 0;
  padding: 12px;
  background: #ffffff;
  border: 1px solid #e8e7e7;
  box-shadow: 0px 1px 50px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
}

.question_container>div:first-child {
  font-weight: 400;
  font-size: 8px;
  line-height: 12px;
  color: #6b6c6f;
}

.question_container>div:nth-child(2) {
  margin: 20px 0 0 0;
}

.question_select_container {
  display: flex;
  flex-direction: column;
  /* row-gap: 10px; */
}

.question_radio_box {
  display: flex;
  column-gap: 20px;
}

.question_radio_box>p {
  flex-basis: 267px;
  font-weight: 400;
  font-size: 8px;
  line-height: 12px;
  word-wrap: break-word;
  color: #000000;
}

.radio_toolbar>form {
  display: flex;
  column-gap: 12px;
}

.radio_toolbar input[type="radio"] {
  accent-color: #66707e;
  height: 12px;
  width: 12px;
}

.radio_btn_container {
  display: flex;
  gap: 4px;
}

.radio_btn_container>p {
  font-weight: 400;
  padding: 1px 0 0 0;
  font-size: 8px;
  color: #000000;
}

.question_select_container {
  display: flex;
  flex-direction: column;
  row-gap: 22px;
}

.question_radio_box {
  display: flex;
  column-gap: 20px;
}

.question_radio_box>p {
  flex-basis: 263px;
  font-weight: 400;
  font-size: 8px;
  line-height: 12px;
  word-wrap: break-word;
  color: #000000;
}

.radio_toolbar>form {
  display: flex;
  column-gap: 12px;
}

.radio_toolbar input[type="radio"] {
  accent-color: #66707e;
  height: 12px;
  width: 12px;
}

.radio_btn_container {
  display: flex;
  gap: 4px;
}

.radio_btn_container>p {
  font-weight: 400;
  padding: 1px 0 0 0;
  font-size: 8px;
  color: #000000;
}

.performance_question_container {
  margin: 12px 0 0 0;
  background: #ffffff;
  border: 1px solid #e8e7e7;
  box-shadow: 0px 1px 50px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  padding: 12px;
}

.performance_question_container>div:first-child {
  font-weight: 400;
  font-size: 8px;
  line-height: 12px;
  color: #6b6c6f !important;
}

.performance_question_container>div:nth-child(2) {
  margin: 12px 0 0 0;
}

.box_min_range {
  flex-basis: 160px !important;
}

.performance_question_container .radio_btn_container:nth-child(2),
.performance_question_container .radio_btn_container:nth-child(4) {
  padding-left: 10px;
}

.performance_question_container .radio_btn_container:nth-child(4)>p {
  width: 52px;
}

.copyright_details_container {
  margin: 8px 0 0 0;
  display: flex;
  justify-content: space-between;
}

.copyright_details_container>div {
  width: 250px;
  font-weight: 400;
  font-size: 6px;
  line-height: 10px;
  color: #000000;
}

.method_framework {
  margin: 16px 0 0 0;
  font-weight: 500;
  font-size: 8px;
  line-height: 12px;
  color: #66707e;
}

.certification_container {
  margin: 8px 0 0 0;
  display: flex;
  column-gap: 24px;
}

.footer_main {
  position: absolute;
  bottom: 10px;
}

.footer_container {
  width: 547px;
  display: flex;
  justify-content: space-between;
}

.footer_container>p:first-child {
  font-weight: 600;
  font-size: 8px;
  line-height: 12px;
  color: #001028;
}

.footer_container>p:nth-child(2) {
  font-weight: 400;
  font-size: 8px;
  line-height: 12px;
  color: #001028;
}