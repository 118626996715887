* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Montserrat;
  color: #343434;
}


.studentRow_container {
  display: grid;
  align-items: center;
  border-radius: 8px;
  padding: 10px 0 10px 16px;
  border: 1px solid #e7e7e7;
  background-color: #fff;
  gap: 24px;
  grid-template-columns: 0.5fr 0.3fr 0.5fr 0.3fr 0.3fr 0.3fr 0.2fr;
  transition: all .2s;

}

.studentRow_container:hover {
  box-shadow: -1px 3px 10px rgb(209, 209, 209);
  transform: scale(1.01);
}

.usersRow_container {
  display: grid;
  align-items: center;
  border-radius: 8px;
  padding: 10px 0 10px 16px;
  border: 1px solid #e7e7e7;
  background-color: #fff;
  gap: 24px;
  grid-template-columns: 0.5fr 0.5fr 0.3fr 0.3fr 0.3fr 0.3fr 0.2fr;
  transition: all .2s;

}

.usersRow_container:hover {
  box-shadow: -1px 3px 10px rgb(209, 209, 209);
  transform: scale(1.01);
}

.usersRow_container>div {
  /* border: 1px solid red; */
}

.usersRow_container>div:first-child {
  display: flex;
  font-weight: bold;
  word-wrap: break-word;
  /* display: flex; */
}


.usersRow_container>div:first-child>div:nth-child(2) {
  padding: 5px 0 0 16px;
}

.usersRow_container>div:first-child>div:nth-child(2)>p {
  font-size: 12px;

}


/* Age Styles */

.usersRow_container>div:nth-child(2) {
  padding: 5px 0;
  text-align: left;
  font-size: 12px;
  min-width: 166px;
  word-wrap: break-word;
}

/* Gender Styles */
.usersRow_container>div:nth-child(3) {
  padding: 5px 0;
  text-align: left;
  font-size: 12px;
  word-wrap: break-word;
}

.usersRow_container>div:nth-child(4) {
  display: flex;
  padding: 5px 0;
  text-align: left;
  font-size: 12px;
  justify-content: space-between;
  /* border: 1px solid red; */
}

.usersRow_container>div:nth-child(4)>p:nth-child(2) {
  margin: 3px 0 0 0;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  font-size: 8px;
  background-color: #343434;
  text-align: center;
}

.usersRow_container>div:nth-child(4)>p:nth-child(2)>span {
  font-size: 9px;
  color: #eae5e5;
  font-weight: 600;
}


/* Screening Styles And Teacher Style */
.usersRow_container>div:nth-child(5) {
  padding: 5px 0;
  text-align: left;
  font-size: 12px;


}

.usersRow_container>div:nth-child(5)>p {
  color: #3b3cea;
}

/* Conditional Report Button */
.usersRow_container>div:nth-child(6) {
  padding: 5px 0;
  text-align: left;
}

.report_btn {
  padding: 5px 0;
  width: 72px;
  border: none;
  background: none;
  background-color: #62a615;
  font-size: 8px;
  border-radius: 5vh;
  color: #fff;
  cursor: pointer;
}

.screened_btn {

  padding: 5px 0;
  width: 72px;
  border: 1px solid #e7e7e7;
  background: none;
  font-size: 8px;
  color: #000;
  border-radius: 5vh;
  cursor: pointer;

}

/* Validated Styling */

.usersRow_container>div:nth-child(7) {
  padding: 5px 0;
  text-align: left;
  font-size: 12px;
}

.usersRow_container>div:nth-child(8) {
  padding: 5px;
  font-size: 12px;

}

/* grid-template-columns: 0.5fr 0.3fr 0.3fr 0.3fr 0.3fr 0.3fr 0.2fr; */
.eventRow_container {
  display: grid;
  align-items: center;
  border-radius: 8px;
  padding: 10px 0 10px 16px;
  border: 1px solid #e7e7e7;
  background-color: #fff;
  gap: 20px;
  grid-template-columns: 0.5fr 0.5fr 0.3fr 0.3fr 0.3fr 0.3fr 0.2fr;
  transition: all .2s;

}

.eventRow_container:hover {
  box-shadow: -1px 3px 10px rgb(209, 209, 209);
  transform: scale(1.01);
}

.eventRow_container>div {
  padding: 5px 0;
  text-align: left;
  font-size: 12px;
}

.eventRow_container>div:first-child {
  display: flex;
  align-items: center;
  font-weight: bold;
  word-wrap: break-word;
  /* display: flex; */
  /* border: 1px solid red; */
}


.eventRow_container>div:first-child>div:nth-child(2) {
  padding: 0px 0 0 16px;
}

.eventRow_container>div:first-child>div:nth-child(2)>p {
  font-size: 12px;

}


/* Age Styles */

.eventRow_container>div:nth-child(2) {
  padding: 5px 0;
  text-align: left;
  font-size: 12px;
}

/* Gender Styles */
.eventRow_container>div:nth-child(3) {
  padding: 5px 0;
  text-align: left;
  font-size: 12px;
  min-width: 166px;
  word-wrap: break-word;
}

.eventRow_container>div:nth-child(4) {
  padding: 5px 0;
  text-align: left;
  font-size: 12px;
  /* border: 1px solid red; */

}

/* Screening Styles And Teacher Style */
.eventRow_container>div:nth-child(5) {
  padding: 5px 0;
  text-align: left;
  font-size: 12px;

}

/* Conditional Report Button */
.eventRow_container>div:nth-child(6) {
  padding: 5px 0;
  text-align: left;
}

.report_btn {
  padding: 5px 0;
  width: 72px;
  border: none;
  background: none;
  background-color: #62a615;
  font-size: 8px;
  border-radius: 5vh;
  color: #fff;
  cursor: pointer;
}

.screened_btn {

  padding: 5px 0;
  width: 72px;
  border: 1px solid #e7e7e7;
  background: none;
  font-size: 8px;
  color: #000;
  border-radius: 5vh;
  cursor: pointer;

}

/* Validated Styling */

.eventRow_container>div:nth-child(7) {
  padding: 5px 0;
  text-align: left;
  font-size: 12px;
}

.eventRow_container>div:nth-child(8) {
  padding: 5px;
  font-size: 12px;

}





/* Name and Avatar Styles */
.studentRow_container>div:first-child {
  display: flex;
  align-items: center;
  font-weight: bold;
  word-wrap: break-word;
  /* display: flex; */
}


.studentRow_container>div:first-child>div:nth-child(2) {
  padding: 0px 0 0 16px;
}

.studentRow_container>div:first-child>div:nth-child(2)>p {
  font-size: 12px;

}


/* Age Styles */

.studentRow_container>div:nth-child(2) {
  padding: 5px 0;
  text-align: left;
  font-size: 12px;
}

/* Gender Styles */
.studentRow_container>div:nth-child(3) {
  padding: 5px 0;
  text-align: left;
  font-size: 12px;
  min-width: 166px;
  word-wrap: break-word;
}

.studentRow_container>div:nth-child(4) {
  padding: 5px 0;
  text-align: left;
  font-size: 12px;
  /* border: 1px solid red; */

}

/* Screening Styles And Teacher Style */
.studentRow_container>div:nth-child(5) {
  padding: 5px 0;
  text-align: left;
  font-size: 12px;

}

/* Conditional Report Button */
.studentRow_container>div:nth-child(6) {
  padding: 5px 0;
  text-align: left;
}

.report_btn {
  padding: 5px 0;
  width: 72px;
  border: none;
  background: none;
  background-color: #62a615;
  font-size: 8px;
  border-radius: 5vh;
  color: #fff;
  cursor: pointer;
}

.screened_btn {

  padding: 5px 0;
  width: 72px;
  border: 1px solid #e7e7e7;
  background: none;
  font-size: 8px;
  color: #000;
  border-radius: 5vh;
  cursor: pointer;

}

.infoHover {
  background-color: transparent;
  width: fit-content;

  &:hover {
    background: rgba(#f6f6f6, .3);

    &>.popOnHover {
      opacity: 1;
      width: 104px;
      height: 113px;
    }
  }
}

.infoHover>p {
  height: 25px;
}

.popOnHover {
  box-shadow: 0px 0px 10px lightgrey;
  border-radius: 10px;
  /* white-space: nowrap; */
  overflow: hidden;
  opacity: 0;
  width: 0px;
  height: 113px;
  position: absolute;
  right: 17px;
  top: 10px;
  z-index: 2;
  bottom: -100px;
  transition: all .4s ease;
}

/* Validated Styling */

.studentRow_container>div:nth-child(7) {
  padding: 5px 0;
  text-align: left;
  font-size: 12px;
}

.studentRow_container>div:nth-child(8) {
  padding: 5px;
  font-size: 12px;

}

@media only screen and (min-width : 1024px) and (max-width: 1169px) {

  .studentRow_container {
    display: grid;
    align-items: center;
    border-radius: 8px;
    padding: 10px 0 10px 16px;
    border: 1px solid #e7e7e7;
    background-color: #fff;
    gap: 24px;
    grid-template-columns: 0.5fr 0.3fr 0.65fr 0.3fr 0.3fr 0.3fr 0.2fr;
  }

}