.slideshow_container{
    margin: 24px 0 0 0;
    position: relative;
    /* height: 200px;
    width: 200px; */
    border-radius: 10px;
}
.image_slides{
    max-height: 300px;
    max-width: 450px;
    border-radius: 10px;
    background-color: #0000008f;
    width: 90%;
}

.num_indicator{
    position: absolute;
    text-align: center;
    width: 42px;
    height: 14px;
    border-radius: 5px;
    color: #fff;
    padding: 0 10px;
    font-size: 10px;
    top: 0;
    left : 40%;
    background-color: #000;
}

.arrow_left{
    position: absolute;
    background: none;
    border: none;
    top: 45%;
    left: 0;
    cursor: pointer;
    color: #fff;
}

.arrow_right{
    position: absolute;
    background: none;
    border: none;
    top: 45%;
    right: 0;
    cursor: pointer;
    color: #fff;
}