* {
  font-family: "Inter", sans-serif;
}

.dashboard_layout {
  background-color: #FAF8F7;
}

.dashboard_main {
  display: grid;
  grid-template-columns: 1.2fr 6fr;
  padding: 3vh 2vw;
  grid-gap: 1rem;
  min-height: 100vh;


}

.dashboard_header {
  width: 100%;
}

.sidebar {
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  box-shadow: 0 1px 50px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px #e8e7e7;
  background-color: #fff;
  width: -webkit-fill-available;
  justify-content: space-between;
}

/* .ops_text{
    
   padding: 0vh;
  } */
.school_logo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 3vh 2vw;
  padding-top: 7vh;


}

.logo_image {
  width: 5vw;

}

.logo_text {
  font-size: 12px;
  line-height: 1.33;
  text-align: center;
  color: #000;
  padding-top: 2vh;
}

.sidebar_items {
  display: flex;
  align-items: center;
  padding: 2vh 2vw;


}

.sidebar_items_text {
  padding-left: 1vw;
  font-size: 14px;
  line-height: 1.14;
  color: #707070;

}

.sidebar_footer {
  padding: 4vh 0;
}

.app_version {
  font-size: 10px;
  color: #303030;
  text-align: center;
}

.sidebar_items_footer {
  display: flex;
  align-items: center;
  padding: 0vh 2vw;
  padding-bottom: 5vh;
}

.sidebar_items_footer>a {
  text-decoration: none;
}


.layout_right {
  padding: 2vh 2vw;
}

.help_support {
  padding-top: 15vh;
}

.school {
  border: solid 1px #e8e7e7;
  background-color: #fff;
  border-radius: 50%;
  width: 7vw;
  height: 7vw;
  text-align: center;
  align-items: center;
  padding: 1vh;
  padding-top: 2.8vh;
}

.dashboard_header {
  /* box-shadow: 0 1px 24px 0 rgba(0, 0, 0, 0.08); */
  background-color: #FAF8F7;
  display: flex;
  justify-content: space-between;
  padding: 1vh 2vw;
  align-items: center;
}

.dashboard_header_left {
  display: flex;
  align-items: center;
}

.header_left_text {
  /* font-size: 1em; */
  font-size: 1.2em;
  line-height: 1.5;
  color: #303030;
}

.dashboard_header_right {
  display: flex;
  gap: 16px;
}

.bar_line {
  /* border:1px solid green; */
  display: flex;
  gap: 10px;
  align-items: center;
}

.notification {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  height: 48px;
  width: 48px;
  border-radius: 12px;
  border: 1px solid #EBECEE;
  cursor: pointer;
}



.principal_name {
  /* font-size: 0.8em; */
  font-weight: bold;
  line-height: 1.14;
  color: #000;
  font-size: 13px;

}

.dashboard_name {
  display: flex;
  align-items: center;
  gap: 8px;
}

.avatar_logo {
  /* height: 5.5vh; */
  width: 3vw;
}

.main_logo {
  width: 80%;
}

.principal_text {
  /* font-size: 0.7em; */
  font-size: 9.5px;
  color: #303030;
}

.dashboard_header_deatils {
  display: flex;
  gap: 16px;
  /* border:1px solid green; */
  /* margin-left: -20px; */
}

.dropdown {
  padding: 6px 0 0 0;
  cursor: pointer;
}

.infoHover {
  /* background-color: white; */
  width: fit-content;

  &:hover {
    background: rgba(#f6f6f6, .3);

    &>.popOnHover {
      opacity: 1;
      width: 95px;
      transform: scaleY(1);
    }
  }
}

.infoHover:hover>.popOnHover {
  opacity: 1;
  width: 95px;
  transform: scaleY(1);
}

.popOnHover {
  background-color: white;
  box-shadow: 0px 0px 10px lightgrey;
  border-radius: 10px;
  white-space: nowrap;
  transform: scaleY(0);
  overflow: hidden;
  opacity: 0;
  width: 95px;
  height: fit-content;
  position: absolute;
  right: 30px;
  top: 35px;
  z-index: 50;
  bottom: -100px;
  transition: all .4s ease;
}

.dropdown_items {
  background-color: white;
  font-size: 0.8em;
  font-weight: 500;
  line-height: 1.14;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.dropdown_items:hover {
  background-color: rgb(239, 239, 239);
}

.dropdown_notification {
  border-radius: 8px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.24);
  background-color: #fff;
  padding: 2vh 1vw;
  z-index: 9999;
  position: absolute;
  top: 9vh;
  left: 50vw;
}

.dropdown_notify {
  font-size: 0.8em;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  color: #000;
  padding: 1.5vh 0;
}

.notify_Heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.view_heading {
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #3b3cea;
}

.dropdown_notify {
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #0e0e0e;
}

.notify_detail {
  display: flex;
  align-items: center;
  gap: 30px
}

.loreum {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #000;
}

.dashboardCard a {
  text-decoration: none;
}

.active_link_container {
  /* background-color: #000; */
  background: linear-gradient(to right,
      #707070 0%,
      #707070 1.5%,
      #000 1.5%,
      #000 100%);
}

.active_link_text {
  padding-left: 1vw;
  font-size: 14px;
  line-height: 1.14;
  color: #ffffff !important;
  transition-delay: 2s;
}