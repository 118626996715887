.section_container{
    display: flex;
    flex-basis: 11.11vw;
    border-radius: 8px;
    border: 1px solid #e8e7e7;
    background-color: #fff;
    justify-content: space-between;
    padding: 8px 0.556vw 8px 1.111vw;
    }
    
    .section_container > p{
    font-size: 12px;
    font-weight: 500;
    }
    .section_container > img{
    padding: 5px 0 0 0;
    height: 12px;
    width: 12px;
    }
    