body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.9);
  border-radius: 10px;
  background-color: #F5F5F5;
}

body::-webkit-scrollbar {
  width: 7px;
  background-color: #F5F5F5;
}

body::-webkit-scrollbar:horizontal{
  height: 6px;
}

body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.9);
  background-color: rgb(0, 0, 0);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@font-face {
  font-family: ArgentCF;
  src: url('../fonts/ArgentCF-Bold.ttf');
}
@font-face {
  font-family: ArgentCFLight;
  src: url('../fonts/ArgentCF-Light.ttf');
}
@font-face {
  font-family: ArgentCFThin;
  src: url('../fonts/ArgentCF-Thin.ttf');
}