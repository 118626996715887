.container{
}
.parent_container{
    margin: 15% 0 0 0;
}

.header_text{
    font-family: ArgentCFLight;
    color: #1740A1;
    font-size: 32px;
    font-weight: 500;
    text-align: center;
}
.searchbar_container{
    margin: 32px 0 0 0;
}

.MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-endAdornment{
    display: none;
}
