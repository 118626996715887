* {
  font-family: "Inter", sans-serif;
}

.submit_button {
  background-color: #222cc9;
  border-radius: 8px;
  border: none;
  height: 6vh;
  width: -webkit-fill-available;
  color: #fff;
  text-align: center;
  line-height: 1.25;
  font-family: Montserrat;
  font-size: 0.9em;
  font-weight: 600;
  margin: 2vh 0;
  cursor: pointer;
}

.login_page {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.error_text {
  margin: 14px 0 0 0;
  font-size: 10px;
  line-height: 1.2;
  color: #d20909;
}

.password_parameters {
  display: flex;
  margin: 24px 0 0 0;
}

.password_parameters_containers {
  /* display: flex; */
  /* flex-direction: column; */
  display: grid;
  grid-template-columns: 1fr;
  gap: 12px;
  width: 50%;
  text-align: left;
}

.password_parameters_boxes {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 12px;
}

.password_parameters_boxes>div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.password_parameters_boxes>p {
  color: #B2B7BE;
}

.password_parameters_boxes_checked>p {
  color: #149547;
}

.password_parameters_boxes_wrong>p {
  color: #D95A59;
}