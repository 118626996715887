@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

* {
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  box-sizing: border-box;
  color: #343434;
}

.main_div {
  width: 100%;
}

.student_layout {
  display: flex;
  justify-content: space-between;
}

.student_filters {
  margin: 28px 0 0 0;
  /* border: 1px solid red; */
  display: flex;
}

.student_layout>div:nth-child(2) {
  display: flex;
  gap: 16px;
}

.student_filters>div {
  margin: 0 1em 0 0;
  border-radius: 8px;
}

.tabBtn_container {
  display: flex;
  gap: 16px;
}

.sharetypeform_btn {
  background: none;
  border: none;
  border-radius: 8px;
  padding: 8px;
  font-weight: 600;
  display: flex;
  gap: 4px;
  cursor: pointer;
  background-color: #1740A1;
}

.sharetypeform_btn>img {
  height: 24px;
  width: 24px;
}

.sharetypeform_btn>span {
  padding: 3px;
  color: #fff;
}




/* Table Layout */

/* Table Header */
.student_table_header {
  display: grid;
  margin: 32px 0 0 0;
  padding: 0 0 0 16px;
  /* grid-template-columns: 0.6fr 0.2fr 0.3fr 0.4fr 0.4fr 0.4fr 0.3fr 0.2fr; */
  grid-template-columns: 1fr 0.3fr 0.3fr 0.3fr 1fr 0.3fr 0.3fr 0.3fr;
  gap: 20px;
}

.student_table_header>div {
  /* border: 1px solid green; */
  font-size: 12px;
  font-weight: 600;
  color: #343434;
  text-align: left;
}

.student_table_header>div:nth-child(8) {
  justify-self: center;
}


/* Student Body */
.student_column_container {
  display: flex;
  flex-direction: column;
  /* border : 1px solid black; */
  margin: 16px 0 0 0;
}

.student_column_container>div:nth-child(n+2) {
  margin: 12px 0 0 0;
}


.pagination_container {
  display: flex;
  justify-content: space-between;
  margin: 20px 0 0 0;
  padding: 0 0 0 5px;
}

.page_item {
  font-size: 12px;
}

.pagination {
  display: flex;
  align-items: center;
}

.pagination>div:first-child {
  position: relative;
}

.pagination>div:first-child>img:nth-child(2) {
  position: absolute;
  top: 25%;
  left: 32%;
}


.pagination>div:nth-child(2) {
  position: relative;
  font-size: 14px;
  font-weight: bold;
}

.pagination>div:nth-child(2)>span {
  position: absolute;
  top: 25%;
  left: 32%;
}


.pagination>div:nth-child(3) {
  padding: 7px 10px;
  font-size: 14px;
  font-weight: bold;
}

.pagination>div:nth-child(3)>span:first-child {
  color: #e7e7e7;
  padding: 0 12px 0 0;
}

.pagination>div:nth-child(4) {
  position: relative;
}

.pagination>div:nth-child(4)>img:nth-child(2) {
  position: absolute;
  top: 25%;
  left: 32%;
}

.scan_student_btn {
  border: none;
  background: none;
  display: flex;
  gap: 5px;
  background: #1740A1;
  mix-blend-mode: normal;
  border-radius: 12px;
  width: 160px;
  height: 44px;
  padding: 12px;
  cursor: pointer;
}

.scan_student_btn>div:first-child {
  width: 24px;
  height: 24px;
  background-color: #FFFFFF;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scan_student_btn>div:nth-child(2) {
  font-weight: 600;
  font-size: 16px;
  color: #FFFFFF;
  padding: 2px 0 0 0;
}

.print_student_qr {
  border: none;
  background: none;
  display: flex;
  gap: 5px;
  background: #B2B7BE;
  mix-blend-mode: normal;
  border-radius: 12px;
  width: 160px;
  height: 44px;
  padding: 2px 5px;
  cursor: pointer;
}

.print_student_qr>div:first-child {
  padding: 8px 0 0 0;
}

.print_student_qr>div:first-child>div:first-child {
  width: 24px;
  height: 24px;
  background-color: #FFFFFF;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.print_student_qr>div:nth-child(2) {
  font-weight: 600;
  font-size: 16px;
  color: #FFFFFF;
  padding: 2px 0 0 0;
}


.opsBtn {
  border: none;
  background: #1740A1;
  font-weight: 600;
  font-size: 15px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  min-width: 160px;
  min-height: 44px;
  padding: 2px 10px;
  cursor: pointer;
}

.OpsBtnInactive {
  border: none;
  background: #B2B7BE;
  font-weight: 600;
  font-size: 15px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  min-width: 160px;
  min-height: 44px;
  padding: 2px 10px;
}