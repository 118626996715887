.main_div {
    width: 100%;
}

.student_layout {
    display: flex;
    justify-content: space-between;
}

.student_filters {
    display: flex;
    align-items: center;
    gap: 16px;
}

.checkIcon {}

.studentDetails {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    padding: 16px;
    border-radius: 16px;
    margin-top: 3vh;
    box-shadow: 0px 1px 50px rgba(0, 0, 0, 0.08);
}

.avtarSection {
    display: flex;
    align-items: center;
    gap: 12px;
}

.studentDetails>:nth-child(2) {
    display: flex;
    justify-content: space-around;
    gap: 50px;
    margin: 0 40px;
    height: fit-content;
}

.studentDetails>:nth-child(2)>div>:first-child {
    color: #6B6C6F;
    font-size: 12px;
    margin-bottom: 8px;
}

.studentDetails>:nth-child(2)>div>:first-child+p,
.validatedByText {
    line-height: 18px;
    font-size: 14px;
    gap: 2px;
}

.studentDetails>:nth-child(2)>:nth-last-child(2)>:nth-child(2) {
    display: flex;
    gap: 8px;
}

.studentDetails>:nth-child(2)>:nth-last-child(2)>:nth-child(2)>div {
    font-size: 10px;
    color: #B2B7BE;
}

.studentDetails>:nth-child(2)>:nth-last-child(2)>:nth-child(2)>div+div {
    width: 100%;
    margin: auto;
}

.studentDetails>:nth-child(2)>:nth-last-child(2)>:nth-child(2)>div>span {
    font-size: 16px;
    color: #149457;
    font-weight: 600;
}

.doneButton {
    background-color: #1740A1;
    border-bottom: 5px solid #0e2e78;
    box-shadow: 0px 4px 6px gray;
    cursor: pointer;
    color: white;
    padding: 10px 20px;
    border-radius: 4px;
}

.doneButton:hover {
    background-color: #264faf;

}

.doneButton:active {
    border-bottom: 2px solid #0e2e78;
    box-shadow: 0px 2px 3px gray;


}

.container {
    border-radius: 16px;
    display: flex;
    margin-top: 3vh;
    background-color: white;
    box-shadow: 0px 1px 50px rgba(0, 0, 0, 0.08);
}


.container>:nth-child(1) {
    border-right: 1px solid #E8E7E7;
}


.container>:nth-child(1)>p {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    padding: 24px;
    padding-right: 30px;
    white-space: nowrap;
}

.parametersCheckActive {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 24px;
    border-left: 4px solid black;
    background-color: #F5F6F7;
}

.parametersCheckInactive {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 24px;
}


.parameterActive {
    font-weight: 600;
    font-size: 16px;
    line-height: 32px;
}

.parameterInactive {
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
}

.parametersTest {
    width: 100%;
    padding: 24px;
}


.parameterHeading {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
}

.subParaHeading {
    font-weight: 500;
    font-size: 10px;
    line-height: 14px;
}

.avatar_container {
    display: flex;
    justify-content: center;
    gap: 12px;
}


.upload_container {
    display: flex;
    gap: 12px;
}

.text_container {
    text-align: center;
    padding: 16px;
    color: #343434;
    font-size: 18px;
    font-weight: bold;
}

.details_container {
    margin: 8px 0 0 0;
    display: grid;
    grid-row-gap: 4px;
    grid-column-gap: 20px;
}

.details_container>div {
    min-width: 112px;
    /* padding: 5px; */
}

.details_container>div:nth-child(1),
.details_container>div:nth-child(2),
.details_container>div:nth-child(3) {
    color: #6b6c6f;
    font-size: 12px;
}

.details_container>div:nth-child(n+4) {
    font-size: 14px;
    line-height: 1.29;
}

.details_container>div:nth-child(4) {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
}

.details_container>div:nth-child(5) {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
}

.details_container>div:nth-child(6) {
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 2;
    grid-row-end: 3;
}

.details_body_container {
    position: relative;
    margin: 24px auto;
    /* border: 1px solid red; */
    width: 200px;
    text-align: center;
}

.head_container {
    position: absolute;
    top: -1.5%;
    left: 44%;
}

.head_test_container {
    position: relative;
    width: 22.5px;
    height: 22.5px;
    border-radius: 50%;
    border: solid 0.5px #13bd28;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.head_test_container>img:first-child {
    width: 11.3px;
    height: 11.3px;
}

.head_test_container>img:nth-child(2) {
    position: absolute;
    height: 5.6px;
    width: 5.6px;
    top: 0;
    right: 6%;
}

.eye_container {
    position: absolute;
    top: 5%;
    left: 35%;
}

.eye_test_container {
    position: relative;
    width: 22.5px;
    height: 22.5px;
    border-radius: 50%;
    background-color: #d8f2dc;
    display: flex;
    justify-content: center;
    align-items: center;
}

.eye_test_container>img:first-child {
    width: 11.3px;
    height: 11.3px;
}


.wind_container {
    position: absolute;
    top: 13%;
    left: 44%;
}

.wind_test_container {
    position: relative;
    width: 22.5px;
    height: 22.5px;
    border-radius: 50%;
    background-color: #d8f2dc;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wind_test_container>img:first-child {
    width: 11.3px;
    height: 11.3px;
}


.ent_container {
    position: absolute;
    top: 18%;
    left: 44%;
}

.ent_test_container {
    position: relative;
    width: 22.5px;
    height: 22.5px;
    border-radius: 50%;
    background-color: #d8f2dc;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ent_test_container>img:first-child {
    width: 11.3px;
    height: 11.3px;
}


.pulmonology_container {
    position: absolute;
    top: 26%;
    left: 44%;
}

.pulmonology_test_container {
    position: relative;
    width: 22.5px;
    height: 22.5px;
    border-radius: 50%;
    background-color: #d8f2dc;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pulmonology_test_container>img:first-child {
    width: 11.3px;
    height: 11.3px;
}

.heartrate_container {
    position: absolute;
    left: 57%;
    top: 29%;
}

.heartrate_test_container {
    position: relative;
    width: 22.5px;
    height: 22.5px;
    border-radius: 50%;
    border: solid 0.5px #13bd28;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.heartrate_test_container>img:first-child {
    width: 11.3px;
    height: 11.3px;
}

.heartrate_test_container>img:nth-child(2) {
    position: absolute;
    height: 5.6px;
    width: 5.6px;
    top: 0;
    right: 6%;
}



.heartrate_container {
    position: absolute;
    left: 57%;
    top: 29%;
}

.heartrate_test_container {
    position: relative;
    width: 22.5px;
    height: 22.5px;
    border-radius: 50%;
    border: solid 0.5px #13bd28;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.heartrate_test_container>img:first-child {
    width: 11.3px;
    height: 11.3px;
}

.heartrate_test_container>img:nth-child(2) {
    position: absolute;
    height: 5.6px;
    width: 5.6px;
    top: 0;
    right: 6%;
}


.ear_test_container {
    position: relative;
    width: 22.5px;
    height: 22.5px;
    border-radius: 50%;
    border: solid 0.5px #13bd28;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ear_test_container>img:first-child {
    width: 11.3px;
    height: 11.3px;
}

.ear_test_container>img:nth-child(2) {
    position: absolute;
    height: 5.6px;
    width: 5.6px;
    top: 0;
    right: 6%;
}


.gastroenterology_container {
    position: absolute;
    top: 40%;
    left: 44%;
}

.gastroenterology_test_container {
    position: relative;
    width: 22.5px;
    height: 22.5px;
    border-radius: 50%;
    background-color: #d8f2dc;
    display: flex;
    justify-content: center;
    align-items: center;
}

.gastroenterology_test_container>img:first-child {
    width: 11.3px;
    height: 11.3px;
}


.fingerprint_container {
    position: absolute;
    top: 61%;
    left: 60%;
}

.fingerprint_test_container {
    position: relative;
    width: 22.5px;
    height: 22.5px;
    border-radius: 50%;
    background-color: #d8f2dc;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fingerprint_test_container>img:first-child {
    width: 11.3px;
    height: 11.3px;
}


.contaier_screening>:nth-child(2)>div>p {
    font-weight: 700;
    font-size: 16px;
}

.organs_navigator {
    display: flex;
    /* width: 48.036vw; */
    border: 1px solid #343434;
    flex-wrap: wrap;
    gap: 5px;
    padding: 4px;
    border-radius: 8px;
    padding: 4px;

}

.organs_navigator>div {
    border-radius: 8px;
    color: #343434;
    font-size: 14px;
    padding: 6px 10px;
    cursor: pointer;
}

.organs_navigator_elements_active {
    background-color: #343434 !important;
    color: #fff !important;
}

.heart_poll_container {
    margin: 32px 0 0 0;
    display: flex;
    flex-direction: column;
}

.heart_poll_container>:nth-child(1) {
    font-weight: 500;
    font-size: 16px;
    line-height: 14px;
}

.heart_poll_container>:nth-child(2) {
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    color: #66707E;
    margin-top: 12px;
    margin-bottom: 8px;
}


.heart_poll_container>:nth-child(3) {
    display: flex;
    gap: 24px;
}

.heart_poll_container_button_inactive {
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    width: 100px;
    height: 56px;
    text-align: center;
    border: none;
    border-radius: 4px;
    border: solid 1px #ebebeb;
    background-color: #f5f5ff;
    color: #000;
}

.heart_poll_container_button_active {
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    width: 100px;
    height: 56px;
    text-align: center;
    border: none;
    border-radius: 4px;
    border: solid 1px #ebebeb;
    background-color: #1740A1 !important;
    color: #fff !important;
}

.poll_container {
    width: 100%;
    display: flex;
    padding-bottom: 24px;
    gap: 120px;
}

.buttons_container {
    display: flex;
    flex-direction: column;
}

.buttons {
    display: flex;
    justify-content: flex-end;
}

.button_with_border {
    font-size: 0.9em;
    font-weight: 600;
    line-height: 1.25;
    width: 12.2vw;
    text-align: center;
    color: #000;
    padding: 1.3vh 2.5vw;
    border-radius: 8px;
    border: solid 1px #000;
    background-color: #fff;
    margin-right: 2vw;
    cursor: pointer;
}

.button_filled {
    border: none;
    font-size: 0.9em;
    width: 12.2vw;
    border-radius: 8px;
    background-color: #000;
    font-weight: 600;
    line-height: 1.25;
    padding: 1.3vh 1vw;
    text-align: center;
    color: #fff;
    cursor: pointer;
}

.button_filled:disabled {
    background-color: #ADADAD !important;
    color: #fff;
}

.qr_square {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto auto;
    width: 55%;
    height: 70%;
    z-index: 4;
    border: 25px solid rgba(0, 0, 0, 0.45);
    border-radius: 10%;
}

.qr_beam {
    position: absolute;
    width: 45%;
    height: 1%;
    background-color: rgba(255, 87, 87, 0.427);
    left: 0;
    right: 0;
    top: 25%;
    z-index: 5;
    margin: auto auto;
    animation: scanning 1.5s infinite;
}

/*------------------------------- kid body styling-------------------------- */

* {
    font-family: Montserrat;
}

.main_div {
    width: 100%;
}

.header {
    display: flex;
    align-items: center;
}

.header_text {
    font-weight: 500;
    color: #000;
}

.back {
    font-weight: 500;
    color: #9d9d9d;
}

/* .container {
    display: flex;
    border-radius: 16px;
    height: calc(100% - 6vh)
} */

.student_details_container {
    display: flex;
    /* border: 1px solid red; */
    padding: 24px;
    justify-content: space-between;
}

.student_details {
    display: flex;
    /* border : 1px solid red; */
    gap: 16px;
}

.parameterContainer {
    padding: 24px;
    width: 100%;
}



.student_details_information {
    /* border: 1px solid red; */
}

.student_details_information_name {
    /* border: 1px solid red; */
    font-size: 18px;
    font-weight: bold;
    color: #343434;
}

.student_details_information_general_info {
    margin: 11px 0 0 0;
    display: flex;
    /* border: 1px solid red; */
    justify-content: space-between;
    gap: 7px;
}

.student_details_information_general_info>p:nth-child(odd) {
    color: #343434;
    font-size: 14px;
}

.student_details_information_general_info>p:nth-child(even) {
    /* border: 1px solid red; */
    height: 2px;
    width: 2px;
    color: #9d9d9d;
    border-radius: 50%;

}

.download_report_btn {
    display: flex;
    height: 32px;
    gap: 16px;
}


.download_report_btn>button {
    display: flex;
    border: none;
    background-color: #303030;
    ;
    color: #fff;
    border-radius: 5vh;
    font-size: 12px;
    padding: 8px;
    cursor: pointer;
    justify-content: space-between;
}

.download_report_btn>button>span {
    font-size: 12px;
    color: #fff;
    margin: 0 0 0 0.5em;
}

.screening_container {
    margin: 12px 0 0 0;
    border-bottom: 2px solid #ececec;
    padding: 10px 0 0 24px;
    display: flex;
    gap: 28px;
}

.screening_container>div {
    cursor: pointer;
}

.screening_container_active {
    font-size: 14px;
    font-weight: bold;
    color: #343434;
}

.screening_container_inactive {
    font-size: 14px;
    color: #696969;
    font-weight: normal;
}

.screening_active_bar {
    margin: 1px 0 0 0;
    width: 2.8vw;
    border-bottom: 2px solid #343434;

}

.fullbody_physical_report_container {
    display: grid;
    padding: 22px 0 0 24px;
    grid-template-columns: 2fr 1fr;
}

.fullbody_physical_report_container>div {
    /* border: 1px solid red; */
}

.physical_report_header {
    display: flex;

}

.physical_report_header>div {
    width: 200px;
    font-size: 12px;
    color: #6b6c6f;
}

.physical_report_header_data {
    display: flex;
    margin: 4px 0 0 0;
    /* border: 1px solid red; */
}

.physical_report_header_data>div {
    width: 200px;
    font-size: 14px;
    color: #000;

}

.exam_data_header {
    margin: 32px 0 0 0;
    font-size: 18px;
    font-weight: bold;
    color: #343434;
}

.exam_data_info {
    margin: 32px 0 0 0;
    display: flex;
    gap: 31px;
}

.exam_data_info>div {
    /* border: 1px solid red; */
    text-align: center;
}

.exam_data_low_hemoglobin {
    color: #e36c00 !important;
}

.exam_data_info>div>img {
    height: 32px;
    width: 32px;
    object-fit: contain;
    /* border: 1px solid red; */
}

.exam_data_info>div>p:nth-child(2) {
    margin: 16px 0 0 0;
    font-size: 14px;
    font-weight: bold;
    color: #008c1e;
}

/* .exam_data_info > div > p:nth-child(2) > sup {
    margin: 0 !important;
    font-size: 14px;
    font-weight: bold;
    color: #008c1e;
} */
.exam_data_info>div>p:nth-child(3) {
    margin: 5px 0 0 0;
    color: #676767;
}

.ausculation_heading {
    font-size: 15px;
    margin: 18px 0px;
}

.audioPlayerWrapper {
    width: 50%;
    margin-bottom: 10px;
}



.organs_container {
    display: flex;
    margin: 29px 0 0 0;
    border: 1px solid #343434;
    width: 38.75vw;
    border-radius: 8px;
    padding: 4px;
    justify-content: space-between;
}


.organs_container>div {
    cursor: pointer;
    border-radius: 8px;
    color: #343434;
    font-size: 14px;
    padding: 6px 10px;
}

.organs_container_elements_active {
    background-color: #343434 !important;
    color: #fff !important;
}

.fullbody_physical_outer_container {
    min-height: 548px;
    display: flex;
    justify-content: center;
}

.fullbody_physical_image_container {
    position: relative;
    width: 154px;
    display: flex;
    align-items: center;
}

.fullbody_physical_image_container>div {
    font-size: 12px;
    text-align: center;

}

.fullbody_physical_image_container>img {
    position: absolute;
    width: 154px;
    z-index: 2;
}

.fullbody_physical_line_container {
    position: relative;
    margin-top: 30px;
    border-bottom: 2px solid #9d9d9d;
    border-left: 2px solid #9d9d9d;
    height: fit-content;
    padding: 0 0 30px 30px;
    box-sizing: border-box;
}

.fullbody_physical_line_container>span {
    position: absolute;
    background-color: white;
    bottom: -3px;
    left: -3px;
    width: 30px;
    height: 35px;
    z-index: 5;
}

.fullbody_physical_line_container>span:nth-child(2) {
    position: absolute;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    top: 43%;
    left: -35px;
}

.fullbody_physical_line_container>span:nth-child(3) {
    position: absolute;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    bottom: -37px;
    left: 45%;
    background-color: transparent;

}



/* .fullbody_physical_height_container{
  border: 1px solid black;
  display: flex;
} */

/* .fullbody_physical_image_inner_container{
    position: relative;
    border: 1px solid red;
    margin: 0 auto;
} */

/* .fullbody_physical_image_inner_container > img {
    position: absolute;
    top: 0;
    left: 3.1%;
    border-left: 1px solid #9d9d9d;
    padding: 0 0 0 2vw;
}  */
/* .weight_bar{
  margin : 24px 0 0 0;
  border-top: 1px solid #9d9d9d;
  width: 145px;
  text-align: center;
  padding: 7px 0 0 0;
  font-size: 12px;
}
.height_bar{
  position: absolute;
  font-size: 12px;
  left: -15%;
  top: 47%;
} */

.fullbody_green_zone {
    margin: 0 auto;
    position: relative;
    background-color: rgba(41, 155, 8, 0.08);
    border: 2px dashed rgb(41, 155, 8);
    border-top: 0px;
    border-bottom: 0px;
    background-color: #EEF7EC;
    /* width: 7.361vw; */
    width: 103px;
    height: 546px;
}

.fullbody_green_zone_obesese {
    margin: 0 auto;
    position: relative;
    background-color: rgba(41, 155, 8, 0.08);
    border: 2px dashed rgb(41, 155, 8);
    border-top: 0px;
    border-bottom: 0px;
    background-color: #EEF7EC;
    /* width: 7.361vw; */
    width: 93px;
    height: 546px;
}

.fullbody_green_zone_skinny {
    margin: 0 auto;
    position: relative;
    background-color: rgba(41, 155, 8, 0.08);
    border: 2px dashed rgb(41, 155, 8);
    border-top: 0px;
    border-bottom: 0px;
    background-color: #EEF7EC;
    /* width: 7.361vw; */
    width: 114px;
    height: 546px;
}

.obsese_outer_line_left {
    position: absolute;
    z-index: 1;
    width: 8px;
    background-color: rgba(248, 13, 36, 0.2);
    border-left: 2px dashed red;
    height: 95%;
    left: 30%;
}

.obsese_outer_line_right {
    position: absolute;
    z-index: 1;
    width: 8px;
    background-color: rgba(248, 13, 36, 0.2);
    border-right: 2px dashed red;
    height: 95%;
    right: 16%;
}

.skinny_outer_line_left {
    position: absolute;
    z-index: 1;
    width: 8px;
    background-color: rgba(248, 13, 36, 0.2);
    border-right: 2px dashed red;
    height: 95%;
    left: 29%;
}

.skinny_outer_line_right {
    position: absolute;
    z-index: 1;
    width: 8px;
    background-color: rgba(248, 13, 36, 0.2);
    border-left: 2px dashed red;
    height: 95%;
    right: 15%;

}


.stethoscope_check_container {
    position: absolute;
    top: 2%;
    left: 42%;
    z-index: 9;

}

.stethoscope_check {
    position: relative;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    border: 1px solid #13bd28;
    background-color: #fff;
}

.stethoscope_check>img {
    position: absolute;
}

.stethoscope_check>img:first-child {
    top: 5%;
    right: 0;
}

.stethoscope_check>img:nth-child(2) {
    height: 12px;
    width: 12px;
    top: 27%;
    left: 25%;
}

.visibility_container {
    position: absolute;
    width: 48px;
    height: 48px;
    padding: 4px;
    border-radius: 50%;
    z-index: 9;
    top: 6.5%;
    left: 25%
}

.visibility_container_active {
    background-color: rgba(0, 0, 0, 0.04);
}

.visibility_container_main {
    position: absolute;
    z-index: 10;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* left: 50%; */
}

.visibility_container_main_active {
    background-color: rgba(0, 184, 23, 0.2);
    animation: pulse 1s infinite;
}

.visibility_container_inner {
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #d8f2dc;
}

.visibility_container_inner_active {
    border: 1px solid #13bd28;
    background-color: #fff !important;
}

.visibility_container_inner>img {
    position: absolute;
    top: 31%;
    left: 25%;
}


.visibility_right_container {
    position: absolute;
    width: 48px;
    height: 48px;
    padding: 4px;
    border-radius: 50%;
    z-index: 9;
    top: 6.5%;
    right: 24%
}

.visibility_right_container_active {
    background-color: rgba(0, 0, 0, 0.04);
}

.visibility_right_container_main {
    position: absolute;
    z-index: 10;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* left: 50%; */
}

.visibility_right_container_main_active {
    background-color: rgba(0, 184, 23, 0.2);
    animation: pulse 1s infinite;
}

.visibility_right_container_inner {
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #d8f2dc;
}

.visibility_right_container_inner_active {
    border: 1px solid #13bd28;
    background-color: #fff !important;
}

.visibility_right_container_inner>img {
    position: absolute;
    top: 31%;
    left: 25%;
}




.wind_container {
    position: absolute;
    z-index: 9;
    top: 12.5%;
    left: 35.5%;
    /* left: 1.5vw; */
    height: 48px;
    width: 48px;
    padding: 4px;
    border-radius: 50%;

}

.wind_container_active {
    background-color: rgba(0, 0, 0, 0.04);
}


.wind_container_main {
    position: absolute;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    /* left: 50%; */
}

.wind_container_main_active {
    background-color: rgba(0, 184, 23, 0.2);
    animation: pulse 1s infinite;
}

.wind_container_inner {
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #d8f2dc;
}

.wind_container_inner_active {
    border: 1px solid #13bd28;
    background-color: #fff !important;
}

.wind_container_inner>img {
    position: absolute;
    top: 25%;
    left: 25%;
    height: 12px;
    width: 12px;
}

.ent_container {
    position: absolute;
    width: 48px;
    height: 48px;
    padding: 4px;
    top: 18.5%;
    left: 35.5%;
    z-index: 9;
    border-radius: 50%;

}

.ent_container_active {
    background-color: rgba(0, 0, 0, 0.04);
}


.ent_container_main {
    position: absolute;
    z-index: 10;
    height: 38px;
    border-radius: 50%;
    width: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ent_container_main_active {
    background-color: rgba(0, 184, 23, 0.2);
    animation: pulse 1s infinite;

}

.ent_container_inner {
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #d8f2dc;
}

.ent_container_inner_active {
    border: 1px solid #13bd28;
    background-color: #fff !important;
}

.ent_container_inner>img {
    position: absolute;
    top: 25%;
    left: 25%;
    height: 12px;
    width: 12px;
}

.ear_container {
    position: absolute;
    z-index: 9;
    top: 8.5%;
    right: 7%;
    height: 48px;
    width: 48px;
    padding: 4px;
    border-radius: 50%;
}

.ear_container_active {
    background-color: rgba(0, 0, 0, 0.04);
}

.ear_container_main {
    position: absolute;
    height: 38px;
    width: 38px;
    display: flex;
    justify-content: center;
    z-index: 10;
    align-items: center;
    border-radius: 50%;
}

.ear_container_main_active {
    background-color: rgba(0, 184, 23, 0.2);
    animation: pulse 1s infinite;
}

.ear_container_inner {
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #d8f2dc;
}

.ear_container_inner_active {
    border: 1px solid #13bd28;
    background-color: #fff;
}

.ear_container_inner>img {
    position: absolute;
    top: 25%;
    left: 25%;
    height: 12px;
    width: 12px;
}

.ear_left_container_active {
    background-color: rgba(0, 0, 0, 0.04);
}

.ear_left_container_main {
    position: absolute;
    height: 38px;
    width: 38px;
    display: flex;
    justify-content: center;
    z-index: 10;
    align-items: center;
    border-radius: 50%;
}

.ear_left_container_main_active {
    background-color: rgba(0, 184, 23, 0.2);
    animation: pulse 1s infinite;
}

.ear_left_container_inner {
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #d8f2dc;
}

.ear_left_container_inner_active {
    border: 1px solid #13bd28;
    background-color: #fff;
}

.ear_left_container_inner>img {
    position: absolute;
    top: 25%;
    left: 25%;
    height: 12px;
    width: 12px;
}

.heartmonitor_container {
    position: absolute;
    width: 48px;
    height: 48px;
    padding: 4px;
    top: 28.5%;
    left: 50%;
    z-index: 9;
    border-radius: 50%;
}

.heartmonitor_container_active {
    background-color: rgba(0, 0, 0, 0.04);
}

.heartmonitor_container_main {
    position: absolute;
    display: flex;
    z-index: 10;
    width: 38px;
    height: 38px;
    padding: 4px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
}

.heartmonitor_container_main_active {
    background-color: rgba(0, 184, 23, 0.2);
    animation: pulse 1s infinite;
}

.heartmonitor_container_inner {
    position: relative;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background-color: #d8f2dc;
}

.heartmonitor_container_inner_active {
    border: 1px solid #13bd28;
    background-color: #fff;
}

.heartmonitor_container_inner>img {
    position: absolute;
}

.heartmonitor_container_inner>img:first-child {
    top: 5%;
    right: 0;
}

.heartmonitor_container_inner>img:nth-child(2) {
    height: 12px;
    width: 12px;
    top: 27%;
    left: 25%;
}


.pulmonology_container_main {
    position: absolute;
    z-index: 9;
    top: 26.5%;
    left: 43%;
    /* left: 50%; */
}

.pulmonology_container_inner {
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #d8f2dc;
}

.pulmonology_container_inner>img {
    position: absolute;
    top: 25%;
    left: 25%;
    height: 12px;
    width: 12px;
}

.gastroenterology_container {
    position: absolute;
    width: 48px;
    height: 48px;
    padding: 4px;
    top: 41%;
    left: 37%;
    z-index: 10;
    border-radius: 50%;
}

.gastroenterology_container_active {
    background-color: rgba(0, 0, 0, 0.04);
}


.gastroenterology_container_main {
    position: absolute;
    z-index: 9;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.gastroenterology_container_main_active {
    background-color: rgba(0, 184, 23, 0.2) !important;
    animation: pulse 1s infinite;
}

.gastroenterology_container_inner {
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #d8f2dc;
}

.gastroenterology_container_inner_active {
    background-color: #fff !important;
    border: solid 0.5px #13bd28;
}

.gastroenterology_container_inner>img {
    position: absolute;
    top: 25%;
    left: 25%;
    height: 12px;
    width: 12px;
}

.fingerprint_container {
    position: absolute;
    padding: 4px;
    height: 48px;
    width: 48px;
    z-index: 9;
    top: 65%;
    left: 20%;
    border-radius: 50%;
}

.fingerprint_container_second {
    position: absolute;
    padding: 4px;
    height: 48px;
    width: 48px;
    z-index: 9;
    top: 40%;
    right: -55%;
    border-radius: 50%;
}

.fingerprint_container_active {
    background-color: rgba(0, 0, 0, 0.04);
}

.fingerprint_container_main {
    position: absolute;
    z-index: 10;
    border-radius: 50%;
    display: flex;
    height: 38px;
    width: 38px;
    justify-content: center;
    align-items: center;
    /* left: 50%; */
}

.fingerprint_container_main_active {
    background-color: rgb(0, 184, 23, 0.2);
    animation: pulse 1s infinite;
}

.fingerprint_container_inner {
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: rgb(0, 184, 23, 0.2);
}

.fingerprint_container_inner_active {
    background-color: #fff !important;
    border: solid 0.5px #13bd28;
}

.fingerprint_container_inner>img {
    position: absolute;
    top: 25%;
    left: 25%;
    height: 12px;
    width: 12px;
}

.heartrate_estimation {
    margin: 24px 0 0 0;
    /* border: 1px solid red; */
}

.heartrate_estimation>div:first-child {
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: bold;
    color: #000;
    color: #000;
}


.lungs_container_main {
    position: absolute;
    top: 28%;
    left: 17%;
    z-index: 10;
}

.lungs_container {
    position: relative;
    height: 95px;
    width: 95px;
}

.lungs_container>img {
    position: absolute;
}

.lungs_container>img:first-child {
    top: 10%;
    left: 40%;
}

.lungs_container>img:nth-child(2) {
    top: 10%;
    left: 60%;
}

.lungs_container>img:nth-child(3) {
    top: 40%;
    left: 30%;
}

.lungs_container>img:nth-child(4) {
    top: 40%;
    left: 70%;
}

.lungs_container>img:nth-child(5) {
    top: 60%;
    left: 80%;
}

.lungs_container>img:nth-child(6) {
    top: 60%;
    left: 20%;
}

.slides_player_container {
    display: flex;
}

.ear_left_container {
    position: absolute;
    z-index: 9;
    top: 8.5%;
    left: 8%;
    height: 48px;
    width: 48px;
    padding: 4px;
    border-radius: 50%;
    transform: scaleX(-1);

}

.ear_left_container_active {
    background-color: rgba(0, 0, 0, 0.04);
}

.ear_left_container_main {
    position: absolute;
    height: 38px;
    width: 38px;
    display: flex;
    justify-content: center;
    z-index: 10;
    align-items: center;
    border-radius: 50%;
}

.ear_left_container_main_active {
    background-color: rgba(0, 184, 23, 0.2);
    animation: pulse 1s infinite;
}

.ear_left_container_inner {
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #D8F2DC;
}

.ear_left_container_inner_active {
    border: 1px solid #13BD28;
    background-color: #fff;
}

.ear_left_container_inner>img {
    position: absolute;
    top: 25%;
    left: 25%;
    height: 12px;
    width: 12px;
}


.visibility_right_container {
    position: absolute;
    width: 48px;
    height: 48px;
    padding: 4px;
    border-radius: 50%;
    z-index: 9;
    top: 6.3%;
    right: 23%
}

.visibility_right_container_active {
    background-color: rgba(0, 0, 0, 0.04);
}

.visibility_right_container_main {
    position: absolute;
    z-index: 10;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* left: 50%; */
}

.visibility_right_container_main_active {
    background-color: rgba(0, 184, 23, 0.2);
    animation: pulse 1s infinite;
}

.visibility_right_container_inner {
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #D8F2DC;
}

.visibility_right_container_inner_active {
    border: 1px solid #13BD28;
    background-color: #fff !important;
}

.visibility_right_container_inner>img {
    position: absolute;
    top: 31%;
    left: 25%;
}


.validatedCheck {
    position: absolute;
    z-index: 2;
    right: 10px;
    top: 40%;
}

.masimoCon {
    display: flex;
    align-items: center;
    gap: 0px;
}


.masimoPicture {
    width: 30%;
    object-fit: cover;
}

.uploadImg {
    margin-top: 24px;
    color: white;
    background-color: #1740A1;
    padding: 8px 15px;
    width: fit-content;
    border-radius: 3px;
    cursor: pointer;
}

.postCaptureBtn {
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    color: #3B3CEA;
}


.preCaptureBtn {
    cursor: pointer;
    background-color: #1740A1;
    color: white;
    padding: 8px 10px;
    width: fit-content;
    border-radius: 3px;

}

.masimoValues {
    width: 40%;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}




@-webkit-keyframes pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(0, 184, 23, 0.3);
    }

    70% {
        -webkit-box-shadow: 0 0 0 18px rgba(204, 169, 44, 0);
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}

@keyframes pulse {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba(0, 184, 23, 0.3);
        box-shadow: 0 0 0 0 rgba(0, 184, 23, 0.3);
    }

    70% {
        -moz-box-shadow: 0 0 0 18px rgba(204, 169, 44, 0);
        box-shadow: 0 0 0 18px rgba(204, 169, 44, 0);
    }

    100% {
        -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
        box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}

/* -----------------------------pulse red------------------------------------ */
@-webkit-keyframes pulseRed {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(248, 13, 36, 0.3);
    }

    70% {
        -webkit-box-shadow: 0 0 0 20px rgba(204, 169, 44, 0);
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}

@keyframes pulseRed {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba(248, 13, 36, 0.3);
        box-shadow: 0 0 0 0 rgba(248, 13, 36, 0.3);
    }

    70% {
        -moz-box-shadow: 0 0 0 20px rgba(204, 169, 44, 0);
        box-shadow: 0 0 0 20px rgba(204, 169, 44, 0);
    }

    100% {
        -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
        box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}

@keyframes scanning {
    50% {
        transform: translateY(260px);
    }

}