.terms {
  padding: 5vh 2vw;
}
.terms_of_service {
  font-family: Montserrat;
  font-size: 2em;
  font-weight: 600;
  line-height: 1.2;
  color: #000;
}
.last_update {
  font-family: Montserrat;
  font-size: 10px;
  line-height: 1.4;
  color: #000;
  padding-top: 1vh;
}
.contents{
    padding-top: 4vh;
}
.items{
    padding: 1vh 0;
}
.label{
    padding: 1vh 0;
    font-size: 1.2em;
  font-weight: 600;
  line-height: 1.2;
  color: #000;
}
.content{
    padding: 1vh 0;
}