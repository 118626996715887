* {
    font-family: Montserrat;
}

.main_div {
    width: 100%;
}

.header {
    display: flex;
    align-items: center;
}

.header_text {
    font-weight: 500;
    color: #000;
}

.back {
    font-weight: 500;
    color: #9d9d9d;
}

.leftBodyFit{
    position: sticky;
    top: 35px;
  }

.container {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    border-top-right-radius: 16px;
    box-shadow: 0 1px 50px 0 rgba(0, 0, 0, 0.08);
    border: solid 1px #e8e7e7;
    border-top: 0px;
    background-color: #fff;
    /* padding: 3vh 2vw; */
    height: calc(100% - 6vh)
}

.dentalHigoCon {
    width: 100%;
    display: flex;
    border-bottom: 1px solid #E8E7E7;
    padding-bottom: 24px;
    gap: 100px;
    align-items: center;

}


.heart_poll_container {
    margin: 32px 0 0 0;
    display: flex;
    flex-direction: column;
}

.heart_poll_container>:nth-child(1) {
    font-weight: 500;
    font-size: 16px;
    line-height: 14px;
}

.heart_poll_container>:nth-child(2) {
    font-weight: 400;
    font-size: 10px;
    line-height: 14px;
    color: #66707E;
    margin-top: 12px;
    margin-bottom: 8px;
}



.heart_poll_container>:nth-child(3) {
    display: flex;
    gap: 24px;
}

.heart_poll_container_button_inactive {
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    width: 100px;
    height: 56px;
    text-align: center;
    border: none;
    border-radius: 4px;
    border: solid 1px #ebebeb;
    background-color: #f5f5ff;
    color: #000;
}

.heart_poll_container_button_active {
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    width: 100px;
    height: 56px;
    text-align: center;
    border: none;
    border-radius: 4px;
    border: solid 1px #ebebeb;
    background-color: #1740A1 !important;
    color: #fff !important;
}


.button_with_border {
    font-size: 0.9em;
    line-height: 1.25;
    width: 150px;
    text-align: center;
    color: #1740a1;
    padding: 0.7rem 0.2rem;
    border-radius: 8px;
    border: solid 1px #1740a1;
    background-color: #fff;
    cursor: pointer;
}

.button_filled {
    border: none;
    width: 150px;
    font-size: 0.9em;
    border-radius: 8px;
    background-color: #1740A1;
    line-height: 1.25;
    padding: 0.7rem 0.2rem;
    text-align: center;
    color: #fff;
}

.button_filled:disabled {
    background-color: #ADADAD !important;
    color: #fff;
}

.assign_validation_progress_btn{
    border: none;
    background : none;
    border-radius: 8px;
    width: 160px;
    height: 44px;
    padding: 10px 0 10px 4px;
    background-color: #1740A1;
    color: #FFFFFF;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
}


.sendButton {
    background-color: #1740a1;
    color: white;
    border: none;
    padding: 6px 15px;
    border-radius: 5px;
    font-size: 14px;
}

.CancelButton {
    background-color: #ffffff;
    color: #1740a1;
    border: 1px solid #1740a1;
    padding: 6px 15px;
    border-radius: 5px;
    font-size: 14px;
    margin-right: 20px;
}

.studentDetails {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    padding: 16px;
    border-radius: 16px;
    margin-top: 3vh;
}

.avtarSection {
    display: flex;
    align-items: center;
    gap: 12px;
}

.studentDetails>:nth-child(2) {
    display: flex;
    justify-content: space-around;
    gap: 50px;
    margin: 0 40px;
    height: fit-content;
}

.studentDetails>:nth-child(2)>div>:first-child {
    color: #6B6C6F;
    font-size: 12px;
    margin-bottom: 8px;
}

.studentDetails>:nth-child(2)>div>:first-child+p,
.validatedByText {
    line-height: 18px;
    font-size: 14px;
    gap: 2px;
}

.studentDetails>:nth-child(2)>:last-child>:nth-child(2) {
    display: flex;
}

.student_details_information {
    /* border: 1px solid red; */
}

.student_details_information_name {
    /* border: 1px solid red; */
    font-size: 18px;
    font-weight: bold;
    color: #343434;
}

.student_details_information_general_info {
    margin: 11px 0 0 0;
    display: flex;
    /* border: 1px solid red; */
    justify-content: space-between;
    gap: 7px;
}

.student_details_information_general_info>p:nth-child(odd) {
    color: #343434;
    font-size: 14px;
}

.student_details_information_general_info>p:nth-child(even) {
    /* border: 1px solid red; */
    height: 2px;
    width: 2px;
    color: #9d9d9d;
    border-radius: 50%;

}

.validatedBy {
    display: flex;
}

.download_report_btn {
    display: flex;
    height: 32px;
    gap: 16px;
}


.download_report_btn>button {
    display: flex;
    border: none;
    background-color: #303030;
    ;
    color: #fff;
    border-radius: 5vh;
    font-size: 12px;
    padding: 8px;
    cursor: pointer;
    justify-content: space-between;
}

.download_report_btn>button>span {
    font-size: 12px;
    color: #fff;
    margin: 0 0 0 0.5em;
}

.screening_container {
    display: flex;
    margin-top: 20px
}

.screening_container>div {
    cursor: pointer;
}

.screening_container>:nth-child(1) {
    border: 2px solid #E8E7E7;
    border-bottom: 0px;
    border-top-left-radius: 16px;
}

.screening_container>:nth-child(2) {
    border: 2px solid #E8E7E7;
    border-bottom: 0px;
}

.screening_container>:nth-child(3) {
    border: 2px solid #E8E7E7;
    border-bottom: 0px;
    border-top-right-radius: 16px;
}

.screening_container_active {
    background-color: white;
    padding: 12px 24px;
    font-size: 14px;
    font-weight: bold;
}

.typeText {
    color: #B2B7BE;
}

.screening_container_inactive {
    padding: 12px 24px;
    font-size: 14px;
    font-weight: bold;
}

.screening_active_bar {
    margin: 1px 0 0 0;
    width: 2.8vw;
    border-bottom: 2px solid #343434;

}

.fullbody_physical_report_container {
    display: flex;
}

.fullbody_physical_report_container> :nth-child(1) {
    border-right: 2px solid #E8E7E7;
    ;
}

.fullbody_physical_report_container> :nth-child(2) {
    width: 100%;
}

.physical_report_header {
    display: flex;
}

.physicalRightCon {
    display: flex;
    flex-direction: column;
}


.physical_report_header>div {
    width: 200px;
    font-size: 12px;
    color: #6b6c6f;
}

.physical_report_header_data {
    display: flex;
    margin: 4px 0 0 0;
    /* border: 1px solid red; */
}

.physical_report_header_data>div {
    width: 200px;
    font-size: 14px;
    color: #000;

}

.exam_data_header {
    /* margin: 32px 0 0 0; */
    font-size: 18px;
    font-weight: bold;
    color: #343434;
    padding-left: 24px;
}

.exam_data_info {
    margin: 32px 0 0 0;
    display: flex;
    gap: 31px;
    justify-content: space-evenly;
}

.exam_data_info>div {
    /* border: 1px solid red; */
    text-align: center;
}

.exam_data_info>div>:nth-child(3) {
    display: flex;
    gap: 4px;
    align-items: center;
}


.infoHoverValidated {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &:hover {
        background: rgba(#f6f6f6, .3);

        &>.popOnHoverValidated {
            opacity: 1;
            width: 400px;
            height: 235px;
        }
    }
}


.infoHover {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &:hover {
        background: rgba(#f6f6f6, .3);

        &>.popOnHover {
            opacity: 1;
            width: 230px;
            height: 125px;
        }
    }
}

.popOnHoverValidated {
    white-space: nowrap;
    overflow: hidden;
    opacity: 0;
    width: 0px;
    height: 0px;
    position: absolute;
    z-index: 10;
    top: 1px;
    right: 1px;
    box-shadow: 0px 12px 34px rgba(0, 0, 0, 0.12317);
    background-color: white;
    padding: 16px;
    border-radius: 16px;
    text-align: start;
    transition: all .4s ease;
}

.popOnHoverValidated>:nth-child(1) {
    font-weight: 600;
    font-size: 14px;
    color: #66707E;
    line-height: 20px;
}

.popOnHoverValidated>:nth-child(2) {
    margin-top: 4px;
    white-space: normal;
    font-weight: 400;
    font-size: 12px;
    color: #B2B7BE;
    line-height: 16px;
}

.popOnHoverValidated>:nth-child(3)>div {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
}


.popOnHoverValidated>:nth-child(3)>div>div>:first-child {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #B2B7BE;
}


.popOnHoverValidated>:nth-child(3)>div>div>:first-child+p {
    font-weight: 600;
    font-size: 13px;
    line-height: 15px;
    margin-top: 6px;
}




.popOnHover {
    white-space: nowrap;
    overflow: hidden;
    opacity: 0;
    width: 0px;
    height: 0px;
    position: absolute;
    z-index: 10;
    top: 15px;
    right: 5px;
    box-shadow: 0px 12px 34px rgba(0, 0, 0, 0.12317);
    background-color: white;
    padding: 16px;
    border-radius: 16px;
    text-align: start;
    transition: all .4s ease;
}

.popOnHover>:nth-child(1) {
    font-size: 14px;
    font-weight: 600;
    color: #66707E;
    line-height: 20px;
}

.popOnHover>:nth-child(2) {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #B2B7BE;
    margin-top: 4px;
}

.popOnHover>:nth-child(3) {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #B2B7BE;
    margin-top: 16px;
}

.popOnHover>:nth-child(4) {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #001028;
    margin-top: 6px;
}



.exam_data_low_hemoglobin {
    color: #e56c00 !important;
}

.exam_data_info>div>img {
    height: 32px;
    width: 32px;
    object-fit: contain;
    /* border: 1px solid red; */
}

.exam_data_info>div>p:nth-child(2) {
    margin: 16px 0 0 0;
    font-size: 14px;
    font-weight: bold;
    color: #008c1e;
}

/* .exam_data_info > div > p:nth-child(2) > sup {
      margin: 0 !important;
      font-size: 14px;
      font-weight: bold;
      color: #008c1e;
  } */
.exam_data_info>div>p:nth-child(3) {
    margin: 5px 0 0 0;
    color: #676767;
}

.ausculation_heading {
    font-size: 14px;
    margin: 18px 0px;
    font-weight: 600;
    color: #66707E;
}

.audioPlayerWrapper {
    width: 50%;
    margin-bottom: 10px;
}



.organs_container {
    width: 100%;
    display: flex;
    margin: 29px 0 0 0;
    border-bottom: 2px solid #F5F6F7;
    /* border-radius: 8px; */
    padding: 4px 4px 0px 4px;
    gap: 24px;
}

.organs_container>:first-child {
    margin-left: 20px;
}


.organs_container>div {
    cursor: pointer;
    padding-top: 40px;
    color: #B2B7BE;
    font-size: 14px;
    padding: 6px 2px;
}

.organs_container_elements_active {
    font-weight: 700;
    color: black !important;
    border-bottom: 3px solid black;
}

.dentalConMain {
    display: flex;
    padding: 30px;
}

.dentalTitle {
    padding-left: 24px;
}

.dentalConMain>:nth-child(1) {
    display: flex;

}

.dentalConMain>:nth-child(2) {
    display: flex;
    width: 100%;

    align-items: center;
    justify-content: center;
}

.dentalConMain>:nth-child(2)>div {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.dentalConMain>:nth-child(2)>div>div {
    display: flex;
    align-items: center;
    gap: 8px;
    font-weight: 600;
}

.decayed {
    width: 15px;
    height: 15px;
    background-color: #9A6031;
    border-radius: 100%;
}

.missing {
    width: 15px;
    height: 15px;
    background-color: #D95A59;
    border-radius: 100%;
}

.filled {
    width: 15px;
    height: 15px;
    background-color: #5FC5FF;
    border-radius: 100%;
}

.dentalConMain>:nth-child(1)>:nth-child(1) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.dentalConMain>:nth-child(1)>:nth-child(3) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.reviewsNormal {
    background-color: #62A615;
    max-width: 80px;
    text-align: center;
    color: white;
    border-radius: 50px;
    line-height: 12px;
    padding: 10px 8px;
    font-size: 10px;
}

.reviewsNeedAttention {
    background-color: #EEA61F;
    max-width: 80px;
    text-align: center;
    color: white;
    border-radius: 50px;
    line-height: 12px;
    padding: 4px 8px;
    font-size: 10px;
}


.fullbody_physical_outer_container {
    min-height: 630px;
    display: flex;
    padding: 30px;
    justify-content: center;
}

.fullbody_physical_image_container {
    position: sticky;
    top: 85px;
    width: 177px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-height: 570px;
}

.fullbody_physical_image_container>div {
    font-size: 12px;
    text-align: center;

}

.fullbody_physical_image_container>img {
    position: absolute;
    z-index: 2;
}

.fullbody_physical_line_container {
    position: relative;
    margin-top: 30px;
    height: fit-content;
    padding: 0 0 30px 0;
    box-sizing: border-box;
    margin: 0 20px;
}

.fullbody_physical_line_container>span {
    position: absolute;
    background-color: white;
    bottom: -3px;
    left: -3px;
    width: 30px;
    height: 35px;
    z-index: 5;
}

.fullbody_physical_line_container>span:nth-child(2) {
    position: absolute;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    top: 43%;
    left: -35px;
}

.fullbody_physical_line_container>span:nth-child(3) {
    position: absolute;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    bottom: -37px;
    left: 45%;
    background-color: transparent;

}



/* .fullbody_physical_height_container{
    border: 1px solid black;
    display: flex;
  } */

/* .fullbody_physical_image_inner_container{
      position: relative;
      border: 1px solid red;
      margin: 0 auto;
  } */

/* .fullbody_physical_image_inner_container > img {
      position: absolute;
      top: 0;
      left: 3.1%;
      border-left: 1px solid #9d9d9d;
      padding: 0 0 0 2vw;
  }  */
/* .weight_bar{
    margin : 24px 0 0 0;
    border-top: 1px solid #9d9d9d;
    width: 164px;
    text-align: center;
    padding: 7px 0 0 0;
    font-size: 12px;
  }
  .height_bar{
    position: absolute;
    font-size: 12px;
    left: -15%;
    top: 47%;
  } */

.fullbody_green_zone {
    margin: 0 auto;
    /* position: relative; */
    background-color: rgba(41, 155, 8, 0.08);
    border: 2px dashed rgb(41, 155, 8);
    border-top: 0px;
    border-bottom: 0px;
    background-color: #EEF7EC;
    /* width: 7.361vw; */
    width: 108px;
    height: 546px;
}

.fullbody_green_zone_obesese {
    margin: 0 auto;
    /* position: relative; */
    background-color: rgba(41, 155, 8, 0.08);
    border: 2px dashed rgb(41, 155, 8);
    border-top: 0px;
    border-bottom: 0px;
    background-color: #EEF7EC;
    /* width: 7.361vw; */
    width: 99px;
    height: 546px;
}

.fullbody_green_zone_skinny {
    margin: 0 auto;
    /* position: relative; */
    background-color: rgba(41, 155, 8, 0.08);
    border: 2px dashed rgb(41, 155, 8);
    border-top: 0px;
    border-bottom: 0px;
    background-color: #EEF7EC;
    /* width: 7.361vw; */
    width: 123px;
    height: 546px;
}

.obsese_outer_line_left {
    position: absolute;
    z-index: 1;
    width: 8px;
    background-color: rgba(248, 13, 56, 0.2);
    border-left: 2px dashed red;
    height: 96%;
    left: 18%;
}

.obsese_outer_line_right {
    position: absolute;
    z-index: 1;
    width: 8px;
    background-color: rgba(248, 13, 56, 0.2);
    border-right: 2px dashed red;
    height: 96%;
    right: 17.7%;
}

.skinny_outer_line_left {
    position: absolute;
    z-index: 1;
    width: 8px;
    background-color: rgba(248, 13, 56, 0.2);
    border-right: 2px dashed red;
    height: 96%;
    left: 16.5%;
}

.skinny_outer_line_right {
    position: absolute;
    z-index: 1;
    width: 8px;
    background-color: rgba(248, 13, 56, 0.2);
    border-left: 2px dashed red;
    height: 96%;
    right: 16.5%;

}


.stethoscope_check_container {
    position: absolute;
    top: -1%;
    left: 45%;
    z-index: 9;

}

.stethoscope_check {
    position: relative;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    border: 1px solid #13bd28;
    background-color: #fff;
}

.stethoscope_check>img {
    position: absolute;
}

.stethoscope_check>img:first-child {
    top: 5%;
    right: 0;
}

.stethoscope_check>img:nth-child(2) {
    height: 12px;
    width: 12px;
    top: 27%;
    left: 25%;
}

.visibility_container {
    /* position: absolute;
    width: 64px;
    height: 64px;
    padding: 4px;
    border-radius: 50%;
    z-index: 9;
    top: 1.5%; */
    /* left: 0% */
}

.visibility_container_active {
    background-color: rgba(0, 0, 0, 0.04);
}

.visibility_container_main {
    position: absolute;
    z-index: 10;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 25%;
    top: 4.2%;
}

.visibility_container_main_active {
    background-color: rgba(0, 184, 23, 0.2);
    animation: pulse 1s infinite;
}

.visibility_container_inner {
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #d8f2dc;
}

.visibility_container_inner_active {
    border: 1px solid #13bd28;
    background-color: #fff !important;
}

.visibility_container_inner>img {
    position: absolute;
    top: 31%;
    left: 25%;
}


.visibility_right_container {
    position: absolute;
    width: 64px;
    height: 64px;
    padding: 4px;
    border-radius: 50%;
    z-index: 9;
    top: 3.5%;
    right: 23%;
}

.visibility_right_container_active {
    background-color: rgba(0, 0, 0, 0.04);
}

.visibility_right_container_main {
    position: absolute;
    z-index: 10;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* left: 50%; */
}

.visibility_right_container_main_active {
    background-color: rgba(0, 184, 23, 0.2);
    animation: pulse 1s infinite;
}

.visibility_right_container_inner {
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #d8f2dc;
}

.visibility_right_container_inner_active {
    border: 1px solid #13bd28;
    background-color: #fff !important;
}

.visibility_right_container_inner>img {
    position: absolute;
    top: 31%;
    left: 25%;
}




.wind_container {
    position: absolute;
    z-index: 9;
    top: 9%;
    left: 32.5%;
    height: 64px;
    width: 64px;
    padding: 4px;
    border-radius: 50%;

}

.wind_container_active {
    background-color: rgba(0, 0, 0, 0.04);
}


.wind_container_main {
    position: absolute;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    /* left: 50%; */
}

.wind_container_main_active {
    background-color: rgba(0, 184, 23, 0.2);
    animation: pulse 1s infinite;
}

.wind_container_inner {
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #d8f2dc;
}

.wind_container_inner_active {
    border: 1px solid #13bd28;
    background-color: #fff !important;
}

.wind_container_inner>img {
    position: absolute;
    top: 25%;
    left: 25%;
    height: 12px;
    width: 12px;
}

.ent_container {
    position: absolute;
    width: 64px;
    height: 64px;
    padding: 4px;
    top: 14.5%;
    left: 32.5%;
    z-index: 9;
    border-radius: 50%;

}

.ent_container_active {
    background-color: rgba(0, 0, 0, 0.04);
}


.ent_container_main {
    position: absolute;
    z-index: 10;
    height: 56px;
    border-radius: 50%;
    width: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ent_container_main_active {
    background-color: rgba(0, 184, 23, 0.2);
    animation: pulse 1s infinite;

}

.ent_container_inner {
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #d8f2dc;
}

.ent_container_inner_active {
    border: 1px solid #13bd28;
    background-color: #fff !important;
}

.ent_container_inner>img {
    position: absolute;
    top: 25%;
    left: 25%;
    height: 12px;
    width: 12px;
}

.ear_container {
    position: absolute;
    z-index: 9;
    top: 5.5%;
    right: 10%;
    height: 64px;
    width: 64px;
    padding: 4px;
    border-radius: 50%;
}

.ear_container_active {
    background-color: rgba(0, 0, 0, 0.04);
}

.ear_container_main {
    position: absolute;
    height: 56px;
    width: 56px;
    display: flex;
    justify-content: center;
    z-index: 10;
    align-items: center;
    border-radius: 50%;
}

.ear_container_main_active {
    background-color: rgba(0, 184, 23, 0.2);
    animation: pulse 1s infinite;
}

.ear_container_inner {
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #d8f2dc;
}

.ear_container_inner_active {
    border: 1px solid #13bd28;
    background-color: #fff;
}

.ear_container_inner>img {
    position: absolute;
    top: 25%;
    left: 25%;
    height: 12px;
    width: 12px;
}




.ear_left_container {
    position: absolute;
    z-index: 9;
    top: 5.5%;
    left: 9%;
    height: 64px;
    transform: scaleX(-1);
    width: 64px;
    padding: 4px;
    border-radius: 50%;
}

.ear_left_container_active {
    background-color: rgba(0, 0, 0, 0.04);
}

.ear_left_container_main {
    position: absolute;
    height: 56px;
    width: 56px;
    display: flex;
    justify-content: center;
    z-index: 10;
    align-items: center;
    border-radius: 50%;
}

.ear_left_container_main_active {
    background-color: rgba(0, 184, 23, 0.2);
    animation: pulse 1s infinite;
}

.ear_left_container_inner {
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #d8f2dc;
}

.ear_left_container_inner_active {
    border: 1px solid #13bd28;
    background-color: #fff;
}

.ear_left_container_inner>img {
    position: absolute;
    top: 25%;
    left: 25%;
    height: 12px;
    width: 12px;
}

.heartmonitor_container {
    position: absolute;
    width: 64px;
    height: 64px;
    padding: 4px;
    top: 23.5%;
    left: 46%;
    z-index: 9;
    border-radius: 50%;
}

.heartmonitor_container_active {
    background-color: rgba(0, 0, 0, 0.04);
}

.heartmonitor_container_main {
    position: absolute;
    display: flex;
    z-index: 10;
    width: 56px;
    height: 56px;
    padding: 4px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
}

.heartmonitor_container_main_active {
    background-color: rgba(0, 184, 23, 0.2);
    animation: pulse 1s infinite;
}

.heartmonitor_container_inner {
    position: relative;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background-color: #d8f2dc;
}

.heartmonitor_container_inner_active {
    border: 1px solid #13bd28;
    background-color: #fff;
}

.heartmonitor_container_inner>img {
    position: absolute;
}

.heartmonitor_container_inner>img:first-child {
    top: 5%;
    right: 0;
}

.heartmonitor_container_inner>img:nth-child(2) {
    height: 12px;
    width: 12px;
    top: 27%;
    left: 25%;
}


.pulmonology_container_main {
    position: absolute;
    z-index: 9;
    top: 23.5%;
    left: 44%;
    /* left: 50%; */
}

.pulmonology_container_inner {
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #d8f2dc;
}

.pulmonology_container_inner>img {
    position: absolute;
    top: 25%;
    left: 25%;
    height: 12px;
    width: 12px;
}

.gastroenterology_container {
    position: absolute;
    width: 64px;
    height: 64px;
    padding: 4px;
    top: 35%;
    left: 34%;
    z-index: 10;
    border-radius: 50%;
}

.gastroenterology_container_active {
    background-color: rgba(0, 0, 0, 0.04);
}


.gastroenterology_container_main {
    position: absolute;
    z-index: 9;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.gastroenterology_container_main_active {
    background-color: rgba(0, 184, 23, 0.2) !important;
    animation: pulse 1s infinite;
}

.gastroenterology_container_inner {
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #d8f2dc;
}

.gastroenterology_container_inner_active {
    background-color: #fff !important;
    border: solid 0.5px #13bd28;
}

.gastroenterology_container_inner>img {
    position: absolute;
    top: 25%;
    left: 25%;
    height: 12px;
    width: 12px;
}

.fingerprint_container {
    position: absolute;
    padding: 4px;
    height: 64px;
    width: 64px;
    z-index: 9;
    top: 62%;
    left: 52%;
    border-radius: 50%;
}

.fingerprint_container_second {
    position: absolute;
    padding: 4px;
    height: 64px;
    width: 64px;
    z-index: 9;
    top: 40%;
    right: -55%;
    border-radius: 50%;
}

.fingerprint_container_active {
    background-color: rgba(0, 0, 0, 0.04);
}

.fingerprint_container_main {
    position: absolute;
    z-index: 10;
    border-radius: 50%;
    display: flex;
    height: 56px;
    width: 56px;
    justify-content: center;
    align-items: center;
    /* left: 50%; */
}

.fingerprint_container_main_active {
    background-color: rgba(248, 13, 56, 0.2);
    animation: pulseRed 1s infinite;
}

.fingerprint_container_inner {
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #fed8df;
}

.fingerprint_container_inner_active {
    background-color: #fff !important;
    border: solid 0.5px #f80d38;
}

.fingerprint_container_inner>img {
    position: absolute;
    top: 25%;
    left: 25%;
    height: 12px;
    width: 12px;
}

.belowBody {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 20px;
  }
  
  .belowBody>div>img {
    width: 27px;
  }
  
  .belowBody>div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
  }
  
  .belowBody>div>div:nth-child(2) {
    font-size: 14px;
    color: #008C1E;
    font-weight: 700;
  }
  
  .belowBody>div>div:nth-child(2)>span {
    font-size: 10px;
    color: #008C1E;
    font-weight: 700;
  }
  
  .belowBody>div>div:nth-child(3) {
    font-size: 12px;
  }

.heartrate_estimation {
    margin: 24px 24px;
    /* border: 1px solid red; */
}

.heartrate_estimation>div:first-child {
    margin-bottom: 15px;
    font-size: 14px;
    font-weight: 600;
    color: #66707E;
}

.headingBold {
    margin-top: 12px;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 9px;
}


.hrLine {
    height: 1.8px;
    background-color: #E8E7E7;

}

.estimationBlock {
    margin-top: 32px;
}


.headingWithCheck {
    display: flex;
    align-self: center;
    gap: 9px;
}

.headerContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.headingText {
    font-weight: 600;
    font-size: 14px;
    color: #66707E;
}

.knowMoreSection {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 18px;
}

.knowMoreSection>:nth-child(1) {
    font-weight: 700;
    font-size: 12px;
    color: #222CC9
}

.knowMoreSection>:nth-child(2) {
    display: flex;
    align-items: center;
}

.knowMoreSection>:nth-child(2) {
    display: flex;
    gap: 10px;
}

.knowMoreSection>:nth-child(2)>:nth-child(2) {
    font-size: 12px;
    font-weight: 700;
}

.lungs_container_main {
    position: absolute;
    top: 22%;
    left: 22%;
    z-index: 10;
}

.lungs_container {
    position: relative;
    height: 100px;
    width: 100px;
}

.lungs_container>img {
    position: absolute;
}

.lungs_container>img:first-child {
    top: 10%;
    left: 40%;
}

.lungs_container>img:nth-child(2) {
    top: 10%;
    left: 60%;
}

.lungs_container>img:nth-child(3) {
    top: 40%;
    left: 30%;
}

.lungs_container>img:nth-child(4) {
    top: 40%;
    left: 70%;
}

.lungs_container>img:nth-child(5) {
    top: 60%;
    left: 80%;
}

.lungs_container>img:nth-child(6) {
    top: 60%;
    left: 20%;
}

.slides_player_container {
    display: flex;
    flex-direction: column;
}

.slides_player_inner_container {
    display: flex;
    justify-content: space-around;
}

#captured {
    width: 200px;
    height: 200px;
    border-radius: 10px;
    overflow: hidden;
}


.ear_left_container_active {
    background-color: rgba(0, 0, 0, 0.04);
}

.ear_left_container_main {
    position: absolute;
    height: 56px;
    width: 56px;
    display: flex;
    justify-content: center;
    z-index: 10;
    align-items: center;
    border-radius: 50%;
}

.ear_left_container_main_active {
    background-color: rgba(0, 184, 23, 0.2);
    animation: pulse 1s infinite;
}

.ear_left_container_inner {
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #D8F2DC;
}

.ear_left_container_inner_active {
    border: 1px solid #13BD28;
    background-color: #fff;
}

.ear_left_container_inner>img {
    position: absolute;
    top: 25%;
    left: 25%;
    height: 12px;
    width: 12px;
}


.visibility_right_container_active {
    background-color: rgba(0, 0, 0, 0.04);
}

.visibility_right_container_main {
    position: absolute;
    z-index: 10;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* left: 50%; */
}

.visibility_right_container_main_active {
    background-color: rgba(0, 184, 23, 0.2);
    animation: pulse 1s infinite;
}

.visibility_right_container_inner {
    position: relative;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #D8F2DC;
}

.visibility_right_container_inner_active {
    border: 1px solid #13BD28;
    background-color: #fff !important;
}

.visibility_right_container_inner>img {
    position: absolute;
    top: 31%;
    left: 25%;
}


.validationButtonContainer {
    margin-top: 30px;
}

.validationButtonContainer>div {
    margin-top: 8px;
    display: flex;
    gap: 24px;
}

.validationButtonContainer>p {
    font-size: 10px;
    color: #66707E;
}


.docInterpretation {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}


.docInterpretation>:nth-child(1) {
    display: flex;
    gap: 8px;
    align-items: center;
}


.docInterpretation>:nth-child(1)>p {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #222CC9;
}

.docInterpretation>:nth-child(2) {
    display: block;
    position: absolute;
    opacity: 0;
    top: 0px;
    left: 0px;
    height: 20px;
    width: 20px;
}

.addInterpretation {
    transform: scaleX(0);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px;
    opacity: 0;
    height: 0px;
    width: 90%;
    border: 2px solid #B2B7BE;
    border-radius: 8px;
    transform-origin: top left;
    transition-duration: 0.2s;
    -webkit-transform-origin: top left;
    -webkit-transition-duration: 0.2s;
}

.addInterpretation>form>input {
    padding: 0px;
    margin-left: 5px;
    outline: none;
    border: none;
}

.docInterpretation>:nth-child(2):checked~.addInterpretation {
    opacity: 1;
    transform: scaleX(1);
    height: auto;
    margin-top: 24px;
}

.editInterpretation {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px;
    width: 90%;
    border: 2px solid #B2B7BE;
    border-radius: 8px;
}

.editInterpretation>form>input {
    padding: 0px;
    margin-left: 5px;
    outline: none;
    border: none;
}


.docEditText {
    margin-top: 12px;
    display: flex;
    gap: 5px;
    align-items: center;
}

/* ----------------------------------------------------------------- */
/* ----------------BEHAVIORAL CONTAINER SCREENING------------------- */
/* ----------------------------------------------------------------- */

.behavioralMainCon {
    display: flex;
}

.behavioralMainCon>:nth-child(1) {
    border-right: 2px solid #E8E7E7;
    padding: 20px;
}


.behavioralMainCon>:nth-child(2)>:nth-child(1) {
    font-weight: 600;
    font-size: 18px;
    line-height: 16px;
    padding: 32px 24px 0 24px
}

.behavioralMainCon>:nth-child(2)>:nth-child(3) {

    padding: 24px
}

.ClickHereBlueBlack {
    font-size: 14px;
    display: flex;
    gap: 5px;
}

.ClickHereBlueBlack>span {
    font-weight: 600;
    color: #222cc9;
}

.close {
    text-align: right;
    cursor: pointer;
}

.flexContainer {
    display: flex;
    gap: 170px
}

.issueButtonCon {
    position: relative;
}

.reExamButtonCon{
    position: relative;
    margin-top: 20px !important;
}

.issueDropDown {
    min-width: 250px;
    padding: 10px 10px 10px 20px;
    position: absolute;
    bottom: 66px;
    left: 0px;
    z-index: 2;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 1px 50px rgba(0, 0, 0, 0.08);
}

.reasonDropDown {
    min-width: 250px;
    padding: 10px 10px 10px 20px;
    position: absolute;
    bottom: 66px;
    left: 0px;
    z-index: 2;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 1px 50px rgba(0, 0, 0, 0.08);
}


.hearingImageSection{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
  }
  .hearingImageSection>div{
    display: flex;
    flex-direction: column;
    align-items: center;
  }

@-webkit-keyframes pulse {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(0, 184, 23, 0.3);
    }

    70% {
        -webkit-box-shadow: 0 0 0 20px rgba(204, 169, 44, 0);
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}

@keyframes pulse {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba(0, 184, 23, 0.3);
        box-shadow: 0 0 0 0 rgba(0, 184, 23, 0.3);
    }

    70% {
        -moz-box-shadow: 0 0 0 20px rgba(204, 169, 44, 0);
        box-shadow: 0 0 0 20px rgba(204, 169, 44, 0);
    }

    100% {
        -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
        box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}

/* -----------------------------pulse red------------------------------------ */
@-webkit-keyframes pulseRed {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(248, 13, 56, 0.3);
    }

    70% {
        -webkit-box-shadow: 0 0 0 20px rgba(204, 169, 44, 0);
    }

    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}

@keyframes pulseRed {
    0% {
        -moz-box-shadow: 0 0 0 0 rgba(248, 13, 56, 0.3);
        box-shadow: 0 0 0 0 rgba(248, 13, 56, 0.3);
    }

    70% {
        -moz-box-shadow: 0 0 0 20px rgba(204, 169, 44, 0);
        box-shadow: 0 0 0 20px rgba(204, 169, 44, 0);
    }

    100% {
        -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
        box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    }
}