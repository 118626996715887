*{
    font-family: "Inter", sans-serif;
}
.searchbar_container
{
width: 19.444vw;
position: relative;
border: 1px solid #e8e7e7;
border-radius: 8px;
padding: 5px 0 8px 1.111vw;
}
.searchbar_container > img{
margin: 0 0.8em 0 0;
position: absolute;
top: 25%;
}
.searchbar_container > input{
background: none;
border: none;
height: 16px;
width: 13.611vw;
}

.searchbar_container > input::placeholder
{
color: #b1b1b1;
font-size: 12px;
}
.searchbar_container > input:focus{
outline: none !important;
}
/* .MuiInputBase-root-MuiOutlinedInput-root{
    height: 32px !important;
}

.MuiInputBase-input-MuiOutlinedInput-input{
    padding: 0 !important;
    height: 32px !important;
}

.MuiInputBase-input .MuiOutlinedInput-input .MuiInputBase-inputSizeSmall .MuiInputBase-inputAdornedStart .MuiInputBase-input-MuiOutlinedInput-input{
    height: 32px !important;
    padding: 0 !important;
}
.MuiInputBase-root .MuiOutlinedInput-root .MuiInputBase-colorPrimary .MuiInputBase-formControl .MuiInputBase-sizeSmall .MuiInputBase-adornedStart .MuiInputBase-root-MuiOutlinedInput-root{
    height: 32px !important;
    padding: 0 !important;
} */