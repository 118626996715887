@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
  color: #001028;
}

  .studentRow_container{
    display: grid;
    border-radius: 8px;
    padding: 10px 0 10px 16px;
    border: 1px solid #e7e7e7;
    background-color: #fff;
    gap: 20px;
    /* grid-template-columns: 0.6fr 0.24fr 0.3fr 0.4fr 0.3fr 0.4fr 0.28fr 0.2fr; */
    grid-template-columns: 1fr 0.3fr 0.4fr 0.8fr 0.6fr 0.5fr 0.5fr;
  }
  
  .studentRow_container > div{
    /* border: 1px solid red; */
    font-size: 12px;
  }
  
  
  
  /* Name and Avatar Styles */
  .studentRow_container > div:first-child{
    display: flex;
    font-weight: bold;
    word-wrap: break-word;
  }
  
  
  .studentRow_container > div:first-child > div:nth-child(2)
  {
    padding: 5px 0 0 16px;
  }
  
  .studentRow_container > div:first-child > div:nth-child(2) > p{
    font-size: 12px;
    font-weight: 600;
  }
  
  
  /* Age Styles */
  
  .studentRow_container > div:nth-child(2) {
    padding: 5px 0;
    text-align: left;
    font-size: 12px;
  }  
  
  /* Gender Styles */
  .studentRow_container > div:nth-child(3) {
    padding: 5px 0;
    text-align: left;
    font-size: 12px;
  } 
  
  .studentRow_container > div:nth-child(4){
    padding: 5px 0;
    text-align: left;
    font-size: 12px;
    /* border: 1px solid red; */

  word-wrap: break-word;
}

.studentRow_container > div:nth-child(4) > p {
  /* color: #3b3cea !important; */
}

/* Screening Styles And Teacher Style */
.studentRow_container>div:nth-child(5) {
  padding: 5px 0;
  text-align: left;
  font-size: 12px;

}

/* Conditional Report Button */
.studentRow_container > div:nth-child(6) {
  padding: 5px 0;
  text-align: left;
}

.validated_btn {
  padding: 5px 0;
  width: 72px;
  border: none;
  background: none;
  background-color: #96c717;
  font-size: 8px;
  border-radius: 5vh;
  color: #fff;
  /* cursor: pointer; */
  display: flex;
  justify-content: center;
}

.pending_btn {
  padding: 5px 0;
  width: 72px;
  border: none;
  background: none;
  background-color: #db1c1c !important;
  font-size: 8px;
  border-radius: 5vh;
  font-weight: 600;
  color: #fff;
  /* cursor: pointer; */
  display: flex;
  justify-content: center;
}
.pending_text{
    color: #db1c1c !important;
}

.screened_btn {
  padding: 5px 0;
  width: 72px;
  border: 1px solid #3b3cea;
  background: #3b3cea;
  font-size: 8px;
  color: #fff !important;
  font-weight: 600;
  border-radius: 5vh;
  text-align: center;

}

/* Validated Styling */

.studentRow_container>div:nth-child(7) {
  padding: 5px 0;
  text-align: left;
  font-size: 12px;
}

.studentRow_container > div:nth-child(7) > button{
    border: none;
    background: none;
    color: #3b3cea;
    text-decoration: underline;
    cursor: pointer;
}
.validated_status{
    border: none;
    background: none;
    color: #66707e;
}

/* Responsive Media Query */