@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

.common_layout {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height: 100vh;
  /* width: 100vw; */
}

.common_layout_left {
  position: relative;
  background-color: #fdf8f4;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
}

.common_layout_left_skyblue_circle {
  position: absolute;
  top: 84px;
  left: 224px;
}

.common_layout_left_yellow_circle {
  position: absolute;
  top: 78px;
  left: 126px;
}

.common_layout_left_violet_circle {
  position: absolute;
  top: 16%;
  left: -1%;
}

.common_layout_left_green_circle {
  position: absolute;
  bottom: -1%;
  left: 43.111vw;
}

.common_layout_left_text {
  position: absolute;
  z-index: 1;
  top: 32%;
  left: 5%;
}

.common_layout_left_text>p:first-child {
  font-family: ArgentCFThin;
  font-size: 72px;
  font-weight: 250;
  /* border: 1px soild red; */
  /* font-family: Montserrat; */
}

.common_layout_left_text>p:nth-child(2) {
  font-size: 72px;
  font-family: ArgentCF;
  font-weight: 700;
}

.common_layout_left_subtext {
  position: absolute;
  z-index: 1;
  top: 60%;
  left: 5%;
}

.common_layout_left_subtext>p {
  font-size: 20px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  line-height: 28px;
}

.common_layout_subhead {
  line-height: 1.5;
  font-size: 14px;
  padding: 1.6vh 0 0 0;
  font-weight: 600;
  font-family: 'Inter', sans-serif;
  color: #66707E;
}

.common_layout_right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff !important;
  gap: 40px;
  padding: 4vh 0 0.5vh 0;
}

.common_layout_right>div:first-child {
  text-align: center;
}

.common_layout_content {
  display: flex;
  flex-direction: column;
  padding: 0 10vw;
  width: -webkit-fill-available;
}

.common_layout_content_centre {
  padding: 0 0 0 15vw;
}

.skids_logo {
  /* width: 9vw; */
  width: 122.00148010253906px;
  height: 88.00025939941406px;

}

.common_layout_header {
  font-family: ArgentCFLight;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left !important;
  color: #001028;
  font-weight: 400;
  font-size: 32px;
}

.common_layout_banner {
  color: white;
  font-family: Montserrat;
  font-size: 30px;
  font-weight: 600;
  line-height: 1.2;
}

.common_layout_subbanner {
  color: white;
  font-family: Montserrat;
  font-size: 16px;
  padding-top: 2vh;
  padding-bottom: 4vh;
}

.common_layout_progress_bar {
  display: block;
  width: 32px;
  height: 8px;
  margin: 0 4px 0 0;
  transform: rotate(-180deg);
  border-radius: 4px;
  background-color: #fff;
}

.common_layout_layers {
  padding: 2vh 0;
  display: flex;
}

.common_layout_pending_bar {
  width: 12px;
  height: 8px;
  margin: 0 2px;
  transform: rotate(-180deg);
  border-radius: 4px;
  background-color: #fff;
}

.common_layout_policies {
  margin-top: auto;
  padding: 2vh 2vw;
  font-family: Montserrat;
  font-size: 12px;
  line-height: 1.33;
  color: #6b6c6f;
  display: flex;
  flex-direction: row;
  width: -webkit-fill-available;
  justify-content: space-between;
}

.common_layout_policies>div:first-child>a {
  color: #1740A1 !important;
  font-weight: 400 !important;
}

.common_layout_text_right {
  text-align: right;
}

.common_layout_right_header {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 0 0 0 2vw;
}

.common_layout_backbtn_container {
  display: flex;
  /* align-items: center; */
  gap: 5px;
  padding: 2.4vh 0 0 0;
}

.common_layout_backbtn_container>p {
  padding: 16px 0 0 0;
  font-size: 12px;
  color: #001028;
}

.common_layout_right_header_conditional {
  flex-direction: row-reverse !important;
}

.copyright_text {
  font-family: 'Inter', sans-serif;
  color: #66707E;
}