  #chart-area > .bar-placeholder {
    fill: #fff;
  }
  
  #chart-area > text {
    text-anchor: middle;
    fill: #333;
    font-size: 12px;
  }
  
  #chart-area > g.layer ~ g.layer .bar-placeholder {
    display: none;
  }
  
  .y-axis > .tick > text {
    transform: translate(-7px,0px);
  }

  .x-axis > .tick > text {
    /* transform: rotate(270deg) translate(-40px,-12px); */
    /* transform: translate(0px,40px); */
  }