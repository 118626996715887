@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

* {
  font-family: "Inter", sans-serif;
}

.otp_page {
  font-family: "Inter", sans-serif;
  padding: 4vh 0;
}

.submit_button {
  background-color: #222cc9;
  border-radius: 8px;
  border: none;
  height: 6vh;
  width: -webkit-fill-available;
  color: #fff;
  text-align: center;
  line-height: 1.25;
  font-family: Montserrat;
  font-size: 0.9em;
  font-weight: 600;
  margin: 2vh 0;
  cursor: pointer;
}

.otp_input {
  width: 3.5vw;
  height: 6.5vh;
  border: 1px solid #e7e8e8;
  background-color: #ffffff;
  border-radius: 8px;
  font-size: 1.5rem;
  text-align: center;
}

::placeholder {
  color: #c6c6c6;
  opacity: 1;
  text-align: center;
}

.otp_container {
  /* display: flex; */
  gap: 24px;
  width: 100%;
}

.resend {
  display: flex;
  justify-content: space-between;
  padding: 1.4vh 0;
  align-items: center;
}

.bottom_text {
  font-size: 12px;
  line-height: 1.33;
  color: #6b6c6f;
}

.resend_send_again {
  display: flex;
  font-size: 12px;
  font-weight: 600;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.timer {
  padding-right: 0.3vw;
  color: #222cc9;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

.title {
  max-width: 400px;
  margin: auto;
  text-align: center;
  font-family: "Poppins", sans-serif;

  h3 {
    font-weight: bold;
  }

  p {
    font-size: 12px;
    color: #118a44;

    &.msg {
      color: initial;
      text-align: initial;
      font-weight: bold;
    }
  }
}

.otp-input-fields {
  margin: auto;
  /* max-width: 400px; */
  width: auto;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  /* padding: 0 40px; */
  width: 100%;
}

.otp-input-fields input {
  height: 55px;
  width: 55px;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid;
  outline: none;
  font-size: 18px;
  padding: 5px;
}

.result {
  max-width: 400px;
  margin: auto;
  padding: 24px;
  text-align: center;
  display: none;
}

.result p {
  font-size: 24px;
  font-family: 'Antonio', sans-serif;
  opacity: 1;
  transition: color 0.5s ease;
  /* &._ok{
    color: green;
  }
  &._notok{
    color: red;
    border-radius: 3px;
  } */
}

.password_parameters {
  display: flex;
  margin: 24px 0 0 0;
}

.password_parameters_containers {
  /* display: flex;
  flex-direction: column; */
  display: grid;
  grid-template-columns: 1fr;
  gap: 12px;
  width: 50%;
  text-align: left;
}

.password_parameters_boxes {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 12px;
}

.password_parameters_boxes>div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.password_parameters_boxes>p {
  color: #B2B7BE;
}

.password_parameters_boxes_checked>p {
  color: #149547;
}

.password_parameters_boxes_wrong>p {
  color: #D95A59;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}