@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
*{
    font-family: "Inter", sans-serif;
}
body{
    background: #FAF8F7;
}
.infoCard_container{
    display: flex;
    gap : 24px;
}

.analytics_text{
    margin: 32px 0 0 0;
    font-size: 16px;
    color: #001028;
    font-weight: 600;
}
.analytics_cards_container{
    margin: 16px 0 0 0;
    display: grid;
    grid-template-columns: repeat(4, 18.542vw);
    gap: 24px;
}
.analytics_cards_main{
    position: relative;
}
.analytics_cards_container_inner{
    border-radius: 16px;
    background: #FFFFFF;
    border: 1px solid #E8E7E7;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.08);
    padding: 24px 7px 24px 24px; 
}



.analytics_cards_container_inner_header{
    font-size: 16px;
    font-weight: 600;
    color: #66707E;
}
.analytics_cards_container_logo_number_container{
    margin: 24px 0 0 0;
    display: flex;
    gap: 12px;

}
.analytics_cards_container_logo_number_container_text{
    font-size: 28px;
    font-weight: 400;
}
.analytics_cards_container_logo_number_container_text > span{
    color: #66707E;
    font-size: 16px !important;
}

.analytics_cards_container_logo_number_container_countText{
    padding: 10px 0 0 0;
}

.analytics_cards_container_inner_subtext{
    margin: 16px 0 0 0;
    width: 15vw;
    font-size: 12px;
    color: #66707E;
}
.analytics_cards_container_inner_subtext > a {
    cursor: pointer;
}
.analytics_cards_inner_red {
    color: #CA6B6E;
}
.analytics_cards_inner_yellow{
    color: #eea61f;
}
.analytics_cards_inner_blue{
    color: #3B3CEA;
}

.analytics_cards_inner_red{
    color: #CA6B6E;
}

.analytics_cards_inner_violet{
    color: #A66DD4;
}

 .analytics_cards_container_inner_mouseover{
    position: absolute;
    /* width: 23.333vw; */
    transform: scale(1.2);
    /* height: 24.49vh; */
    z-index: 1;
    border-radius: 16px;
    background: #FFFFFF;
    border: 1px solid #E8E7E7;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.08);
    padding: 24px;
} 
.analytics_clinic_card{
    /* top: 0; */
    /* right: 0; */
}

.analytics_card_mouseover_subtitle{
    margin: 8px 0 0 0;
    font-size: 14px;
    color: #B2B7BE;
}
.analytics_card_mouseover_data_container{
    margin: 24px 0 0 0;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-template-rows: auto;
    row-gap: 24px;
}

.analytics_mouseover_data_title{
    font-size: 12px;
    color: #B2B7BE;
}
.analytics_mouseover_data_title_count{
    margin: 8px 0 0 0;
    font-size: 16px;
    font-weight: bold;
    color: #3B3CEA;
}
.events_link{
    color: #3B3CEA;
    font-weight: 600;
}

.devices_data_container{
    margin: 24px 0 0 0;
    display: flex;
    gap: 24px;
}
.devices_container{
    width: 38.75vw;
    padding: 24px;
    background: #FFFFFF;
    border: 1px solid #E8E7E7;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
}
.devices_title{
    font-size: 16px;
    color: #66707E;
    font-weight: 600;
}
.devices_logo_number{
    margin: 24px 0 0 0;
    display: flex;
    gap: 12px;
}
.device_pupil_count{
    font-size: 28px;
    font-weight: 400;
    color: #9B715D;
}
.device_table_container{
    margin: 24px 0 0 0;
}
table{
    border-spacing: 20px;
}

table th{
    font-size: 12px;
    font-weight: 600;
    color: #001028;
}

table th:first-child{
    width: 10.278vw;
    text-align: left;
}
table th:nth-child(n+2){
    width: 8vw;
    text-align: center;
}
table td:first-child{
    text-align: left;
    font-size: 14px;
    font-weight: 400;
}  
table td:nth-child(n+2){
    text-align: center;
    font-weight: 500;
}    
table td:nth-child(2){
    color: #3B3CEA;
}
.manage_devices_link{
    font-size: 12px;
    color: #9B715D;
}

.data_container{
    display: grid;
    grid-template-columns: repeat(2, 18.542vw);
    gap: 24px;
}
.data_container > div:first-child{
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;
}
.data_container > div:nth-child(2){
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
}
.data_container > div:nth-child(3){
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
}

.data_container > div:nth-child(4){
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
}

.data_container_boxes{
    border-radius: 16px;
    background: #FFFFFF;
    border: 1px solid #E8E7E7;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.08);
    padding: 24px;
    
}

.analytics_device_data_card_red{
    color: #CA6B6E;
}

.analytics_device_data_card_yellow{
    color: #EEA61F;
}

.graphTile {
    width: 38.75vw;
    padding: 24px;
    background: #FFFFFF;
    border: 1px solid #E8E7E7;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
}

.filterContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.devices_title{
    font-size: 16px;
    color: #66707E;
    font-weight: 600;
}

/* Stepper container */

.role_stepper_container_parent{
 margin: 20px 0 0 0;
 padding: 32px 0px 32px 24px;
 display: flex;
 justify-content: space-between;
 background: #FFFFFF;
 border: 1px solid #E8E7E7;
 box-shadow: 0px 1px 50px rgba(0, 0, 0, 0.08);
 border-radius: 16px;
}
.role_Stepper_container_parent > div:nth-child(2){
    padding: 20px 0 0 0;
}
.stepper_container_role{
    display: flex;
    column-gap: 60px;
    row-gap : 40px;
    flex-wrap: wrap;
    width: 23vw;
}
.label{
    font-size: 12px;
    font-weight: 400;
    color: #66707E;
}
.completed{
    margin: 16px 0 0 0;
    color: #001028;
    font-size: 12px;
    font-weight: 600;
}
.link_text{
    margin: 16px 0 0 0;
    font-weight: 600;
    font-size: 12px;
    color: #3B3CEA;
    cursor: pointer;
}

.role_activity_container{
    display: flex;
    gap: 12px;
}
.role_image_container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    background-color: #F5F6F7;
    border-radius: 8px;
}
.role_activity_description_name{
    font-size: 12px;
    font-weight: 400;
    color: #001028;

}
.role_activity_description_count{
    margin: 8px 0 0 0;
    font-size: 12px;
    font-weight: 600;
    color: #3B3CEA;

}
.role_activity_image_container{
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F5F6F7;
    border-radius: 8px;
}
.whatnew_and_faq_wrapper{
    display: flex;
    gap: 24px;
}

.whatsnew_container{
    margin: 24px 0 0 0;
    background: #FFFFFF;
    height: 384px;
    border: 1px solid #E8E7E7;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    width: 38.750vw;
    padding: 0 16px 0 24px;
    overflow: scroll;
}
.whatsnew_container > div:nth-child(2) > div:not(:last-child){
    border-bottom: 1px solid #E8E7E7;
}
.whatsnew_header_container{
    display: flex;
    justify-content: space-between;
}
.whatsnew_header_container > p{
    color: #66707E;
    font-weight: 600;
    padding: 30px 0 0 0;
}
.whatsnew_content_container{
    display: flex;
    flex-direction: column;
}
.whatsnew_content_box{
    padding: 12px 0;
}

.whatsnew_content_box > span{
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #001028;
}
.whatsnew_content_box > a{
    font-size: 10px;
    font-weight: 600;
    color: #3B3CEA;
}
.faq_container{
    margin: 24px 0 0 0;
    background: #FFFFFF;
    border: 1px solid #E8E7E7;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    width: 38.750vw;
    padding: 0 16px 0 24px;
    
}
.faq_container > div:nth-child(n+2){
    border-bottom: 1px solid #EBECEE;
}
.faq_container > div:last-child{
    border-bottom: none !important;
}

.faq_link{
    color: #3B3CEA;
    font-weight: 700;
    font-size: 10px;
}

.blogarticles_container{
    margin: 24px 0 0 0;;
    border-radius: 16px;
    width: 38.750vw;
    /* padding: 0 16px 0 24px; */
}
.blog_cards_wrapper{
    margin: 28px 0 0 0;
    display: flex;
    gap: 12px;
}

  
  
  
  