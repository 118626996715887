.audioContainer {
    display: flex;
    gap: 5px;
    justify-content: center;
    align-items: center;
}

.progressBarActive {
    position: relative;
    height: 2px;
    background-color: #00a2ff;
}

.timeTag {
    text-align: center;
    width: 60px;
    color: gray;
    font-size: 15px;
}

.playPauseIco :only-child {
    color: #006eae;
    font-size: 21px;
    display: flex;
    justify-content: center;
}


.progressBarBadge {
    position: absolute;
    z-index: 2;
    right: -4px;
    top: -3px;
    height: 8px;
    width: 8px;
    border-radius: 100%;
    background-color: #006eae;
}

.progressBar {
    width: 100%;
    height: 2px;
    background-color: #c3e9ff
}