* {
  font-family: "Inter", sans-serif;
}
.parent_container{
  position: relative;
}

.filter_container {
  display: flex;
  gap: 5px;
  padding: 5px 0 0 0;
  cursor: pointer;
}
.filter_container > p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  padding: 5px 0 0 0;
  font-size: 12px;
  line-height: 16px;
  color: #001028;
}

.filter_list_container {
  position: absolute;
  display: flex;
  top: 80%;
  flex-direction: column;
  width: 180px;
  padding: 16px;
  background: #ffffff;
  border: 1px solid #e7e7e7;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  z-index: 9;
}
