.noScreening {
    width: 100%;
    height: 100%;
    padding-top: 20px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    display: flex;
    padding-bottom: 32px;
}

.noScreening>img {
    width: 40%;
}

.noScreening>:nth-child(2) {
    margin-top: 48px;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;

}

.noScreening>:nth-child(3) {
    width: 320px;
    margin-top: 16px;
    text-align: center;
    /* width: 40%; */
    color: #66707E;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;

}

.noScreening>:nth-child(4) {
    cursor: pointer;
    border-radius: 8px;
    margin-top: 28px;
    padding: 15px 30px;
    background: #1740A1;
    color: white;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;

}

.noScreening>:nth-child(4):hover {

    background: #254eae;

}