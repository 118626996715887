* {
    font-family: "Inter", sans-serif;
}

.divContainer {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
}

.divContainer:-moz-drag-over {
    cursor: no-drop;
}

.formContainer {
    /* width: 100vw; */
    /* height: 100vh; */
    /* background-color: #7b2cbf; */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.uploadFilesContainer {
    /* background-color: #f7fff7; */
    /* width: 420px; */
    padding: 18px 0px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 10px 20px, rgba(0, 0, 0, 0.28) 0px 6px 6px; */
}

.dragFileArea {
    border: 2px dashed #66707E;
    border-radius: 20px;
    margin: 10px 0 15px;
    padding: 30px 50px;
    width: 350px;
    text-align: center;
}

.dragFileArea .uploadIcon {
    /* font-size: 50px; */
    margin-right: 10px;
}

.uploadIconDiv {
    display: flex;
    justify-content: center;
    align-items: center;
}

.dragFileArea h3 {
    font-size: 14px;
    margin: 18px 0 5px;
    color: #66707E;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
}

.or {
    font-size: 12px;
    color: #66707E;
    font-weight: 400;
    line-height: 18px;
}

.dragFileArea h3>span {
    color: blue;
    font-weight: bolder;
    cursor: pointer;
}

.onlyFiles {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    color: #B2B7BE;
}

.dragFileArea label {
    font-size: 12px;
}

.fileName,
.fileSize {
    padding: 0 3px;
}

.dragFileArea label .browseFilesText {
    color: blue;
    font-weight: bolder;
    cursor: pointer;
}

.browseFiles span {
    font-size: 14px;
}

.defaultFileInput {
    display: none;
}

.cannotUploadMessage {
    background-color: #ffc6c4;
    font-size: 17px;
    display: flex;
    align-items: center;
    margin: 5px 0;
    padding: 5px 10px 5px 30px;
    border-radius: 5px;
    color: #BB0000;
    display: none;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.cannotUploadMessage span,
.uploadButtonIcon {
    padding-right: 10px;
}

.cannotUploadMessage span:last-child {
    padding-left: 20px;
    cursor: pointer;
}

.fileBlock {
    color: #f7fff7;
    background-color: #000;
    transition: all 1s;
    width: 390px;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0 15px;
    padding: 10px 20px;
    border-radius: 25px;
}

.fileInfo {
    display: flex;
    align-items: center;
    font-size: 15px;
}

.fileIcon {
    margin-right: 10px;
}

.fileName,
.fileSize {
    padding: 0 3px;
}

.removeFileIcon {
    cursor: pointer;
}

.progressBar {
    display: flex;
    position: absolute;
    bottom: 0;
    left: 4.5%;
    width: 0;
    height: 5px;
    border-radius: 25px;
    background-color: #4BB543;
}

.uploadButton {
    display: flex;
    justify-content: center;
    background-color: #1740A1;
    width: 176px;
    height: 56px;
    color: #fff;
    display: flex;
    align-items: center;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    padding: 7.5px 50px;
    cursor: pointer;
}

.cancelButton {
    width: 176px;
    height: 56px;
    background-color: white;
    border: 1px solid #1740A1;
    color: #1740A1;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
}

.downloadButton {
    display: flex;
    gap: 12px;
    justify-content: center;
    background-color: #001028;
    width: 332px;
    height: 64px;
    color: #fff;
    align-items: center;
    font-size: 16px;
    border: none;
    border-radius: 10px;
    margin: 10px;
    padding: 7.5px 50px;
    cursor: pointer;
}

.downloadButton>img {
    height: 15px;
    width: 15px;
}

.downloadButton>a {
    color: #fff;
}

.buttonDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 375px;
}