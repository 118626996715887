.appointmentTile {
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    height: 124px;
    width: 200px;
    border-radius: 16px;
    margin-top: 16px;
    padding: 15px;
    justify-content: space-between;
    background: #FFFFFF;

}

.appointmentTile> :nth-child(1) {
    font-family: "Inter";
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: left;
}

.appointmentTile> :nth-child(2) {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
}

.appointmentTile> :nth-child(3) {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
}

.assessmentStats {
    display: flex;
    align-items: center;
    gap: 2rem;
    padding: 2rem 0;
}

.assessmentStats h1 {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: left;

}

.assign_header {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.assign_header_text {
    font-weight: 600;
}

.assign_validation_progress_btn {
    border: none;
    background: none;
    border-radius: 8px;
    width: 160px;
    height: 44px;
    padding: 10px 0 10px 4px;
    background-color: #1740A1;
    color: #FFFFFF;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
}

.assign_layout_header {
    margin: 32px 0 0 0;
    display: flex;
}

.assign_layout_header_inner_container_first {
    display: flex;
    border-radius: 16px 16px 0 0;
    border-top: 1px solid #E8E7E7;
    border-left: 1px solid #E8E7E7;
    border-right: 1px solid #E8E7E7;

}

.assign_layout_header_inner_container_first>div {
    padding: 12px 24px;
    cursor: pointer;
}

.assign_layout_header_inner_container_second {
    flex: 1;
    border-bottom: 1px solid #E8E7E7;
}

.assign_layout_header_physical {
    border-bottom: 1px solid #E8E7E7;
    border-right: 1px solid #E8E7E7;
    font-size: 14px;
    font-weight: 600;
    color: #B2B7BE;
}

.assign_layout_header_dental {
    border-bottom: 1px solid #E8E7E7;
    border-right: 1px solid #E8E7E7;
    font-size: 14px;
    font-weight: 600;
    color: #B2B7BE;
}

.assign_layout_header_behavioral {
    border-bottom: 1px solid #E8E7E7;
    border-bottom: 1px solid #E8E7E7;
    font-size: 14px;
    font-weight: 600;
    color: #B2B7BE;
}

.assign_layout_component_container {
    padding: 24px;
    border-radius: 0 0 16px 16px;
    border-bottom: 1px solid #E8E7E7;
    border-right: 1px solid #E8E7E7;
    border-left: 1px solid #E8E7E7;
    background-color: #FFFFFF;
}

.assign_layout_component_header {
    font-weight: 600;
    color: #66707E;
}

.assign_layout_component_subheader {
    margin: 24px 0 0 0;
    display: flex;
    gap: 20px;
}

.screening_text {
    display: flex;
    align-items: baseline;
    gap: 6px;
}

.assign_layout_header_bgColor_active_physical {
    border-radius: 16px 0 0 0;
    background-color: #FFFFFF !important;
    color: #001028 !important;
    border-bottom: none !important;
}

.assign_layout_header_bgColor_active_dental {
    background-color: #FFFFFF !important;
    color: #001028 !important;
    border-bottom: none !important;
}

.assign_layout_header_bgColor_active_behavioral {
    border-radius: 0 16px 0 0;
    color: #001028 !important;
    background-color: #FFFFFF !important;
    border-bottom: none !important;
}

.physical_screening_number {
    padding: 1px 0 0 0;
    color: #CA6B6E;
    font-size: 28px;
    font-weight: 400;
}

.yellow_screening_number {
    padding: 1px 0 0 0;
    color: #eea61f;
    font-size: 28px;
    font-weight: 400;
}

.physical_screening_text {
    font-size: 16px;
    font-weight: 400;
    color: #66707E;
}

.screening_cards_container {
    margin: 32px 0 0 0;
    /* display: grid;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    grid-auto-flow: row; */
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1.667vw;
}

.cards_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #FEECEC;
    padding: 15px 17px;
    border-radius: 16px;
    width: 261px;
    min-height: 160px;
    cursor: pointer;

}

.cards_container>div {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.cards_container>div>div {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: right;
    color: #66707E;

}

.cards_container>h1 {
    font-size: 18px;
    font-weight: 600;
    text-align: left;
    color: #EF1210;
    line-height: 22px;


}

.cards_container :nth-child(3) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background-color: #66707E; */
}

.cards_container :nth-child(3) div {
    font-size: 12px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;

}

.cards_container :nth-child(3)>div>span {
    font-size: 16px;
    font-weight: 600;
    color: #EF1210;
}


/* .screening_cards_container > div:first-child{
    grid-area: 1/1/3/2;
} */
.screening_card_large {
    padding: 16px;
    background: #FFFFFF;
    border: 1px solid #E8E7E7;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
}

.screening_card_large>p:first-child {
    color: #001028;
    font-size: 14px;
    font-weight: 400;
}

.large_card_data_box {
    margin: 16px 0 0 0;
    display: flex;
    justify-content: space-between;
}

.large_card_data_box>div>p:first-child {
    width: 10.139vw;
    color: #B2B7BE;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
}

.large_card_data_box>div>p:nth-child(2) {
    margin: 8px 0 0 0;
    color: #3b3cea;
}

.input_assign_container {
    margin: 24px 0 0 0;
    display: flex;
    justify-content: space-between;
}

.input_assign_container>div>p:first-child {
    color: #B2B7BE;
    font-size: 12px;
    font-weight: 400;
}

.input_assign_container>div>input {
    margin: 8px 0 0 0;
    width: 10.139vw;
    height: 40px;
    border: none;
    background: #F5F5FF;
    border-radius: 4px;
    padding: 10px;
}

.input_assign_container>div>input:focus {
    border: none;
}

.date_input::placeholder {
    color: #B2B7BE;
    padding: 0 10px;
}

.decision_buttons {
    margin: 24px 0 0 0;
    display: flex;
    justify-content: space-between;
}

.decision_buttons>button {
    background: none;
    border: none;
    padding: 12px 0;
    width: 10.139vw;
    font-size: 12px;
    font-weight: 600;
    border-radius: 8px;
    background-color: #1740A1;
    color: #FFFFFF;
    cursor: pointer;
}

.decision_buttons>div {
    width: 10.139vw;
    padding: 12px 0;
    text-align: center;
}

.decision_buttons>div>button:first-child {
    background: none;
    border: none;
    color: #1740A1;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
}

.loading {
    display: flex;
    height: 400px;
    justify-content: center;
    align-items: center;
    position: relative;
}