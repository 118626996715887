.wrapper {
    width: 50px;
    padding: 5px;
    height: fit-content;
    border-radius: 50px;
}

.wrapper:hover {
    background-color: #edfff6;
}

.checkmark {
    stroke: #149457;
    stroke-dashoffset: 745.74853515625;
    stroke-dasharray: 745.74853515625;
    animation: dash 2s ease-out forwards infinite;
    margin: 0px;
}

.checkmarkInactive {
    stroke: #e5e5e5;
    stroke-dashoffset: 745.74853515625;
    stroke-dasharray: 745.74853515625;
    stroke-dashoffset: 0;
}


@keyframes dash {
    0% {
        stroke-dashoffset: 745.74853515625;
    }

    100% {
        stroke-dashoffset: 0;
    }
}