@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

.container {
  position: relative;
  margin: 0 auto;
  width: 595px;
  height: 842px;
  padding: 24px 24px 8px 24px;
  background-color: rgb(238, 238, 238);
  background-image: url("../../../assets/images/Background.svg");
}

.header_container {
  display: flex;
  gap: 8px;
}

.header_container > img {
  height: 32px;
  width: 22px;
}

.header_container > p {
  font-family: "Inter";
  padding: 6px 0 0 0;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #001028;
}

.method_container {
  margin: 24px 0 0 0;
  font-weight: 500;
  font-size: 8px;
  line-height: 12px;
  color: #66707e;
}

.certification_container {
  margin: 8px 0 0 0;
  display: flex;
  column-gap: 24px;
}

.footer_container_main{
    position: absolute;
    bottom: 10px;
    width: 547px;
}

.footer_container {
  display: flex;
  justify-content: space-between;
}

.footer_container > p:first-child {
  font-weight: 600;
  font-size: 8px;
  line-height: 12px;
  color: #001028;
}

.footer_container > p:nth-child(2) {
  font-weight: 400;
  font-size: 8px;
  line-height: 12px;
  color: #001028;
}

