.privacy_policy {
  font-family: Montserrat;
  font-size: 2em;
  font-weight: 600;
  line-height: 1.2;
  color: #000;
}
.last_update {
  font-family: Montserrat;
  font-size: 10px;
  line-height: 1.4;
  color: #000;
  padding-top: 1vh;
}
.privacy_policies {
  padding: 5vh 2vw;
}
.privacy_contents {
  padding: 2vh 0;
}
.privacy_items {
  padding: 1vh 0;
}
.privacy_head {
  padding: 1vh 0;
  font-weight: 600;
  font-size: 1.2em;
  line-height: 1.4;
  color: #000;
  padding-top: 1vh;
}
