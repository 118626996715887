@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  color: #001028;
}

.studentRow_container {
  display: grid;
  align-items: center;
  border-radius: 8px;
  padding: 10px 0 10px 16px;
  border: 1px solid #e7e7e7;
  background-color: #fff;
  gap: 20px;
  /* grid-template-columns: 0.6fr 0.24fr 0.3fr 0.4fr 0.3fr 0.4fr 0.28fr 0.2fr; */
  grid-template-columns: 1fr 0.9fr 0.44fr 1.1fr 0.3fr 0.3fr;
  transition: all 0.2s;
}

.studentRow_container:hover {
  box-shadow: -1px 3px 10px rgb(209, 209, 209);
  transform: scale(1.01);
}

.studentRow_container>div {
  /* border: 1px solid red; */
  font-size: 12px;
}



/* Name and Avatar Styles */
.studentRow_container>div:first-child {
  display: flex;
  font-weight: bold;
  word-wrap: break-word;
}


.studentRow_container>div:first-child>div:nth-child(2) {
  padding: 0px 0 0 16px;
}

.studentRow_container>div:first-child>div:nth-child(2)>p {
  font-size: 12px;
  font-weight: 600;
}


/* Age Styles */

.studentRow_container>div:nth-child(2) {
  padding: 5px 0;
  text-align: left;
  font-size: 12px;
  word-wrap: break-word;
}

/* Gender Styles */
.studentRow_container>div:nth-child(3) {
  padding: 5px 0;
  text-align: left;
  font-size: 12px;
}

.studentRow_container>div:nth-child(4) {
  padding: 5px 0;
  text-align: left;
  font-size: 12px;
  /* border: 1px solid red; */

  word-wrap: break-word;
}

.studentRow_container>div:nth-child(4)>p {
  /* color: #3b3cea !important;   */
}

/* Screening Styles And Teacher Style */
.studentRow_container>div:nth-child(5) {
  padding: 5px 0;
  text-align: left;
  font-size: 12px;

}

/* Conditional Report Button */
.studentRow_container>div:nth-child(6) {
  padding: 5px 0;
  text-align: left;
}

.report_btn {
  padding: 5px 0;
  width: 72px;
  border: none;
  background: none;
  background-color: #fd6f09;
  font-size: 8px;
  border-radius: 5vh;
  color: #fff;
  /* cursor: pointer; */
  display: flex;
  justify-content: center;
}

.report_btn_active {
  padding: 5px 0;
  width: 72px;
  border: none;
  background: none;
  background-color: #149457 !important;
  font-size: 8px;
  border-radius: 5vh;
  font-weight: 600;
  color: #fff;
  /* cursor: pointer; */
  display: flex;
  justify-content: center;
}

.screened_btn {
  padding: 5px 0;
  width: 72px;
  border: 1px solid #EBECEE;
  background: #EBECEE;
  font-size: 8px;
  color: #B2B7BE !important;
  font-weight: 600;
  border-radius: 5vh;
  cursor: pointer;
  text-align: center;

}

.verify_btn {
  padding: 5px 0;
  width: 72px;
  border: 1px solid #1740A1;
  background: #1740A1;
  font-size: 8px;
  color: #FFF !important;
  font-weight: 600;
  border-radius: 5vh;
  cursor: pointer;
  text-align: center;

}

/* Validated Styling */

.studentRow_container>div:nth-child(7) {
  padding: 5px 0;
  text-align: left;
  font-size: 12px;
}

.studentRow_container>div:nth-child(8) {
  padding: 5px;
  font-size: 12px;

}




/* Responsive Media Query */