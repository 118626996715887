@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

*{
    font-family: "Inter", sans-serif;
}
.mainDiv{
    width: 100%;
}

.header {
    display: flex;
    align-items: center;
}

.header_text {
    font-weight: 500;
    color: #000;
}

.back {
    font-weight: 500;
    color: #9d9d9d;
}

.container {
    border-radius: 16px;
    box-shadow: 0 1px 50px 0 rgba(0, 0, 0, 0.08);
    border: solid 1px #e8e7e7;
    background-color: #fff;
    padding: 3vh 2vw;
    margin: 3vh 0;
}

.logo_text {
    font-size: 0.8em;
    line-height: 1.5;
    color: #a3a3a3;
}

.upload_logo {
    display: flex;
    padding: 3vh 0;
    align-items: center;
}

.upload {
    display: flex;
    padding-left: 2vw;
    color: #3b3cea;
}

.upload_text {
    padding-left: 0.5vw;
    color: #3b3cea;
    font-size: 12px;
    padding-top: 0.5vh;
}
.validation_text{
    font-size: 10px;
    color: rgb(243, 114, 114);
}

.no_upload_logo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 122px;
    width: 122px;
    background-color: #F9F9F9;
    border: 2px solid #E8E7E7;
    border-radius: 50%;
  }
.input_layout {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.input_school_layout {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}
.input_user_layout {
    display: grid;
    width: 80%;
    grid-template-columns: 0.4fr 1fr 1fr;
    gap: 24px;
}


.contact_info {
    font-size: 0.7em;
    line-height: 1.5;
    color: #a3a3a3;
    padding-top: 3vh;
}

.lowlight_text {
    font-size: 0.7em;
    line-height: 1.5;
    color: #343434;
    padding-top: 1vh;
}

.buttons {
    margin: 48px 0 0 0;
    display: flex;
    justify-content: flex-end;
}

.button_with_border {
    font-size: 0.9em;
    font-weight: 600;
    line-height: 1.25;
    width: 12.2vw;
    text-align: center;
    color: #1740A1;
    padding: 1.3vh 2.5vw;
    border-radius: 8px;
    border: solid 1px #1740A1;
    background-color: #fff;
    margin-right: 2vw;
    cursor: pointer;
}

.button_filled {
    border: none;
    font-size: 0.9em;
    width: 12.2vw;
    border-radius: 8px;
    background-color: #1740A1;
    font-weight: 600;
    line-height: 1.25;
    padding: 1.3vh 1vw;
    text-align: center;
    color: #fff;
    cursor: pointer;
}

.button_filled:disabled {
    background-color: #ADADAD !important;
    color: #fff;
}

.button_amenities_container {
    display: flex;
    gap: 8px;
    padding: 0 100px 0 0;
    flex-wrap: wrap;
}

.button_amenities {
    display: flex;
    margin: 8px 0 0 0;
    padding: 8px 10px 4px 10px;
    background-color: #e7e7fe;
    justify-content: space-between;
    border-radius: 16px;
    gap: 8px;
}

.button_amenities>p {
    color: #3b3cea;
    font-size: 10px;
}

.button_amenities>button {
    padding: 0;
    background-color: transparent;
    border: none;
    cursor: pointer;
}