/* .input-border {
    border-color: #e8e7e7 !important;
    border-radius: 8px !important;
} */

/* .inputLabel {
    color: #9d9d9d !important;
    font-family: "Inter", sans-serif !important;
    font-size: 14px !important;
}
.labelAsterisk{
    color: #9d9d9d !important;
}

.helper-text {
    text-transform: initial;
    font-size: 1rem !important;
} */
.MuiSvgIcon-root .MuiSvgIcon-fontSizeMedium .MuiSvgIcon-root{
    fill: #66707E;
}
.myDatePicker fieldset.MuiOutlinedInput-notchedOutline {
    border-color: #e8e7e7;
  }
  
  .myDatePicker .Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
    border-color: #e8e7e7;
  }
  