.header {
  display: flex;
  align-items: center;
}
.header_text {
  font-weight: 500;
  color: #000;
}
.back {
  font-weight: 500;
  color: #9d9d9d;
}
.container {
  border-radius: 16px;
  box-shadow: 0 1px 50px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px #e8e7e7;
  background-color: #fff;
  padding: 3vh 2vw;
  margin: 3vh 0;
}
.logo_text {
  font-size: 0.8em;
  line-height: 1.5;
  color: #a3a3a3;
}
.upload_logo {
  display: flex;
  padding: 3vh 0;
  align-items: center;
}
.upload {
  display: flex;
  padding-left: 2vw;
  color: #3b3cea;
}

.upload {
  display: flex;
  padding-left: 2vw;
  color: #3b3cea;
  align-items: center;
}

.validation_text {
  font-size: 10px;
  color: rgb(243, 114, 114);
}

.no_upload_logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 122px;
  width: 122px;
  background-color: #f9f9f9;
  border: 2px solid #e8e7e7;
  border-radius: 50%;
}

.uploadLabel {
  display: flex;
  gap: 8px;
  align-items: center;
  width: 150px;
  cursor: pointer;
}

.uploadPhoto {
  display: none;
}

.upload_text {
  padding-left: 0.5vw;
  color: #3b3cea;
  font-size: 12px;
  padding-top: 0.5vh;
}
.input_layout {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}
.input_school_layout {
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.package_drawer {
  margin: 26px 0 0 0;

}

.contact_info {
  font-size: 0.7em;
  line-height: 1.5;
  color: #a3a3a3;
  padding-top: 3vh;
}
.buttons {
  margin: 48px 0 0 0;
  display: flex;
  justify-content: flex-end;
}
.button_with_border {
  font-size: 0.9em;
  font-weight: 600;
  line-height: 1.25;
  width: 12.2vw;
  text-align: center;
  color: #1740a1;
  padding: 1.3vh 2.5vw;
  border-radius: 8px;
  border: solid 1px #1740a1;
  background-color: #fff;
  margin-right: 2vw;
  cursor: pointer;
}
.button_filled {
  border: none;
  font-size: 0.9em;
  width: 12.2vw;
  border-radius: 8px;
  background-color: #1740a1;
  font-weight: 600;
  line-height: 1.25;
  padding: 1.3vh 1vw;
  text-align: center;
  color: #fff;
  cursor: pointer;
}

.button_filled:disabled {
  background-color: #adadad !important;
  color: #fff;
}

.button_amenities_container {
  display: flex;
  gap: 8px;
  padding: 0 100px 0 0;
  flex-wrap: wrap;
}
.button_amenities_container_multiple {
  display: flex;
  gap: 8px;
  padding: 0 0 0 25vw;
  flex-wrap: wrap;
}

.button_amenities {
  display: flex;
  margin: 8px 0 0 0;
  padding: 8px 10px 4px 10px;
  background-color: #e7e7fe;
  justify-content: space-between;
  border-radius: 16px;
  gap: 8px;
}
.button_amenities > p {
  color: #3b3cea;
  font-size: 10px;
}
.button_amenities > button {
  padding: 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.entity_admin_header {
  margin: 48px 0 0 0;
  font-weight: 600;
}
.entity_admin_text {
  font-size: 12px;
  margin: 8px 0 0 0;
}
.lettered_avatar_container {
  margin: 24px 0 0 0;
}

.input_admin_layout {
  display: flex;
  gap: 1.667vw;
}
.uploadLogo {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}
