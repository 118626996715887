* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Montserrat;
  color: #343434;
}

/* .studentRow_container{
  display: flex;
  border-radius: 8px;
  margin: 12px 0 0 0;
  padding: 10px 0 10px 16px;
  border: 1px solid #e7e7e7;
  background-color: #fff;
  

} */

.studentRow_container {
  display: grid;
  align-items: center;
  border-radius: 8px;
  padding: 10px 0 10px 16px;
  border: 1px solid #e7e7e7;
  background-color: #fff;
  gap: 24px;
  grid-template-columns: 0.5fr 0.2fr 0.2fr 0.5fr 0.5fr 0.3fr 0.3fr 0.2fr;
  transition: all .2s;

}

.studentRow_container:hover {
  box-shadow: -1px 3px 10px rgb(209, 209, 209);
  transform: scale(1.01);
}


.studentRow_container>div {
  /* border: 1px solid red; */
  font-size: 12px;
}



/* Name and Avatar Styles */
.studentRow_container>div:first-child {
  display: flex;
  font-weight: bold;
  word-wrap: break-word;
  align-items: center;
}


.studentRow_container>div:first-child>div:nth-child(2) {
  padding: 0px 0 0 16px;
}

.studentRow_container>div:first-child>div:nth-child(2)>p {
  font-size: 12px;

}


/* Age Styles */

.studentRow_container>div:nth-child(2) {
  padding: 5px 0;
  text-align: left;
  font-size: 12px;
}

/* Gender Styles */
.studentRow_container>div:nth-child(3) {
  padding: 5px 0;
  text-align: left;
  font-size: 12px;
}

.studentRow_container>div:nth-child(4) {
  padding: 5px 0;
  text-align: left;
  font-size: 12px;
  min-width: 166px;
  /* border: 1px solid red; */
  word-wrap: break-word;
}

/* Screening Styles And Teacher Style */
.studentRow_container>div:nth-child(5) {
  padding: 5px 0;
  text-align: left;
  font-size: 12px;

}

/* Conditional Report Button */
.studentRow_container>div:nth-child(6) {
  padding: 5px 0;
  text-align: left;
}

.report_btn {
  padding: 5px 0;
  width: 72px;
  border: none;
  background: none;
  background-color: #62a615;
  font-size: 8px;
  border-radius: 5vh;
  color: #fff;
  cursor: pointer;
}

.screened_btn {
  padding: 5px 0;
  width: 72px;
  border: 1px solid #e7e7e7;
  background: none;
  font-size: 8px;
  color: #000;
  border-radius: 5vh;
  cursor: pointer;

}

/* Validated Styling */

.studentRow_container>div:nth-child(7) {
  padding: 5px 0;
  text-align: left;
  font-size: 12px;
}

.studentRow_container>div:nth-child(8) {
  padding: 5px;
  font-size: 12px;

}


.infoHover {
  background-color: transparent;
  width: fit-content;

  &:hover {
    background: rgba(#f6f6f6, .3);

    &>.popOnHover {
      opacity: 1;
      width: 104px;
      height: 113px;
    }
  }
}

.infoHover>p {
  height: 25px;
}

.popOnHover {
  box-shadow: 0px 0px 10px lightgrey;
  border-radius: 10px;
  /* white-space: nowrap; */
  overflow: hidden;
  opacity: 0;
  width: 0px;
  height: 113px;
  position: absolute;
  right: 17px;
  top: 10px;
  z-index: 2;
  bottom: -100px;
  transition: all .4s ease;
}




/* Responsive Media Query */

@media screen and (min-width: 1024px) and (max-width : 1100px) {

  .studentRow_container {
    display: grid;
    border-radius: 8px;
    padding: 10px 0 10px 16px;
    border: 1px solid #e7e7e7;
    background-color: #fff;
    gap: 24px;
    grid-template-columns: 0.5fr 0.2fr 0.2fr 0.5fr 0.4fr 0.4fr 0.3fr 0.2fr;
  }

  .studentRow_container>div {
    /* border: 1px solid red; */
    font-size: 11px;
  }


  /* Name and Avatar Styles */
  .studentRow_container>div:first-child {
    display: flex;
    font-weight: bold;

  }


  .studentRow_container>div:first-child>div:nth-child(2) {
    padding: 4px 0 0 15px;
  }

  .studentRow_container>div:first-child>div:nth-child(2)>p {
    font-size: 11px;

  }


  /* Age Styles */

  .studentRow_container>div:nth-child(2) {
    padding: 5px 0;
    text-align: left;

  }

  /* Gender Styles */
  .studentRow_container>div:nth-child(3) {
    padding: 5px 0;
    text-align: left;

  }

  .studentRow_container>div:nth-child(4) {
    padding: 5px 0;
    text-align: left;
    min-width: 110px;
    /* border: 1px solid red; */
    word-wrap: break-word;

  }

  /* Screening Styles And Teacher Style */
  .studentRow_container>div:nth-child(5) {
    padding: 5px 0;
    text-align: left;

  }

  /* Conditional Report Button */
  .studentRow_container>div:nth-child(6) {
    padding: 5px 0;
    text-align: left;
  }

  .report_btn {
    padding: 5px 0;
    width: 72px;
    border: none;
    background: none;
    background-color: #62a615;
    font-size: 8px;
    border-radius: 5vh;
    color: #fff;
    cursor: pointer;
  }

  .screened_btn {

    padding: 5px 0;
    width: 72px;
    border: 1px solid #e7e7e7;
    background: none;
    font-size: 8px;
    color: #000;
    border-radius: 5vh;
    cursor: pointer;

  }



  /* Validated Styling */

  .studentRow_container>div:nth-child(7) {
    padding: 5px 0;
    text-align: left;
    font-size: 8px;
  }

  .studentRow_container>div:nth-child(8) {
    padding: 5px;
    font-size: 12px;

  }
}



@media screen and (min-width: 1101px) and (max-width : 1200px) {

  .studentRow_container {
    display: grid;
    border-radius: 8px;
    padding: 10px 0 10px 16px;
    border: 1px solid #e7e7e7;
    background-color: #fff;
    gap: 22px;
    grid-template-columns: 0.5fr 0.2fr 0.2fr 0.5fr 0.5fr 0.3fr 0.3fr 0.2fr;
  }

  .studentRow_container>div {
    /* border: 1px solid red; */
    font-size: 11px;
  }


  /* Name and Avatar Styles */
  .studentRow_container>div:first-child {
    display: flex;
    font-weight: bold;
  }


  .studentRow_container>div:first-child>div:nth-child(2) {
    padding: 4px 0 0 15px;
  }

  .studentRow_container>div:first-child>div:nth-child(2)>p {
    font-size: 11px;

  }


  /* Age Styles */

  .studentRow_container>div:nth-child(2) {
    padding: 5px 0;
    text-align: left;

  }

  /* Gender Styles */
  .studentRow_container>div:nth-child(3) {
    padding: 5px 0;
    text-align: left;

  }

  .studentRow_container>div:nth-child(4) {
    padding: 5px 0;
    text-align: left;
    font-size: 12px;
    min-width: 130px;
    /* border: 1px solid red; */
    word-wrap: break-word;

  }

  /* Screening Styles And Teacher Style */
  .studentRow_container>div:nth-child(5) {
    padding: 5px 0;
    text-align: left;

  }

  /* Conditional Report Button */
  .studentRow_container>div:nth-child(6) {
    padding: 5px 0;
    text-align: left;


  }


  .report_btn {
    padding: 5px 0;
    width: 72px;
    border: none;
    background: none;
    background-color: #62a615;
    font-size: 8px;
    border-radius: 5vh;
    color: #fff;
    cursor: pointer;
  }

  .screened_btn {

    padding: 5px 0;
    width: 72px;
    border: 1px solid #e7e7e7;
    background: none;
    font-size: 8px;
    color: #000;
    border-radius: 5vh;
    cursor: pointer;

  }

  /* Validated Styling */

  .studentRow_container>div:nth-child(7) {
    padding: 5px 0;
    text-align: left;
    font-size: 8px;
  }

  .studentRow_container>div:nth-child(8) {
    padding: 5px;
    font-size: 12px;

  }
}