* {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
  box-sizing: border-box;
}

.container {
  padding: 15px 14px 0 14px;
}

.package_drawer_btn {
  /* display: inline-block; */
  /* margin: 25px 0 0 0; */
  cursor: pointer;
  font-size: 10px;
  font-weight: 600;
  line-height: 16px;
  color: #3E36B0;
  text-decoration: underline;
}

.customise_header {
  display: flex;
  align-items: center;
  gap: 16px;
}

.customise_header> :nth-child(3) {
  margin-left: auto;
}

.customise_header>p {
  font-family: ArgentLight !important;
  font-weight: 300;
  font-size: 28px;
}

/* header  */

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 10px 0;
}

.header h1 {
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
}

.header p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
}

.header button,
.nextConsultation button {
  width: 120px;
  height: 32px;
  border-radius: 100px;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  text-align: center;
  background-color: #3E36B0;
  color: #fff;
  cursor: pointer;
  outline: 0;
  border: 0;
}

/* assessment */
.assessment,
.nextConsultation {
  padding: 10px;
  border-bottom: 1px solid #EBECEE;
}

.assessment>div {
  display: grid;
  grid-template-columns: 1.5fr 0.5fr 0.5fr;
  gap: 1rem;
  padding: 3px 0;
}

.assessment :nth-child(1)>div {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #66707E;
}

.assessment :nth-child(2)>div {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
}

.assessment p {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: #3E36B0;
  padding: 5px 0;

}

.assessment a {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: #3E36B0;
  padding: 5px 0;
  cursor: pointer;
  text-decoration: underline;
}

/* nextConsultation  */

.nextConsultation p {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #66707E;

}

.nextConsultation :nth-child(2) {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #001028;
  padding: 5px 0;
}

.nextConsultation :nth-child(3) {
  display: flex;
  align-items: center;
  padding: 7px 0;
  gap: 10px;
}

.nextConsultation :nth-child(3)>p {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;
  color: #343434;
}

/* lastConsultation */
.lastConsultation {
  padding: 10px;
}

.lastConsultation>div>p {
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: left;
  color: #66707E;
  padding: 10px 0 5px 0;
}

.lastConsultation>div>div {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #001028;

}