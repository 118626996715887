* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }


  .accordion-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    padding: 16px 0;
  }
    
  .accordion-content {
    /* background-color: #39b9d2; */
  }

  .accordion-content > div > p:first-child{
    margin: -10px 0 0 0;
    font-size: 12px;
    color: #a3a3a3;

  }

  .accordion-content > div > p:nth-child(2){
    margin: 16px 0 0 0;
    font-size: 10px;

  }

  .accordion-content > div:nth-child(2){
    padding: 0 0 16px 0;
  }
  
  .accordion-content > div:nth-child(2) >  button{
    margin: 12px 2% 0 0;
    border-radius: 20px;
    padding: 1% 3.5%;
    font-size: 14px;
    border: 1px solid #e8e7e7;
    background-color: #ffffff;
    color: #484848;
  }
