* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Inter", sans-serif;
    color: #001028;
}

.container>p {
    font-size: 20px;
}

.topic-container {
    margin: 24px 0 0 0;
    background-color: #ffffff;
    border-radius: 1%;
    display: grid;
    padding: 1.5%;

    grid-template-columns: repeat(2, 1fr);

}

/* .topic-container>div {
    border: 1px solid red;
} */

.topic-container>div:first-child {
    border-right: 1px solid #ebecee;
    /* padding: 20px 0; */
    padding: 0 3% 0 0;
}

.topic-container>div:first-child>section:first-child {
    width: 94%;
    display: flex;
    /* border: 1px solid black; */
    /* justify-content: space-between; */
    gap: 6px;
}

.topic-container>div:first-child>section:first-child>button {
    background: none;
    padding: 1% 5%;
    border-radius: 5vw;
    font-size: 13px;
    outline: none;
    border: none;
    background-color: #f5f6f7;
    cursor: pointer;
}

.active_btn{
    background-color: #1740A1 !important; 
    color: #ffffff !important;
}


.topic-container>div:first-child>section:nth-child(2) {
    margin: 32px 0 0 0;
    /* border-top: 1px solid #e8e7e7; */

}

.topic-container>div:first-child>section:nth-child(2)>section {
    border-bottom: 1px solid #e8e7e7;
}

.topic-container>div:first-child>section:nth-child(2)>section:nth-last-child(1) {
    border: none
}



/* Second Column div */

.topic-container>div:nth-child(2) {
    padding: 0 0 0 3%;

    /* position: relative; */
}

.topic-container>div:nth-child(2)>section:first-child>p {
    font-size: 14px;
}

.topic-container>div:nth-child(2)>section:nth-child(3) {
    /* margin: 32px 0 0 0; */
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 16px;
    column-gap: 17px;
    z-index: -1;

    /* position: fixed; */
}

/* .topic-container>div:nth-child(2)>section:nth-child(3)>div {
    border-radius: 8px;
    height: 64px;
    border: 1px solid #e8e7e7;
    padding: 2%;

}

.topic-container>div:nth-child(2)>section:nth-child(3)>div:nth-child(1) {
 width: 200px;
 padding: 5px;

} */

.topic-container>div:nth-child(2)>section:nth-child(3)>input::placeholder {
    position: absolute;
    /* top: 0; */
    font-size: 12px;
    text-align: left;


}

.topic-container>div:nth-child(2)>section:nth-child(3)>div>input {
    outline: none;
    border: none;

}


/* .topic-container>div:nth-child(2)>section:nth-child(3)>div:nth-child(3) {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
    height: 96px !important;

} */

.topic-container>div:nth-child(2)>section:nth-child(4) {
    margin: 16px 0 0 0;
    /* border: 1px solid red;     */
}

.topic-container>div:nth-child(2)>section:nth-child(4)>img {
    padding: 0 0 0 1%;
}

.topic-container>div:nth-child(2)>section:nth-child(4)>div {
    margin: 6px 0 0 0;
    border: 1px dashed #e8e7e7;
    padding: 15px 12px;
    width: 100%;
    border-radius: 8px;
}

.topic-container>div:nth-child(2)>section:nth-child(4)>div>p {
    color: #3b3cea;
    font-size: 14px;
    font-weight: bold;
}

.topic-container>div:nth-child(2)>section:nth-child(4)>div>p>span {
    color: #a3a3a3;
}

.uploadedStatus {
    display: flex;
}

.uploadedStatus>div {
    margin: 12px 0 0 0;
    padding: 10px 15px;
    color: #3b3cea;
    background-color: #ecedfb;
    border-radius: 20px;
    font-size: 12px;
    font-weight: bold;
}

.uploadedStatus button {
    border: none;
    background: none;
    cursor: pointer;
    height: 20px;
    margin: 20px 0 0 8px;
}

.confirmBtn {
    margin: 40px 0 0 0;
    display: flex;
    flex-direction: row-reverse;
}

.confirmBtn button {
    border: none;
    padding: 12px 50px;
    border-radius: 8px;
    background: none;
    border: 1px solid #1740A1;
    font-weight: bold;
    color: #001028;
    cursor: pointer;
}

.confirmBtn button:first-child {
    border: none;
    background: none;
    margin-left: 24px;
    color: #ffffff;
    background-color: #1740a1;
}

/* Select Tag ---- Custom CSS Start */