.student_details_container {
    margin: 18px 0 0 0;
    display: flex;
    gap: 12px;
}

.student_details_container>div {
    background: #ffffff;
    border: 1px solid #e8e7e7;
    box-shadow: 0px 1px 50px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
}

.student_avatar_container {
    flex-basis: 175px;
    padding: 12px 0 8px 12px;
}

.student_avatar_container>div:nth-child(2) {
    margin: 8px 0 0 0;
    display: flex;
    justify-content: center;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #343434;
}

.student_avatar_container>div:nth-child(3) {
    margin: 4px 0 0 0;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.student_avatar_container>div:nth-child(3)>div {
    display: flex;
    column-gap: 8px;
    align-items: center;
    justify-content: space-between;
}

.student_avatar_container>div:nth-child(3)>div>p {
    font-weight: 500;
    font-size: 10px;
    line-height: 14px;
    color: #343434;
}

.student_avatar_container>div:nth-child(3)>div>p:nth-child(2),
.student_avatar_container>div:nth-child(3)>div>p:nth-child(4) {
    padding-bottom: 5px;
    font-weight: 800;
    color: #9d9d9d;
}

.validation_container {
    margin: 16px 0 0 0;
}

.validation_container>div>p:first-child {
    font-weight: 400;
    font-size: 8px;
    line-height: 12px;
    color: #6b6c6f;
}

.validation_container>div>p:nth-child(2) {
    margin: 2px 0 0 0;
    font-weight: 600;
    font-size: 8px;
    line-height: 12px;
    color: #343434;
}

.validation_container>div:nth-child(2) {
    margin: 12px 0 0 0;
}

.student_details_container>div:nth-child(2) {
    flex-basis: 360px;
    padding: 16px 12px 12px 12px;
}

.student_details_container>div:nth-child(2)>p:first-child {
    font-weight: 400;
    font-size: 8px;
    line-height: 12px;
    color: #6b6c6f;
}

.student_details_container>div:nth-child(2)>div:nth-child(2) {
    margin: 4px 0 0 0;
    display: flex;
    gap: 10px;
}

.student_details_container>div:nth-child(2)>div:nth-child(2)>p {
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;
    color: #001028;
}

.interpretation_container {
    margin: 24px 0 0 0;
    display: flex;
    column-gap: 9px;
    align-items: flex-start;
}

.interpretation_container>div:first-child {
    padding: 3px 0 0 0;
}

.interpretation_container>div:nth-child(2)>p:first-child {
    font-weight: 400;
    font-size: 8px;
    line-height: 12px;
    color: #6b6c6f;
}

.interpretation_container>div:nth-child(2)>p:nth-child(2) {
    margin: 4px 0 0 0;
    font-style: normal;
    font-weight: 400;
    padding-right: 23px;
    font-size: 8px;
    line-height: 12px;
    color: #000000;
}

.title_container {
    margin: 24px 0 0 0;
    text-align: center;
    font-family: "ArgentThin";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: #000000;
}

.header_container {
    display: flex;
    gap: 8px;
}

.header_container>img {
    height: 32px;
    width: 22px;
}

.header_container>p {
    font-family: "Inter";
    padding: 6px 0 0 0;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #001028;
}

.footer_container {
    position: absolute;
    width: 100%;
    bottom: 10px;
    left: 0px;
    padding: 0px 10px;
    display: flex;
    justify-content: space-between;
}

.footer_container>p:first-child {
    font-weight: 600;
    font-size: 8px;
    line-height: 12px;
    color: #001028;
}

.footer_container>p:nth-child(2) {
    font-weight: 400;
    font-size: 8px;
    line-height: 12px;
    color: #001028;
}

.copyright_details_container {
    margin: 8px 0 0 0;
    display: flex;
    justify-content: space-between;
}

.copyright_details_container>div {
    width: 250px;
    font-weight: 400;
    font-size: 6px;
    line-height: 10px;
    color: #000000;
}

.method_framework {
    margin: 16px 0 0 0;
    font-weight: 500;
    font-size: 8px;
    line-height: 12px;
    color: #66707e;
}


.certification_container {
    margin: 8px 0 0 0;
    display: flex;
    column-gap: 24px;
}