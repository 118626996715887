.ticket_info {
  width: 100%;
}

.ticket_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header {
  display: flex;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: normal;
  color: #000;
}

.ticket_container {
  position: relative;
  margin-top: 3vh;
  padding: 3vh 2vw;
  border-radius: 16px;
  box-shadow: 0 1px 50px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px #e8e7e7;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  /* padding-bottom: 8vh; */
}

.ticket_container .issue_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}

.ticket_container .issue_header > div > div {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.ticket_container .issue_header > div > div > .issue_status {
  color: #fff;
  padding: 5px;
  border-radius: 10px;
}

.ticket_container .issue_metadata {
  display: flex;
  gap: 2rem;
  /* justify-content: center; */
  align-items: center;
  margin: 1rem auto;
}

.ticket_container .issue_metadata > div > h6 {
  font-size: 1rem;
}

.ticket_container .issue_metadata > div > h6 > span {
  color: gray;
}

.ticket_container .issue_image {
  text-align: center;
}

.ticket_container .issue_image > img {
  width: 100%;
  max-width: 1000px;
  height: 500px;
  object-fit: contain;
}



.heart_poll_container_button_inactive {
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  width: 100px;
  height: 56px;
  text-align: center;
  border: none;
  border-radius: 4px;
  border: solid 1px #ebebeb;
  background-color: #f5f5ff;
  color: #000;
}

.heart_poll_container_button_active {
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  width: 100px;
  height: 56px;
  text-align: center;
  border: none;
  border-radius: 4px;
  border: solid 1px #ebebeb;
  background-color: #1740A1 !important;
  color: #fff !important;
}
