

.editInterpretation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px;
    width: 90%;
    border: 2px solid #d5d0d0;
    border-radius: 8px;
}

.editInterpretation>form>input {
    padding: 0px;
    margin-left: 5px;
    outline: none;
    border: none;
}

.docEditText {
    margin-top: 12px;
    display: flex;
    gap: 5px;
    align-items: center;
}