.docInterpretationCon{
    margin-top: 24px
}

.headingWithCheck {
    display: flex;
    align-self: center;
    gap: 9px;
}

.headingText {
    font-weight: 600;
    font-size: 14px;
    color: #66707E;
}

.docInterpretation {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}


.docInterpretation>:nth-child(1) {
    display: flex;
    gap: 8px;
    align-items: center;
}


.docInterpretation>:nth-child(1)>p {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #222CC9;
}

.docInterpretation>:nth-child(2) {
    display: block;
    position: absolute;
    opacity: 0;
    top: 0px;
    left: 0px;
    height: 20px;
    width: 20px;
}

.addInterpretation {
    transform: scaleX(0);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px;
    opacity: 0;
    height: 0px;
    width: 90%;
    border: 2px solid #B2B7BE;
    border-radius: 8px;
    transform-origin: top left;
    transition-duration: 0.2s;
    -webkit-transform-origin: top left;
    -webkit-transition-duration: 0.2s;
}

.addInterpretation>form>input {
    padding: 0px;
    margin-left: 5px;
    outline: none;
    border: none;
}

.docInterpretation>:nth-child(2):checked~.addInterpretation {
    opacity: 1;
    transform: scaleX(1);
    height: auto;
    margin-top: 24px;
}

.editInterpretation {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 10px;
    width: 90%;
    border: 2px solid #B2B7BE;
    border-radius: 8px;
}

.editInterpretation>form>input {
    padding: 0px;
    margin-left: 5px;
    outline: none;
    border: none;
}


.docEditText {
    margin-top: 12px;
    display: flex;
    gap: 5px;
    align-items: center;
}