@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Inter", sans-serif;
}

.breadcrumbs_container>img {
    filter: saturate(7.5);
    margin: 0px 3px;
}


.breadcrumbs_container {
    height: fit-content;
    align-items: center;
    display: flex;
    gap: 0.694vw;
    font-size: 16px;
    font-weight: 500;
}

.breadcrumbs_container a {
    text-decoration: none;
    outline: none;
}

.nested_nav_links {
    color: #989889;
    cursor: pointer;
    transition: all .1s linear;
}

.nested_nav_links:hover {
    color: black;
    transform: scale(1.03);
    text-shadow: 0px 5px 4px rgb(195, 195, 195);
}

.breadcrumbs_container>div>a {
    font-size: 1em;
    color: #8e8f8f;
    font-weight: normal;
}

.breadcrumbs_container {
    display: flex;
    gap: 0.556vw;
}

.single_link {
    color: #343434;
}

.last_link {
    color: #9d9d9d;
}