@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
*{
  font-family: "Inter", sans-serif;
  }.delete_btn{
    color : #db1c1c;
    }
    
    .activate_btn{
    color: #3b3cea;
    }
    