@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
/* .css-1dkupph-MuiFormControl-root-MuiTextField-root{
    border: 1px solid red !important;
} */
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root{
    /* border: 1px solid #e8e7e7 !important;  */
    /* border-radius: 8px !important;  */
}
.MuiInputBase-input-MuiOutlinedInput-input{
    /* border: 1px solid red !important; */
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    /* border-color: green !important; */
}

.input-border {
    border-color: #e8e7e7 !important;
    border-radius: 8px !important;
}

.inputLabel {
    color: #9d9d9d !important;
    font-family: "Inter", sans-serif !important;
    font-size: 14px !important;
}
.labelAsterisk{
    color: #9d9d9d !important;
}

.helper-text {
    text-transform: initial;
    font-size: 1rem !important;
}