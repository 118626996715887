.mainContainer {
  display: flex;
  flex-direction: column;   
  justify-content: space-between;
  height: 500px;
}
.chatScreen {
    display: flex;
    align-items: start;
    margin-bottom: 20px;
  }

.chatArea {
  display: flex;
  flex-direction: column;
  overflow: scroll;
}
  
  .reportArea {
    display: flex;
    align-items: start;
    margin-top: 20px;
  }
  
  .avatar {
    display:flex;
    align-items: center;
    justify-content:center;
    padding: 5px 9px;
    margin-right: 20px;
    background-color: #E5EEFF;
    border-radius: 50px;
  }

  .textfield {
    font-size: 10px;
    margin-top: 10px;
  }

  .loader {
    width: 100%;
  }