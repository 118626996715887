.react-calendar {
    width: 100% !important;
    max-width: 100%;
    background: white;
    border: none !important;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
  }
  .react-calendar--doubleView {
    width: 700px;
  }
  .react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
  }
  .react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
  }
  .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
  .react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
  }
  .react-calendar button:enabled:hover {
    cursor: pointer;
  }
  .react-calendar__navigation {
    display: flex;
    height: 44px;
    margin-bottom: 1em;
  }
  .react-calendar__navigation button {
    min-width: 44px;
    background: none;
  }
  .react-calendar__navigation button:disabled {
    background-color: #FFFFFF !important;
  }
  .react-calendar__navigation button:enabled:hover,
  .react-calendar__navigation button:enabled:focus {
    background-color: #FFFFFF !important;
  }
  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
  }
  .react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
  }
  .react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    font-weight: bold;
  }
  .react-calendar__month-view__days__day--weekend {
    color: #D10000;
  }
  .react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
  }
  .react-calendar__year-view .react-calendar__tile,
  .react-calendar__decade-view .react-calendar__tile,
  .react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
  }
  .react-calendar__tile {
    max-width: 100%;
    padding: 10px 6.6667px;
    background: none;
    text-align: center;
    line-height: 16px;
  }
  .react-calendar__tile:disabled {
    background-color: #fff;
  }
  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    /* background-color: #E6E6E6; */
    background-color: #FEF3FE !important;
  }
  .react-calendar__tile--now {
    /* background: #FFFF76; */
    /* background-color: #c8faff !important; */
  }
  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus {
    background: #FFFFA9;
    /* background-color: #FEF3FE !important;  */
  }
  .react-calendar__tile--hasActive {
    /* background: #76BAFF; */
    background-color: #FEF3FE !important;
  }
  .react-calendar__tile--hasActive:enabled:hover,
  .react-calendar__tile--hasActive:enabled:focus {
    /* background: #A9D4FF; */
    background-color: #FEF3FE !important;
  }
  .react-calendar__tile--active {
    /* background: #006EDC; */
    background-color: #FEF3FE !important;
    color: white;
  }
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    /* background: #1087FF; */
    background-color: #FEF3FE !important;
  }
  .react-calendar--selectRange .react-calendar__tile--hover {
     /* background-color: #E6E6E6; */
    background-color: #FEF3FE !important;
  }
  .react-calendar__tile--rangeStart {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    background-color: #FEF3FE !important;
    color: white;
   }
   .react-calendar__tile--rangeEnd {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: #FEF3FE !important;
    color: white;
   }
   .calendar-container .react-calendar .react-calendar__navigation  > button:first-child,
   .calendar-container .react-calendar__navigation  > button:nth-child(5){
    display: none !important;
    /* border: 1px solid red !important; */
   }
   .calendar-container .react-calendar__navigation  > button:nth-child(2){
    order: 4;
   }
   .calendar-container .react-calendar__navigation  > button:nth-child(3){
    text-align: left;
    font-weight: bold;
    font-size: 20px !important;
   }
   .calendar-container .react-calendar__navigation  > button:nth-child(4){
    order: 5;
   }
   .calendar-container .react-calendar__navigation  > button:nth-child(5){
    border: 1px solid black;
   }
   .text-center{
    display: none !important;
   }
   .react-calendar__month-view__weekdays__weekday{
    color: rgba(52, 52, 52, 0.85) !important;
   }
   .react-calendar__month-view__weekdays__weekday abbr{
      text-decoration: none !important;
      color: rgba(52, 52, 52, 0.85) !important;
   }
   /* .react-calendar__month-view__days button:nth-child(10){
    position: relative;
   }
   .react-calendar__month-view__days button:nth-child(10):before{
    content: "";
    position: absolute ;
    inset: 15%;
    border-image-source: linear-gradient(134deg, rgba(0, 0, 0, 0.1) 24%, #000 74%);
    border-image-slice: 1;
    border-width: 2px;
    border-style: solid;
     border-radius: 50% !important;
   } */
   /* .clicked_day{
    position: relative;
   }
   .clicked_day::before{
    content: "";
    position: absolute;
    inset: -30% -55%;
    border-image-source: linear-gradient(134deg, rgba(0, 0, 0, 0.1) 24%, #000 74%);
    border-image-slice: 1;
    border-width: 2px;
    border-style: solid;
    border-radius: 50% !important;
   } */
   /* .react-calendar__month-view__days button:nth-child(7), .react-calendar__month-view__days button:nth-child(26){
    position: relative;
   }
   .react-calendar__month-view__days button:nth-child(7)::before, .react-calendar__month-view__days button:nth-child(26)::before{
    content: "";
    position: absolute;
    bottom : 9%;
    border: 1px solid white;
    border-radius: 50%;
    border-image-slice: 1;
    border: 2px solid black !important;
   } */