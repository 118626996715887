
.report_btn {
    padding: 5px 0;
    width: 90px;
    border: none;
    background: none;
    background-color: #3b3cea;
    font-size: 8px;
    border-radius: 5vh;
    color: #fff;
    display: flex;
    justify-content: center;
  }
  
  .screened_btn {
    padding: 5px 0;
    width: 90px;
    border: 1px solid #e7e7e7;
    background: none;
    font-size: 8px;
    color: #000;
    border-radius: 5vh;
    display: flex;
    justify-content: center;
  
  }