.dashboard_header {
  font-size: 14px;
  line-height: 1.29;
  color: #303030;
}
.time {
  font-weight: 600;
  color: #222cc9;
}
.dashboard_greetings {
  font-size: 20px;
  font-weight: 600;
  font-style: italic;
  line-height: 1.2;
  color: #343434;
  padding-top: 1vh;
  
}

.dashboard_layout {
  width: 100%;
}
.task_tracker {
  border-radius: 8px;
  box-shadow: 0 1px 50px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px #e8e7e7;
  background-color: #fff;
  margin: 2vh 0;
  padding: 4vh 1vw;
  display: flex;
  justify-content: space-evenly;
}
.link_text {
  font-size: 0.7em;
  font-weight: bold;
  line-height: 1.6;
  text-align: center;
  color: #3b3cea;
}
.completed {
  font-size: 0.8em;
  font-weight: 600;
  line-height: 1.33;
  text-align: center;
  color: #343434;
  padding: 1vh 0;
}
.label {
  font-size: 0.8em;
  line-height: 1.33;
  text-align: center;
  color: #343434;
}
.your_school {
  font-size: 1.1em;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #343434;
  padding-bottom: 1vh;
}
.item_image {
  width: 16px;
  height: 16px;
  padding-right: 0.5vw;
}
.lists {
  display: flex;
  align-items: center;
  padding: 0.5vh 0;
}
.school_list {
  font-size: 0.7em;
  font-weight: 600;
  line-height: 1.33;
  color: #9d9d9d;
}
.card_layout {
  display: grid;
  grid-template-columns: 2fr 1fr;
}
.card_left {
  display: grid;
  grid-template-rows: repeat(1fr);
}
.card_left_top {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.background_image {
  border-radius: 6px;
  background-color: #f8debd;
  width: max-content;
  padding: 1.5vh 1vw;
}

.card {
  border-radius: 8px;
  box-shadow: 0 1px 50px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px #e8e7e7;
  background-color: #fff;
  padding: 3vh 2vw;
  margin-bottom: 2vh;
  margin-right: 1vw;
}
.image_header {
  display: flex;
  align-items: center;
}
.background_text {
  font-size: 0.9em;
  color: #000;
  padding-left: 1.5vw;
  width: 5vw;
}
.student_counter {
  font-size: 1.5em;
  line-height: 1.13;
  color: #272927;
  padding: 2.4vh 0;
}
.student_link {
  font-size: 0.8em;
  line-height: 1.33;
  color: #e79b38;
}
.screening_counter {
  display: flex;
  padding: 2vh 0;
  align-items: center;
}
.screening_text {
  font-size: 1.1em;
  line-height: 2;
  color: #818181;
}
.screening_num {
  font-size: 1.5em;
  color: #272927;
  padding-right: 1vw;
}
.screening_link {
  font-size: 0.7em;
  line-height: 1.33;
  color: #9d9d9d;
}
.card_calendar {
  border-radius: 8px;
  box-shadow: 0 1px 50px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px #e8e7e7;
  background-color: #fff;
  padding: 3vh 2vw;
  margin-bottom: 2vh;
}
.background_text_ed {
  font-size: 0.9em;
  color: #000;
  padding-left: 1.5vw;
}
.background_text_calendar {
  font-size: 0.9em;
  color: #000;
  padding-left: 1.5vw;
  width: 7vw;
}
.calendar_view{
    padding: 2vh 0;
}

.class_section{
    font-size: 0.8em;
    line-height: 1.14;
    color: #000;
    padding-top: 1vh;
}
.class_time{
    font-size: 0.7em;
  line-height: 1.17;
  color: #9d9d9d;
  padding: 1vh 0;
  padding-bottom: 4vh;
}




