.physical_details {
  display: flex;
}
.physical_head {
  padding-right: 7vw;
}
.text_header {
  font-size: 12px;
  line-height: 1;
  color: #6b6c6f;
}
.text_subhead {
  font-size: 14px;
  line-height: 1.29;
  color: #000;
  padding-top: 1vh;
}
.exam_text {
  font-size: 18px;
  font-weight: bold;
  line-height: 0.89;
  color: #343434;
  padding-top: 5vh;
}
