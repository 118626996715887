@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

* {
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
  box-sizing: border-box;
}

.main_div {
  width: 100%;
}

.student_layout {
  display: flex;
  justify-content: space-between;
}

.student_filters {
  margin: 28px 0 0 0;
  /* border: 1px solid red; */
  display: flex;
}

.student_filters>div {
  margin: 0 1em 0 0;
  border-radius: 8px;
}

.tabBtn_container {
  display: flex;
  gap: 16px;
}

.sharetypeform_btn {
  background: none;
  border: none;
  border-radius: 8px;
  padding: 8px;
  font-weight: 600;
  display: flex;
  gap: 4px;
  cursor: pointer;
  background-color: #1740A1;
}

.sharetypeform_btn>img {
  height: 24px;
  width: 24px;
}

.sharetypeform_btn>span {
  padding: 3px;
  color: #fff;
}

.show_text {
  padding: 8px 0 0 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #66707E;
}



/* Table Layout */

/* Table Header */
.student_table_header {
  display: grid;
  margin: 32px 0 0 0;
  padding: 0 0 0 16px;
  /* grid-template-columns: 0.6fr 0.2fr 0.3fr 0.4fr 0.4fr 0.4fr 0.3fr 0.2fr; */
  grid-template-columns: 1fr 0.9fr 0.5fr 0.3fr 0.3fr;
  gap: 20px;
}

.student_table_header>div {
  /* border: 1px solid green; */
  font-size: 12px;
  font-weight: 600;
  color: #343434;
  text-align: left;
}

/* Student Body */
.student_column_container {
  display: flex;
  flex-direction: column;
  /* border : 1px solid black; */
  margin: 16px 0 0 0;
}

.student_column_container>div:nth-child(n+2) {
  margin: 12px 0 0 0;
}


.pagination_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0 0 0;
  padding: 0 0 0 5px;
}

.page_item {
  font-size: 12px;
}

.pagination {
  display: flex;
  align-items: center;
}

.pagination>div:first-child {
  position: relative;
}

.pagination>div:first-child>img:nth-child(2) {
  position: absolute;
  top: 25%;
  left: 32%;
}



.pagination>div:nth-child(2) {
  position: relative;
  font-size: 14px;
  font-weight: bold;
}

.pagination>div:nth-child(2)>span {
  position: absolute;
  top: 25%;
  left: 32%;
}


.pagination>div:nth-child(3) {
  padding: 7px 10px;
  font-size: 14px;
  font-weight: bold;
}

.pagination>div:nth-child(3)>span:first-child {
  color: #e7e7e7;
  padding: 0 12px 0 0;
}

.pagination>div:nth-child(4) {
  position: relative;
}

.pagination>div:nth-child(4)>img:nth-child(2) {
  position: absolute;
  top: 25%;
  left: 32%;
}