.slideshow_container{
    position: relative;
    border-radius: 10px;
}
.image_slides{
    height: 100%;
    width: 100%;
    border-radius: 10px;
}

.play_btn{
    padding: 3px 3px 0px 3px;
    width: 30px;
    height: 30px;
    background-color: white;
    position: absolute;
    border: none;
    top : 45%;
    left: 45%;
    border-radius: 50%;
}



