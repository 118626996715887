.vbgb {
    background-color: #DFF4EA;
    display: flex;
    gap: 7px;
    padding: 18px 12px;
    width: fit-content;
    border-radius: 4px;
    cursor: pointer;
}

.vbgb:hover {
    background-color: #c7ecda;
}

.vbgb>p {
    color: #149457;
    font-weight: 600;
    font-size: 16px;
}

.vbrb {
    cursor: pointer;
    background-color: #FAECEC;
    display: flex;
    gap: 7px;
    padding: 18px 12px;
    width: fit-content;
    border-radius: 4px;
}

.vbrb:hover {
    background-color: #f7dbdb;

}

.vbrb>p {
    color: #D95A59;
    font-weight: 600;
    font-size: 16px;
}


.vbyb {
    cursor: pointer;
    background-color: #f6f0e4;
    display: flex;
    gap: 7px;
    padding: 18px 12px;
    width: fit-content;
    border-radius: 4px;
}

.vbyb:hover {
    background-color: #f2e5ce;

}

.vbyb>p {
    color: #E09F26;
    font-weight: 600;
    font-size: 16px;
}