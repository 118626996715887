/* Default Card CSS */

.infocard_parent{
    position: relative;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    width: 23vw;
    background-color: #EDFFEF;
    padding: 16px;
    z-index: 10;
}
.infocard_color_violet{
    background-color: #F4F2FF !important;
}
.titleHeader{
    display: flex;
    justify-content: space-between;
}
.titleHeader button{
    background: none;
    border: none;
    cursor: pointer;
}
.infocard_title{
    font-size: 12px;
    color: #001028;
}
.entity_number, .totalText{
    margin: 8px 0 0 0;
    color: #60607E;
}
.entity_number{
    font-size: 14px;
}
.totalText{
    font-size: 12px;
}
.live_status{
    margin: 10px 0 0 0;
    display: flex;
    gap: 8px;
    font-size: 12px;
    
}
.live_status > p{
    color: #149457;
}

/* Plus Button Model CSS Starts */


.infocard_modal_container{
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    width: 23vw;
    background-color: #EDFFEF;
    padding: 16px;
    z-index: 1;
    max-height: 83vh;
    overflow-y: scroll;
}
.infocard_modal_color_violet{
    background-color: #F4F2FF !important;
}
.infocard_modal_color_pink{
    background-color: #FBF0F3 !important;
}

.infocard_modal_container > div:nth-child(n+3){
    border-bottom: 1.5px solid #BAE7B6;
    padding-bottom: 16px;
}
.infocard_modal_description_violet_border{
    border-bottom: 1.5px solid #D8D3F5 !important;
}

.infocard_modal_container > div:last-child{
    border-bottom: none !important;
}

.infocard_modal_title_container{
    display: flex;
    justify-content: space-between;
}
.infocard_modal_title_container button{
    background: none;
    border: none;
}

.infocard_modal_title{
    font-size: 14px;
    color: #001028;
}
.infocard_modal_description_container{
    margin: 32px 0 0 0;
    display: flex;
    justify-content: space-between;
}

.infocard_modal_description_progress_info_container{
    display: flex;
    gap: 16px;
}
.infocard_progress_bar svg{
    height: 48px;
    width: 48px;
}

.day_desc{
    font-size: 12px;
    color: #72B97A;
}
.entity_name, .count_outof, .total_count{
    margin: 8px 0 0 0;
}
.entity_name{
    margin: 8px 0 0 0;
    font-size: 14px;
    font-weight: 600;
}
.count_outof_container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-top: 8px;
}

.count_outof{
    margin: 8px 0 0 0;
    font-size: 14px;
    color: #72B97A;
    font-weight: 700;
}

.count_missing{
    margin: 8px 0 0 0;
    font-size: 14px;
    color: #b97272;
    font-weight: 700;
}
.count_outof span {
    font-size: 14px;
    color: #000;
    font-weight: 500;
}
.total_count{
    font-size: 12px;
    color: #001028;
}

.check_progress{
   font-size: 12px;
   font-weight: bold;
   padding: 50px 0 0 0;
   color: #3B3CEA !important;
}

.test_inconclusive{

}

.heath_certificate_wrapper{
    display: flex;
    gap: 12px;
}
.health_certificate_download_wrapper{
    display: flex;
    gap: 8px;
}
.health_certificate{
    height: 68px;
    width: 96px;
    border-radius: 4px;
    background-color: #fff;
}
.health_certificate_download_wrapper > p{
    font-size: 14px;
    font-weight: 600;
    color: #3B3CEA;
}