@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
*{
    margin: 0;
    padding: 0;
    font-family: 'Inter', sans-serif;
    box-sizing: border-box;
  }
  .nurse_student_layout {
    display: flex;
    justify-content: space-between; 
   }
   .log_screening_container{
    display: flex;
    gap: 16px;
   }
   .log_screening_btn{
    background : none;
    border: none;
    border-radius: 8px;
    width: 160px;
    height: 44px;
    padding: 10px 8px;
    font-weight: 600;
    display: flex;
    gap: 30px;
    cursor: pointer;
    background-color: #1740A1;
  }
  .log_screening_btn > span {
    padding: 2px 0 0 0;
    font-size: 16px;
    color: #fff;
  }

  .student_info_container{
    margin: 32px 0 0 0;
    display: flex;
    gap: 7vw;
    background: #FFFFFF;
    border: 1px solid #E8E7E7;
    box-shadow: 0px 1px 50px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    padding: 16px 50px 16px 24px;
  }
  .avatar_container{
    display: flex;
    gap: 12px;
  }

  .avatar_container > div:nth-child(2){
    display: flex;
    align-items: center;
  }
  .avatar_container > div:nth-child(2) > p{
    font-weight: 600;
  }
  .student_description_container{
    display: flex;
    gap: 4vw;
    /* justify-content: space-between; */
    /* border: 1px solid red; */
  }
  .description_boxes > p:first-child{
    color: #6B6C6F;
    font-size: 12px;
    font-weight: 400;
  }
  .description_boxes > p:nth-child(2){
    margin: 8px 0 0 0;
    font-size: 14px;
    font-weight: 400;
  }
  .infirmary_info_container{
    margin: 24px 0 0 0;
    background: #FFFFFF;
    border: 1px solid #E8E7E7;
    box-shadow: 0px 1px 50px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    padding: 24px;
  }
  .infirmary_info_header{
    font-size: 18px;
    font-weight: 600;
    color: #343434;
  }
  .infirmary_info_container > div:nth-child(n+2){
    border-bottom: 1px solid #E8E7E7;
  }
  .infirmary_card{
    margin: 32px 0 0 0;
    /* border: 1px solid red; */
    padding-bottom: 24px;
  }
  .infirmary_card_description{
    display: flex;
    gap: 5vw;
  }

  .infirmary_card_description > div > p:first-child{
    font-size: 14px;
    font-weight: 600;
    color: #66707E;
  }

  .infirmary_card_description > div > p:nth-child(2){
    margin: 12px 0 0 0;
    font-weight: 600;
    color: #001028;
  }

  .vitals_container_main{
    margin: 32px 0 0 0;
    /* border: 1px solid red; */
  }
  .vitals_header{
    font-size : 14px;
    font-weight: 600;
    color: #66707E;
  }
  .vitals_box_container{
    display: flex;
    gap: 4vw;
    padding: 0 50px;
  }

  .vitals_box{
    margin: 12px 0 0 0;
    /* border: 1px solid red; */
    text-align: center;
  }
  .vitals_box > img{
    height: 28px;
    width: 28px;
  }
  .vitals_box > p{
    margin: 12px 0 0 0;
    color: #008C1E;
    font-weight: 700;
    font-size: 18px;
  }
  .vitals_box > p > span{
    font-size: 14px;
    color: #008C1E;
    font-weight: 700
  }

  .vitals_box > div{
    margin: 5px 0 0 0;
    display: flex;
    gap: 4px;
  }
  .vitals_box > div > p{
    color: #001028;
    font-size: 14px;
    font-weight: 400;
  }
  sup{
    color: #008C1E;
  }
  .bmi_index{
    height: 27px;
    width: 55px;
  }
  .historyHover {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background: transparent;
    
    &:hover {
      background: rgba(#f6f6f6, .3);
  
      &>.popOnHover {
        opacity: 1;
        width: 200px;
        height: 113px;
      }
    }
  }
  

  .popOnHover {
    white-space: nowrap;
    overflow: hidden;
    opacity: 0;
    width: 0px;
    height: 0px;
    position: absolute;
    z-index: 10;
    top: 15px;
    right: 5px;
    box-shadow: 0px 12px 34px rgba(0, 0, 0, 0.12317);
    background-color: white;
    padding: 16px;
    border-radius: 16px;
    text-align: start;
    transition: all .4s ease;
  }
  
  .popOnHover>:nth-child(1) {
    font-size: 14px;
    font-weight: 600;
    color: #66707E;
    line-height: 20px;
  }
  
  .popOnHover>:nth-child(2) {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #B2B7BE;
    margin-top: 4px;
  }
  
  .popOnHover>:nth-child(3) {
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #B2B7BE;
    margin-top: 16px;
  }
  
  .popOnHover>:nth-child(4) {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #001028;
    margin-top: 6px;
  }
  .problem_text{
    margin: 32px 0 0 0;
    font-size : 14px;
    font-weight: 600;
    color: #66707E;
  }
  .problem_answer_text{
    margin: 12px 0 0 0;
    color: #001028;
    font-weight: 600;
  }

  .doctor_diagnosis_container{
    margin: 32px 0 0 0;
    display: flex;
    max-width: 46.528vw;
    gap: 13px;
    background: #FFFFFF;
    border: 1px solid #E8E7E7;
    border-radius: 12px;
    padding: 10px 18px;
  }
  .doctor_diagnosis_container > div:first-child{
    display: flex;
    align-items: center;
  }
  .doctor_diagnosis_container > div:nth-child(2) > p:first-child{
    color: #66707E;
    font-size: 10px;
    font-weight: 400;
  }

  .doctor_diagnosis_container > div:nth-child(2) > p:nth-child(2){
    margin: 4px 0 0 0;
    color: #001028;
    font-size: 12px;
    font-weight: 500;
  }

  .centerAdornment{
    text-align: left;
  }