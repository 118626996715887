* {
  font-family: "Inter", sans-serif;
}

.container_header {
  margin: 32px 0 0 0;
  display: flex;
  gap: 24px;
}
.container_header > div > p:first-child {
  font-family: "Inter";
  width: 240px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #b2b7be;
}

.container_header > div > p:nth-child(2) {
  margin: 8px 0 0 0;
  font-style: normal;
  width: 240px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #001028;
}

.progress_container {
  margin: 32px 0 0 0;
  display: flex;
  gap: 24px;
}

.progress_container:nth-child(n+2){
    margin: 24px 0 0 0 !important;
}

.progress_container > div > p:first-child {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  width: 120x;
  color: #b2b7be;
}

.progress_container > div > p:nth-child(2) {
  margin: 8px 0 0 0;
  width: 120px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #001028;
}

.progress_container > div:first-child > p:nth-child(2) {
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 19px !important;
  color: #3b3cea !important;
}

.okay_btn {
  margin: 48px 0 0 0;
  background: none;
  border: none;
  width: 176px;
  height: 56px;
  background: #1740a1;
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #fff;
  cursor: pointer;
}
