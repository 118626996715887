*{
    font-family: "Inter", sans-serif;
}
.nurse_student_layout {
    display: flex;
    justify-content: space-between; 
   }
   .log_screening_container{
    display: flex;
    gap: 16px;
   }
   .log_screening_btn{
    background : none;
    border: none;
    border-radius: 8px;
    width: 160px;
    height: 44px;
    padding: 10px 8px;
    font-weight: 600;
    display: flex;
    gap: 30px;
    cursor: pointer;
    background-color: #1740A1;
  }
  .log_screening_btn > span {
    padding: 2px 0 0 0;
    font-size: 16px;
    color: #fff;
  }

  .student_info_container{
    margin: 32px 0 0 0;
    display: flex;
    gap: 7vw;
    background: #FFFFFF;
    border: 1px solid #E8E7E7;
    box-shadow: 0px 1px 50px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    padding: 16px 50px 16px 24px;
  }
  .avatar_container{
    display: flex;
    gap: 12px;
  }

  .avatar_container > div:nth-child(2){
    display: flex;
    align-items: center;
  }
  .avatar_container > div:nth-child(2) > p{
    font-weight: 600;
  }
  .student_description_container{
    display: flex;
    gap: 4vw;
    /* justify-content: space-between; */
    /* border: 1px solid red; */
  }
  .description_boxes > p:first-child{
    color: #6B6C6F;
    font-size: 12px;
    font-weight: 400;
  }
  .description_boxes > p:nth-child(2){
    margin: 8px 0 0 0;
    font-size: 14px;
    font-weight: 400;
  }

  .addlog_container{
    margin: 24px 0 0 0;
    background: #FFFFFF;
    border: 1px solid #E8E7E7;
    box-shadow: 0px 1px 50px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    padding: 24px;
  }
  .addlog_top_container{
    display: flex;
    justify-content: space-between;
  }
  .addlog_top_container > div{
    /* border: 1px solid red; */
  }

  .addlog_top_left{

  }

  .addlog_header{
    font-size: 18px;
    font-weight: 600;
    color: #343434;
  }
  .addlog_header_subtext{
    margin: 12px 0 0 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: #66707E;
  }

  .checkbox_container_top_header{
    font-size: 14px;
    font-weight: 600;
    color: #66707E;
  }
  .checkbox_inner{
    margin: 14px 0 0 0;
  }
  .checkbox_container_bottom{
    margin: 10px 0 0 0;
  }
  .input_dropdown{
    margin: 28px 0 0 0;
  }
  .vital_signs_container{
    margin: 32px 0 0 0;
  }
  .vital_signs_header{
    color: #66707E;
    font-size: 14px;
    font-weight: 600;
  }
  .vital_signs_input_top_container{
    margin: 16px 0 0 0;
    display: flex;
    align-items: center;
    gap: 1.667vw;
  }
  .add_vital_container{
    display: flex;
    gap: 8px;
  }
  .add_vital_container > p {
    color: #222cc9;
    font-size: 12px;
  }
  .vital_signs_input_mid_container{
    margin: 32px 0 0 0;
  }
  .decision_btn_container{
    margin: 135px 0 0 0;
    display: flex;
    flex-direction: row-reverse;
    gap: 32px;
}
.decision_btn_container > button{
    background: none;
    border: none;
    padding: 16px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 8px;
    height: 56px;
    width: 176px;
    cursor: pointer;
}
.decision_btn_container > button:first-child{
    color: #fff;
    background-color: #1740A1;
}

.decision_btn_container > button:nth-child(2){
    border: 1px solid #1740A1;
    color: #1740A1;
}