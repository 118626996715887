.error_layout {
  text-align: center;
  background-color: #fffcf8;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 7vh 0;
}
.divider {
  width: 10vw;
  border: solid 0.5px #000;
  margin: 2vh 0;
}
.error_header {
  font-family: Montserrat;
  font-size: 26px;
  font-weight: bold;
  text-align: center;
  color: #000;
}
.error_subtext {
  font-family: Montserrat;
  font-size: 1.3em;
  line-height: 1.36;
  text-align: center;
  color: #000;
  padding: 3vh 0;
  width: 55vw;
}

.error_return {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 500;
  line-height: 0.6;
  text-align: center;
  color: #3b3cea;
}
.error_pannel {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border: solid 6px #000;
  height: 27vw;
  border-radius: 25px;
  text-align: center;
  transform: rotate(45deg);
  width: 17vw;
  margin: 15vh 0;
  padding: 5vh;
}
.error_box {
  transform: rotate(-45deg);
}
.error_message {
  vertical-align: middle;
  font-size: 0.8em;
  font-weight: bold;
  text-align: center;
  color: #000;
}

.error_warning {
  font-size: 0.9em;
  text-align: center;
  color: #000;
  width: 15vw;
  padding-top: 2vh;
}
.policy {
  font-size: 16px;
  line-height: 1.25;
  text-align: center;
  color: #000;
}
