input[type = "date"]{
    color: #9d9d9d;
    height: 40px;
    margin: 2px 0 0 0;
    border-radius : 8px !important;
}
.filledInput{
  color: #000 !important;
  border-radius : 8px !important;
}
.clearedInput{
  color: #9d9d9d !important;
  border-radius : 8px !important;
}


input[type="date"]::-webkit-calendar-picker-indicator {
    cursor: pointer;
    opacity: 0.6;
    filter: brightness(35%);
  }
  
  input[type="date"]::-webkit-calendar-picker-indicator:hover {
    opacity: 1
  }

