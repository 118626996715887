.otp_container {
    display: flex;
    width: 100%;
    height: 60px;
    margin-top : 40px
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.otp-input-fields {
    margin: auto;
    height: 100%;
    /* max-width: 400px; */
    width: auto;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    /* padding: 0 40px; */
    width: 100%;
}

.otp-form {
    width: 100%;
    height: 100%;
}

.otp-input-fields input {
    width: 100%;
    max-width: 50px;
    font-size: larger;
    background-color: white;
    border-radius: 4px;
    border: 1px solid white;
    ;
    outline: none;
    font-size: 24px;
    padding: 5px;
}