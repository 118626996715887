* {
    font-family: "Inter", sans-serif;
}

.main_div {
    width: 100%;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header_text {
    font-weight: 500;
    color: #000;
}

.back {
    font-weight: 500;
    color: #9d9d9d;
}

.container {
    border-radius: 16px;
    box-shadow: 0 1px 50px 0 rgba(0, 0, 0, 0.08);
    border: solid 1px #e8e7e7;
    background-color: #fff;
    /* padding: 3vh 2vw; */
    margin: 3vh 0;
    padding: 0 0 0 24px;
}

.student_details_container {
    display: flex;
    /* border: 1px solid red; */
    padding: 24px 24px 24px 0;
    justify-content: space-between;
}

.student_details {
    display: flex;
    /* border : 1px solid red; */
    gap: 16px;
}

.student_details>div:first-child>img {
    height: 64px;
    width: 64px;
}

.student_details_information {
    /* border: 1px solid red; */
}

.no_upload_logo {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 64px;
    width: 64px;
    background-color: #F9F9F9;
    border: 2px solid #E8E7E7;
    border-radius: 50%;
}

.no_upload_logo>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.student_details_information_name {
    /* border: 1px solid red; */
    font-size: 18px;
    font-weight: bold;
    color: #343434;
}

.student_details_information_general_info {
    margin: 11px 0 0 0;
    display: flex;
    /* border: 1px solid red; */
    justify-content: space-between;
    gap: 7px;
}

.student_details_information_general_info>p:nth-child(odd) {
    color: #343434;
    font-size: 14px;
}

.student_details_information_general_info>p:nth-child(even) {
    /* border: 1px solid red; */
    height: 2px;
    width: 2px;
    color: #9d9d9d;
    border-radius: 50%;

}

.download_report_btn {
    display: flex;
    height: 32px;
    gap: 16px;
}


.download_report_btn>button {
    display: flex;
    border: none;
    background-color: #303030;
    ;
    color: #fff;
    border-radius: 5vh;
    font-size: 12px;
    padding: 8px;
    cursor: pointer;
    justify-content: space-between;
}

.download_report_btn>button>span {
    font-size: 12px;
    color: #fff;
    margin: 0 0 0 0.5em;
}

.screening_container {
    margin: 12px 0 0 0;
    border-bottom: 2px solid #ececec;
    padding: 10px 0 0 24px;
    display: flex;
    gap: 28px;
}

.screening_container>div:nth-child(1) {
    font-size: 14px;
    font-weight: bold;
    color: #343434;
}

.screening_container>div:nth-child(2) {
    font-size: 14px;
    font-weight: bold;
    color: #696969;
    font-weight: normal;
}

.screening_active_bar {
    margin: 1px 0 0 0;
    width: 2.8vw;
    border-bottom: 2px solid #343434;

}

.physical_report_header {
    display: flex;
    /* padding: 0 0 0 24px; */

}

.physical_report_header>div {
    width: 200px;
    font-size: 12px;
    color: #6b6c6f;
}

.physical_report_header_data {
    display: flex;
    margin: 4px 0 0 0;
    /* padding: 0 0 0 24px; */
    /* border: 1px solid red; */
}

.physical_report_header_data>div {
    width: 200px;
    font-size: 14px;
    color: #000;
}

.total_ops_student_container {
    margin: 24px 0 0 0;
}

.device_mapping_text {
    margin: 32px 0 0 0;
    color: #343434;
    font-size: 14px;
    font-weight: 600;
    /* padding: 0 0 0 24px; */
}

.organs_container {
    display: flex;
    margin: 12px 0 0 0;
    border: 1px solid #343434;
    width: fit-content;
    border-radius: 8px;
    padding: 4px;
    max-width: 75vw;
    justify-content: space-between;
    overflow-x: auto;
}

.organs_container>div {
    border-radius: 8px;
    color: #343434;
    font-size: 14px;
    padding: 6px 10px;
    cursor: pointer;
    white-space: nowrap;
}



.organs_container::-webkit-scrollbar:horizontal {
    height: 2px;
}

.organs_container_elements_active {
    background-color: #343434 !important;
    color: #fff !important;
}

.class_mapping_text {
    color: #6b6c6f;
    margin: 32px 0 0 0;
    font-size: 12px;
}

.select_class_container {
    display: flex;
    margin: 24px 0 0 0;
    gap: 26px;
    font-size: 14px;
}

.select_class_container>div:nth-child(1),
.select_class_container>div:nth-child(3) {
    width: 120px;
    padding: 10px 0;
}


.select_class_container>div:nth-child(even) {
    width: 200px;
    height: 40px;
}

.select_class_container>div:nth-child(even)>select {
    width: 100%;
    padding: 0 10px;
    border-radius: 4px;
    border: none;
    height: 100%;
    font-size: 12px !important;
    background-color: #f5f5ff;
}

.select_class_container>div:nth-child(5)>p:first-child {
    color: #6b6c6f;
    font-size: 12px;
}

.select_class_container>div:nth-child(5)>p:nth-child(2) {
    color: #3b3cea;
    font-size: 14px;
    font-weight: 600;
    margin: 5px 0 0 0;
}

.schoolCode {
    font-size: 14px;
}

.schoolEvent {
    color: #3b3cea !important;
    font-weight: bold;
}

.totalStudents_text {
    color: #3b3cea !important;
    font-weight: bold;
}

.buttons {
    margin: 48px 0 0 0;
    display: flex;
    padding: 24px;
    justify-content: flex-end;
}

.button_with_border {
    font-size: 0.9em;
    line-height: 1.25;
    width: 150px;
    text-align: center;
    color: #1740a1;
    padding: 0.7rem 0.2rem;
    border-radius: 8px;
    border: solid 1px #1740a1;
    background-color: #fff;
    cursor: pointer;
}

.button_filled {
    border: none;
    width: 150px;
    font-size: 0.9em;
    border-radius: 8px;
    background-color: #1740A1;
    line-height: 1.25;
    padding: 0.7rem 0.2rem;
    text-align: center;
    color: #fff;
}

.button_filled:disabled {
    background-color: #ADADAD !important;
    color: #fff;
}

.mappedClass {
    display: flex;
    gap: 1rem;
    align-items: center;
    padding-bottom: 10px;
}

.mappedClass p {
    display: flex;
    gap: 5px;
    align-items: center;
}

.mappedClass div {
    background: #F5F5FF;
    padding: 12px;
    border-radius: 10px;
}