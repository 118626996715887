.report_details {
  border-radius: 8px;
  box-shadow: 0 1px 50px 0 rgba(0, 0, 0, 0.08);
  border: solid 1px #e8e7e7;
  background-color: #fff;
  padding: 2vh 0vw;
}
.toggle_screens {
  display: flex;
  padding: 0vh 2vw;
  padding-top: 4vh;
  column-gap: 2rem;
}
.toggle_text {
  font-size: 14px;
  line-height: 1.29;
  text-align: center;
  color: #696969;
}
.toggle_underline {
  border-bottom: #343434 solid;
  width: 5vw;
  padding-top: 2vh;
}
.screening {
  cursor: pointer;
}
.reports {
  padding: 4vh 2vw;
}
