.formContainer {
    border: 1px solid #B2B7BE;
    background: #FFFFFF;
    border-radius: 16px;
    border: 1px;
    padding: 20px;
    font-family: Inter;
}

.topForm {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
}

.topForm>div>h1 {
    font-size: 18px;
    font-weight: 600;
    line-height: 16px;
    text-align: left;
    color: #343434;
}

.topForm>div>p {
    padding: 10px 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #66707E;
}

.topForm>div :nth-child(3) {
    display: flex;
    gap: 10px;
    padding: 20px 0;
}

.checkContainer {
    display: flex;
    flex-direction: column;
}

.checkContainer {
    display: flex;
    flex-direction: column;
}

.checkContainer FormLabel {
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: -0.00009999999747378752px;
    text-align: left;

}

/* .topForm :nth-child(1)>div {
    display: flex;
    flex-direction: column;
    gap: 20px;
} */

.formContainer h1 {
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: -0.00009999999747378752px;
    text-align: left;
    color: #66707E;
    padding: 20px 0 10px 0;
}


.buttons {
    margin: 48px 0 0 0;
    display: flex;
    justify-content: flex-end;
}

.button_with_border {
    font-size: 0.9em;
    font-weight: 600;
    line-height: 1.25;
    width: 12.2vw;
    text-align: center;
    color: #1740A1;
    padding: 1.3vh 2.5vw;
    border-radius: 8px;
    border: solid 1px #1740A1;
    background-color: #fff;
    margin-right: 2vw;
    cursor: pointer;
}

.add_log {
    border: none;
    font-size: 0.9em;
    width: 12.2vw;
    border-radius: 8px;
    background-color: #1740A1;
    font-weight: 600;
    line-height: 1.25;
    padding: 1.3vh 1vw;
    text-align: center;
    color: #fff;
    cursor: pointer;
}

.add_log:disabled {
    background-color: #ADADAD !important;
    color: #fff;
}

.TopNav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0 30px 0;
}

.buttonNav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
}

.button_filled {
    border: none;
    width: 12.2vw;
    border-radius: 8px;
    background-color: #1740A1;
    padding: 1.3vh 1vw;
    cursor: pointer;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.button_filled>:first-child {
    fill: #1740A1;
    background-color: #fff;
    border-radius: 999px;
}

.button_filled:last-of-type>:first-child {
    padding: 3px;

}

.button_filled>:last-child {
    color: #fff;
}