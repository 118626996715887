@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
*{
  font-family: "Inter", sans-serif;
}
.select-container {
  margin: 18px 0 0 0;
  padding: 20px;
  width: 23vw;
  height: 40px;
  border: 1px solid #e8e7e7;
  border-radius: 8px;
  position: relative;
}

.label-amenities-disabled {
  position: absolute;
  top: 25%;
  font-size: 14px;
  color: #9d9d9d;
  transition: 300ms all;
}
.label-amenities-enabled {
  font-size: 12px;
  position: absolute;
  color: #9d9d9d;
  left: 5%;
  top: 2%;
  transition: 300ms ease;
}
.selected-amenities-disabled {
  display: none;
}
.selected-amenities-enabled {
  position: absolute;
  display: block;
  font-size: 14px;
  left: 5%;
  top: 40%;
  color: #3b3cea;
}

.select-container > img {
  position: absolute;
  right: 5%;
  bottom: 30%;
}
.dropdown-list-disabled {
  display: none;
}
.dropdown-list-enabled {
  position: absolute;
  top: 6.5vh;
  left: 0%;
  width: 23vw;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  z-index: 9999;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  overflow: scroll;
}
.dropdown-list-enabled > div {
  padding: 10px ;
}
.dropdown-list-enabled > div:hover {
  background-color: #000;
  color: #fff;
}