.forget_password_page {
  font-family: "Inter", sans-serif;
}
.extra_settings {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.css-ptiqhd-MuiSvgIcon-root {
  width: 12px !important;
  height: 12px !important;
}
.forgot_password {
  font-size: 0.65rem;
  color: #222cc9;
  cursor: pointer;
}
.submit_button {
  background-color: #222cc9;
  border-radius: 8px;
  border: none;
  height: 6vh;
  width: -webkit-fill-available;
  color: #fff;
  text-align: center;
  line-height: 1.25;
  font-family: Montserrat;
  font-size: 0.9em;
  font-weight: 600;
  margin: 4.5vh 0;
  cursor: pointer;
}
.error_text {
  margin: 14px 0 0 0;
  font-size: 10px;
  line-height: 1.2;
  color: #d20909;
}
