.ProfileHeader {
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 6rem;
    border: 1px solid #E8E7E7;
    box-shadow: 0px 1px 50px 0px #00000014;
    border-radius: 1rem;

}

.ProfileHeader>div {
    display: flex;
    align-items: center;
    gap: 10px;


}

.ProfileHeader>div>h1 {
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: #343434;


}

.ProfileHeader>section {
    display: flex;
    gap: 2rem;
}

.ProfileHeader section div {
    display: flex;
    gap: 4px;
    flex-direction: column;
}

.ProfileHeader section div h1 {
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0px;
    text-align: left;
    color: #6B6C6F;
}

.ProfileHeader section div p {
    color: #000000;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
}

.student_filters {
    margin: 28px 0 0 0;
    /* border: 1px solid red; */
    display: flex;
    align-items: center;
    padding: 10px 0;
}

.student_filters>div {
    margin: 0 1em 0 0;
    border-radius: 8px;
}

/* ProfileBottom */
.ProfileBottom {
    display: flex;
    gap: 10px;
    padding: 10px 0;
}

/* riskCard  */

.riskCard {
    display: flex;
    gap: 1rem;
    align-items: center;
    width: 402px;
    height: 56px;
    border: 1px;
    padding: 5px 15px;
    border-radius: 10px;
    border: 1px solid #E7E7E7;
    margin-bottom: 10px;
    background-color: #FFFFFF;
    cursor: pointer;
}

.riskCard:hover {
    background: linear-gradient(0deg, #E7E7E7, #E7E7E7),
        linear-gradient(0deg, #FFFFFF, #FFFFFF);

}

.active {
    background: linear-gradient(0deg, #E7E7E7, #E7E7E7),
        linear-gradient(0deg, #FFFFFF, #FFFFFF);
}

.riskCard div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 5px;
}

.riskCard div>h1 {
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: -0.00013333333481568843px;
    text-align: left;
    color: #001028;

}

.riskCard div>p {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: -0.00013333333481568843px;
    text-align: left;
    color: #66707E;
}

.riskCard :nth-child(2)>span {
    font-size: 10px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: -0.00013333333481568843px;
    text-align: left;
    color: #1B9446;
    display: flex;
    align-items: center;
    gap: 5px;
}

/* .riskCard :nth-child(2) span {
    display: inline-block;
    width: 4px;
    height: 4px;
    border-radius: 9px;
    background-color: inherit;

} */

.riskCard :nth-child(3) {
    margin-left: auto;
    padding-left: 10px;
    border-left: 1px solid #EBECEE;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* detailRiskCard  */

.detailRiskCard {
    background: #FFFFFF;
    border: 1px solid #E7E7E7;
    border-radius: 10px;
    padding: 20px;
    width: 100%;
}

/* nextConsultation  */

.nextConsultation p {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #66707E;

}

.nextConsultation div {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: #001028;
    padding: 5px 0;
}


/* assessment */
.assessment {
    padding: 10px 0;
    border-bottom: 1px solid #EBECEE;
}

.assessment>div {
    display: grid;
    grid-template-columns: 1.5fr 0.5fr 0.5fr;
    gap: 1rem;
    padding: 3px 0;
}

.assessment :nth-child(1)>div {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #66707E;
}

.assessment :nth-child(2)>div {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
}

.assessment a {
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
    color: #3E36B0;
    padding: 5px 0;
    cursor: pointer;
    text-decoration: underline;

}

/* lastConsultation */
.lastConsultation {
    padding: 10px 0;
}

.lastConsultation>div {
    border-bottom: 1px solid #EBECEE;
    padding: 10px 0;

}

.lastConsultation>div>p {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
    color: #66707E;
}

.lastConsultation>div>div {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #001028;
    padding-top: 5px;

}

/* attachment  */
.attachment h1 {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
    color: #66707E;

}

.TopNav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0 30px 0;
}

.buttonNav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
}

.button_filled {
    border: none;
    width: 12.2vw;
    border-radius: 8px;
    background-color: #1740A1;
    padding: 1.3vh 1vw;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.button_filled>:first-child {
    fill: #1740A1;
    background-color: #fff;
    border-radius: 999px;
}

.button_filled:last-of-type>:first-child {
    padding: 3px;

}

.button_filled>:last-child {
    color: #fff;
}