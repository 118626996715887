.report_header {
    display: flex;
    align-items: center;
    padding: 0vh 2vw;
  }
  .report_header_details {
    padding: 1vh 2vw;
    width: -webkit-fill-available;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  /* .report_header_right{
      display: flex;
      justify-content: space-between;
      align-items: center;
  } */
  .patient_name {
    font-size: 18px;
    font-weight: bold;
    line-height: 0.89;
    color: #343434;
  }
  .patient_details {
    display: flex;
  }
  .items {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 1.14;
    color: #343434;
    padding-top: 2vh;
    padding-right: 1vh;
  }
  .dots {
    width: 2px;
    height: 2px;
    margin-left: 1vh;
    background-color: #9d9d9d;
  }
  .download_image {
  }
  .download_button {
    display: flex;
    padding: 1vh;
    border: none;
    border-radius: 4px;
    background-color: #303030;
  }
  .download_text {
    font-size: 12px;
    line-height: 1.33;
    text-align: center;
    color: #fff;
  }
  