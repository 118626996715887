.slideshow_container{
    position: relative;
    height: 200px;
    width: 200px;
    border-radius: 10px;
}
.image_slides{
    height: 200px;
    width: 200px;
    border-radius: 10px;
}



.playArrow_btn{
    position: absolute;
    background-color: none;
    border: none;
    top: 45%;
    left: 45%;
}

video{
    border-radius: 10px;
}