@import url("https://fonts.googleapis.com/css2?family=Montserrat");
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

.login_page {
  font-family: "Montserrat", sans-serif;
}

.extra_settings {
  /* padding-top: 1vh; */
  margin: 16px 0 0 0;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
}

.css-ptiqhd-MuiSvgIcon-root {
  width: 12px !important;
  height: 12px !important;
}

.forgot_password {
  font-size: 12px;
  color: #1740A1;
  cursor: pointer;
  font-family: 'Inter', sans-serif;
  ;
  font-weight: 600;
}

.submit_button {
  margin: 32px 0 0 0;
  background-color: #1740A1;
  border-radius: 8px;
  border: none;
  height: 6vh;
  width: -webkit-fill-available;
  color: #fff;
  text-align: center;
  line-height: 1.25;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

.error_text {
  padding: 12px 0 0 0;
  font-size: 10px;
  line-height: 1.2;
  color: #d20909;
}

.box {
  border: 1px solid red;
  width: 100%;
}