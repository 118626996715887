.blog_info {
    width: 100%;
}

.blog_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header {
    display: flex;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: normal;
    color: #000;
}

.blog_container {
    position: relative;
    margin-top: 3vh;
    padding: 3vh 2vw;
    border-radius: 16px;
    box-shadow: 0 1px 50px 0 rgba(0, 0, 0, 0.08);
    border: solid 1px #e8e7e7;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    /* padding-bottom: 8vh; */
}

.blog_container .blog_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
}

.blog_container .blog_header>div>div {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.blog_container .blog_header>div>div>.issue_status {
    color: #fff;
    padding: 5px;
    border-radius: 10px;
}


.blog_container .blog_metadata {
    display: flex;
    gap: 2rem;
    /* justify-content: center; */
    align-items: center;
    margin: 1rem auto;
}

.blog_container .blog_metadata>div>h6 {
    font-size: 1rem;
}

.blog_container .blog_metadata>div>h6>span {
    color: gray;
}