.main {
    margin-top: 32px;
    ;
}

.main>:nth-child(1) {
    font-size: 14px;
    font-weight: 600;
    color: #66707E;
}

.main>:nth-child(2) {
    margin-top: 10px;
    font-size: 12px;
    font-weight: 500;
    color: #000000;
}

.main>:nth-child(3) {
    font-size: 12px;
    font-weight: 500;
    color: #000000;
}
.main>:nth-child(4) {
    margin-top: 10px;
    font-size: 12px;
    font-weight: 500;
    color: #000000;
}

.main div {
    display: flex;
    gap: 15px;
}

.main img {
    height: 80px;
}