.rowpopup_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
    height: 100%;
}
.rowpopup_container>button {
    display: flex;
    background: none;
    background-color: none;
    align-items: center;
    padding-left: 15px;
    border: none;
    outline: none;
    gap: 10px;
    cursor: pointer;
    height: 100%;
}
.rowpopup_container>button:hover {
    background-color: rgb(242, 242, 242)
}
.rowpopup_container>button>span {
    font-size: 12px;
}