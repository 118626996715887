.pagination {
    display: flex;
    align-items: center;
}

.pagination>div:first-child {
    position: relative;
}

.pagination>div:first-child>img:nth-child(2) {
    position: absolute;
    top: 25%;
    left: 32%;
}


.topFilter {
    display: flex;
    align-items: center;
    gap: 15px;
}

.pagination>div:nth-child(2) {
    position: relative;
    font-size: 14px;
    font-weight: bold;
}

.pagination>div:nth-child(2)>span {
    position: absolute;
    top: 25%;
    left: 32%;
}


.pagination>div:nth-child(3) {
    padding: 7px 10px;
    font-size: 14px;
    font-weight: bold;
}

.pagination>div:nth-child(3)>span:first-child {
    color: #e7e7e7;
    padding: 0 12px 0 0;
}

.pagination>div:nth-child(4) {
    position: relative;
}

.pagination>div:nth-child(4)>img:nth-child(2) {
    position: absolute;
    top: 25%;
    left: 32%;
}

.studentRows th>span {
    display: flex;
    align-items: center;
    gap: 10px;
}

.studentRows td:nth-child(2) {
    color: #001028;
}

.studentRows .action {
    color: #222CC9;
    text-decoration: underline;
    cursor: pointer;
}

.studentRows .dataValidate {
    color: #66707E;
    user-select: none;
    text-decoration: none;
    cursor: auto;
}

.viewSchoolBtn {
    cursor: pointer;
    border-radius: 20px;
    font-size: 10px;
    padding: 10px 15px;
    outline: none;
    border: none;
    background-color: #3B3CEA;
    color: white;
}

.greenBtn {
    background-color: #96C717;
}

.action {
    background-color: transparent;
    border: none;
}

.pagination_container {
    display: flex;
    justify-content: space-between;
    margin: 20px 0 0 0;
    padding: 0 0 0 5px;
}

.page_item {
    font-size: 12px;
}

.pagination {
    display: flex;
    align-items: center;
}

.pagination>div:first-child {
    position: relative;
}

.pagination>div:first-child>img:nth-child(2) {
    position: absolute;
    top: 25%;
    left: 32%;
}



.pagination>div:nth-child(2) {
    position: relative;
    font-size: 14px;
    font-weight: bold;
}

.pagination>div:nth-child(2)>span {
    position: absolute;
    top: 25%;
    left: 32%;
}


.pagination>div:nth-child(3) {
    padding: 7px 10px;
    font-size: 14px;
    font-weight: bold;
}

.pagination>div:nth-child(3)>span:first-child {
    color: #e7e7e7;
    padding: 0 12px 0 0;
}

.pagination>div:nth-child(4) {
    position: relative;
}

.pagination>div:nth-child(4)>img:nth-child(2) {
    position: absolute;
    top: 25%;
    left: 32%;
}